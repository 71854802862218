import React from 'react';

import { TailwindContainer } from '../Container/Container';
import { cn } from '../cn';
import { ClassValue } from 'clsx';

type HeroProps = React.PropsWithChildren<{
  title?: string,
  button?: JSX.Element,
  background?: string,
  id?: number,
  center?: boolean,
  isMobile?: boolean,
  lazyLoadImage?: boolean,
  subtitle?: string,
  className?: ClassValue[],
  secondaryContent?: JSX.Element,
}>;

export const Hero = ({
  title, button, background, lazyLoadImage=false, center=false,
  subtitle, children, secondaryContent, className } : HeroProps
): JSX.Element => {
  const smallBg = background?.replace('_w1600', '_w640');
  const mediumBg = background?.replace('_w1600', '_w1400');
  return (
    <section className={cn(
      'bg-black',
      'relative',
      'py-24',
      'flex',
      'items-center',
      'min-h-[700px]',
      {
        'min-h-[calc(100vh-87px)]': center,
        'h-[calc(100vh-87px)]': !center,
      },
      'after:content-[\'\']',
      'after:block',
      'after:absolute',
      'after:top-0',
      'after:bottom:0',
      'after:left:0',
      'after:w-full',
      'after:z-0',
      'md:after:w-[70%]',
      'overflow-hidden',
      ...(className || [])
    )}
    >
      { background
        ? <picture>
          <source srcSet={smallBg} media="(max-width: 640px)" />
          <source srcSet={mediumBg} media="(max-width: 1400px)" />
          <source srcSet={background} />
          <img className={`transform absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-auto min-w-full object-cover object-center  ${center ? 'min-h-[calc(100vh-87px)]' : 'min-h-[700px] h-[calc(100vh-87px)]'}`}
            loading={lazyLoadImage ? 'lazy' : undefined}
            data-src={lazyLoadImage ? background : undefined}
            src={background}
            alt={title}
          />
        </picture>
        : null
      }
      <TailwindContainer className={[
        'relative', 'items-center', 'z-10', 'w-full', 'px-8',
        'min-[1601px]:max-w-[1600px]',
        'justify-start',
        { 'justify-center': center },
      ]}>
        <div className={cn([
          'max-w-[550px]',
          'text-center',
          'bg-[rgba(13,13,13,0.6)]',
          'p-3',
          'shadow-md',
          {
            'max-w-[700px]': center,
          },
        ])}>
          {title && <h1>{title}</h1>}
          {(subtitle || children) && <div
            className={cn(
              'mt-2',
              'mb-4',
              'lg:mt-5',
              'lg:mb-8',
              {
                'lg:mt-10': center,
                'lg:mb-12': center,
              }
            )}
            dangerouslySetInnerHTML={children ? undefined : { __html: subtitle as string }}
          >
            {children}
          </div>}
          {button}
        </div>
      </TailwindContainer>
      {secondaryContent !== undefined ? <>
        <div className='z-10'>
          {secondaryContent}
        </div>
      </>: ''}
    </section>
  );
};
