import React, { useContext } from 'react';
import { GoX } from 'react-icons/go';
import { LoginContext } from 'src/lib/LoginContext';
import styled from 'styled-components';

const YouHaveBeenLoggedOutContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  font-size: 2.2rem;
  padding: 15px;
  background-color: rgba(225, 5, 5, 1);
  color: white;
  z-index: 9999999;
  line-height:1;

  svg {
    float: right;
    cursor: pointer;
  }
`;

export const YouHaveBeenLoggedOut = () => {
  const { youHaveBeenLoggedOut, clearYouHaveBeenLoggedOut } = useContext(LoginContext);

  if(!youHaveBeenLoggedOut) return null;

  return (
    <YouHaveBeenLoggedOutContainer>
      You have been logged out <GoX onClick={() => clearYouHaveBeenLoggedOut()}/>
    </YouHaveBeenLoggedOutContainer>
  );
};
