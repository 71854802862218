import React, { useRef } from 'react';
import styled from 'styled-components';

import { useListBox, useOption } from '@react-aria/listbox';
import { AriaListBoxProps } from '@react-types/listbox';
import { ListState } from '@react-stately/list';
import { Node } from '@react-types/shared/src/collections';

import { useBubbleShield } from '../../Hooks/useBubbleShield';

export type ListBoxAriaProps = React.PropsWithChildren<{
  inverted?: boolean,
  noOptionsBorder?: boolean,
  state: ListState<{}>,
}> & AriaListBoxProps<{}>;

export const ListBoxAria = (props : ListBoxAriaProps): JSX.Element => {
  const listBoxRef = useRef<HTMLUListElement >(null);
  const { state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ListBoxAriaContainer
      { ...listBoxProps }
      noOptionsBorder = {props.noOptionsBorder}
      ref={ listBoxRef }
    >
      {Array.from(state.collection).map((item) =>
        <ListBoxAriaOption
          key={item.key}
          item={item}
          state={state}
          inverted = { props.inverted }
        />
      )}
    </ListBoxAriaContainer>
  );
};

export const ListBoxAriaContainer = styled.ul<{
  noOptionsBorder: boolean,
}>`
  position: absolute;
  margin: 0;
  padding: 0;
  
  max-height: 200px;
  width: 100%;

  border: solid 2px black;
  list-style: none;
  overflow: auto;
  z-index: 3;

  ${(props) => !props.noOptionsBorder
    ? `li:nth-last-child(n+2) {
       border-bottom: 1px solid ${props.theme.black[80]};
      }`
    : ''}

  box-shadow:
    0 1px 4px rgba(0,0,0,.09),
    0 3px 8px rgba(0,0,0,.09),
    0 4px 13px rgba(0,0,0,.13);
`;

export type OptionProps = React.PropsWithChildren<{
  state: ListState<{}>,
  item: Node<{}>,
  className?: string,
  inverted?: boolean,
}>;

const Option = ( props : OptionProps ) => {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: props.item.key },
    props.state,
    ref
  );

  return (
    <ConditionallyStyledLi
      optionProps = { optionProps }
      the_ref = { ref }
      itemRendered = { props.item.rendered}
      isSelected = { isSelected }
      isFocused = { isFocused }
      isDisabled = {isDisabled}
      className = { props.className }
      inverted = { props.inverted }
    />
  );
};

export const ListBoxAriaOption = styled(Option)`
  background-color: ${(props) => props.inverted ? props.theme.black[10] : props.theme.black[90]};
  color: ${(props) => props.inverted ? props.theme.black[90] : props.theme.black[30]};
  padding: 10px;
  outline: none;
  cursor: pointer;
`;

/* This is necessary because we want to render based on isSelect, isFocused, and isDisabled
 * but those are gained from useOption inside of the Option component.  They are not
 * available on the props we will get in styled components.
 *
 * We can feed the values we want to process into this middleman component
 * as props and use them in a styled component.
 */

type CLSIProps = React.PropsWithChildren<{
  optionProps: React.HTMLAttributes<HTMLElement>,
  the_ref: React.RefObject<HTMLLIElement>,
  itemRendered: React.ReactNode,
  className?: string,
  isSelected: boolean,
  isFocused: boolean,
  isDisabled: boolean,
  inverted?: boolean,
}>;

const CSLI = (props : CLSIProps) => {
  return (
    <li
      {...props.optionProps}
      ref={props.the_ref}
      className = { props.className }
    >
      {props.itemRendered}
    </li>
  );
};

export const ConditionallyStyledLi = styled(CSLI)`
  ${(props) => `
    ${(props.isSelected)
    ? (`
          background-color: ${props.theme.gold[50]};
          color: ${props.theme.black[90]};
        `)
    : (props.isFocused)
      ? (`
            background-color ${props.inverted ? props.theme.black[0] : props.theme.black[80]};
          `)
      : ''
}
  `}
`;
