import React from 'react';
import styled from 'styled-components';

const ServerSideRenderingErrorContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 28px;
  font-size: 16px;
  padding: 5px;
  background-color: red;
  color: white;
  z-index: 9999999;
`;

export const ServerSideRenderingError = () => {
  if(!window.IS_DEV) return null;
  if(typeof window == 'undefined') return null;
  if(!window.serverSideRenderingError) return null;

  return (
    <ServerSideRenderingErrorContainer>
      Server side rendering error occurred
    </ServerSideRenderingErrorContainer>
  );
};
