import Color from 'color';

export const screenWidths = {
  phone: 480,
  tablet: 768,
  desktop: 1024,
  widescreen: 1200,
  extrawide: 1440,
};

export const theme = {
  mediaScalingFont: (multiplier: number) => `
    font-size: ${multiplier}rem;

    ${theme.phone} {
      font-size: ${multiplier * 1.125}rem;
    }
    ${theme.desktop} {
      font-size: ${multiplier * 1.25}rem;
    }
  `,
  // Colors
  black: {
    0: '#ffffff',
    10: '#f2f2f2',
    20: '#d9d9d9',
    30: '#bfbfbf',
    40: '#a6a6a6',
    50: '#8c8c8c',
    60: '#737373',
    70: '#595959',
    80: '#404040',
    90: '#262626',
    95: '#131313',
    100: '#0d0d0d',
  },
  gold: {
    0: '#ffffff',
    10: '#fff3dc',
    20: '#fbe2b1',
    30: '#f8d285',
    40: '#f3c656',
    50: '#f0bc28',
    60: '#d7960f',
    70: '#a76a08',
    80: '#774303',
    90: '#492200',
    100: '#1b0800',
  },
  blueLink: new Color('#0645ad'),

  // Focus effect for radio and checkbox inputs. gold[40] + transparency
  focusBoxShadow: 'box-shadow: 0 0 0 0.2rem rgba(243, 198, 86, 0.25)',

  // Typography
  serif: 'Amiri, serif',
  sansSerif: 'Open Sans, sans-serif',

  // Breakpoints
  phone: `@media screen and (min-width: ${screenWidths.phone}px)`,
  tablet: `@media screen and (min-width: ${screenWidths.tablet}px)`,
  desktop: `@media screen and (min-width: ${screenWidths.desktop}px)`,
  widescreen: `@media screen and (min-width: ${screenWidths.widescreen}px)`,
  extrawide: `@media screen and (min-width: ${screenWidths.extrawide}px)`,

  screenWidths,
};
