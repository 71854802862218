import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';
import AuctionHall from 'images/consignment/AuctionHall.jpg';

export const AsyncConsignmentWhoWeArePage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/ConsignmentWhoWeArePage/ConsignmentWhoWeArePage'
    componentImport={async () => (await import(
      'src/pages/ConsignmentWhoWeArePage/ConsignmentWhoWeArePage'
    ))}
    head={() => async () => {
      return {
        title: 'This Is Who We Are And What We Do',
        description: 'From a small family run local auction house, Rock Island Auction Company has grown '
        + 'into a world renowned, industry dominating, international auction firm. '
        + 'Here are some of the key difference makers in our success.',
        image: AuctionHall,
      };
    }}
    {...props}
  />
);
