import styled from 'styled-components';
import { IAccount } from 'src/components/Header/Account/Account.i';
import { Button } from '@riac/design-system';


export const LogoutButton = styled(Button)`
  color: ${(props) => props.theme.black[0]};
  overflow: visible;
  font-size: 2rem;
  line-height: 0;

  small {
    bottom: -12px;
  }
`;

export const AccountCount = styled.span`
  font-weight: 800;
  position: absolute;
  top: -5px;
  right: 1px;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
`;

export const Menu = styled.nav<IAccount>`
  display: ${(props) => props.showOn === 'mobile' ? 'flex' : 'none'};
  background: ${(props) => props.showOn === 'mobile' ? props.theme.gold[50] : 'transparent'};
  flex: 1;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: ${(props) => props.showOn === 'mobile' ? 'center' : 'flex-end'};
  align-items: center;
  margin-top: 7px;

  @media print {
    display: none;
  }

  ${(props) => props.theme.desktop} {
    display: ${(props) => props.showOn === 'mobile' ? 'none' : 'flex'};
    margin-top: 0;
  }

  ${Button} {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    color: ${(props) => props.showOn === 'mobile' ? `${props.theme.black[100]}` : props.theme.black[0]};
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    padding: 3px 0;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      ${(props) => props.showOn !== 'mobile' && 'color: props.theme.gold[50]'};
      small {
        visibility: visible;
        opacity: 1;
      }
    }

    &:focus {
      outline: 1px solid ${(props) => props.theme.black[100]};
    }

    ${(props) => props.theme.desktop} {
      padding: 0 10px;
      display: inline-block;
    }

    ${(props) => props.theme.extrawide} {
      padding: 0 15px;
    }
  }
  i {
    font-family: FontAwesome;
    font-size: 28px;
    display: block;

    ${(props) => props.theme.desktop} {
      font-size: 24px;
    }

    ${(props) => props.theme.extrawide} {
      font-size: 28px;
    }
  }
  small {
    text-align: center;
    font-family: ${(props) => props.theme.serif};
    font-size: 16px;
    display: inline-block;
    margin-left: 3px;
    ${(props) => props.theme.desktop} {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      visibility: hidden;
      color: ${(props) => props.theme.gold[50]};
      font-size: 12px;
      opacity: 0;
      transition: all 0.3s;
      margin-left: 0;
    }
    ${(props) => props.theme.extrawide} {
      font-size: 13px;
    }
  }

  ${AccountCount} {
    ${({ showOn }) => showOn === 'mobile' && `
      font-weight: 600;
      position: unset;
      text-shadow: none;
      top: 0;
      right: 0;
      margin-left: 5px;
    ` || ''
}
  }

  ${LogoutButton} {
    ${({ showOn }) => showOn === 'mobile' && `
      display: none;
    `}
  }
`;
