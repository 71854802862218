import styled from 'styled-components';

import { OutlineButton } from '@riac/design-system';
import { TextButton } from '@riac/design-system';

import { IOffcanvas } from 'src/components/Header/Offcanvas/Offcanvas.i';

export const Panel = styled.aside<IOffcanvas>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: ${({ show }) => show ? '0' : '-300px'};
  padding: 30px;
  height: 100%;
  min-height: 600px;
  width: ${({ show }) => show ? '100%' : '300px'};
  z-index: 200;
  overflow: hidden;
  overflow-y: auto;
  background: ${(props) => props.theme.black[100]};
  transition: all 0.5s;
  @media only screen and (max-height: 480px){
    min-height: 400px;
  }
  @media only screen and (max-height: 320px){
    min-height: 300px;
  }
`;

export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${OutlineButton} {
    margin: 30px 0 40px;
    @media only screen and (max-height: 480px){
      margin: 15px 0 20px;
      font-size: 1.6rem;
    }
    @media only screen and (max-height: 320px){
      margin: 12px 0 16px;
      font-size: 1.4rem;
    }
  }

  ${TextButton} {
    margin-bottom: 10px;
    
    font-family: ${(props) => props.theme.serif};
    font-size: 30px;
    color: ${(props) => props.theme.black[0]};    
  
    @media only screen and (max-height: 480px){
      font-size: 20px;
      margin-bottom: 5px;
    }

    @media only screen and (max-height: 320px){
      font-size: 15px;
      margin-bottom: 2px;
    }
  }
`;


export const AccountButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top 30px;

  @media only screen and (max-height: 480px){
    margin-top: 15px;
  }

  @media only screen and (max-height: 320px){
    margin-top: 12px;
  } 
`;

export const CreateAccount = styled.div`
  display: inline-block;
  margin-right: 1em;

  ${TextButton} {
    padding: 10px 20px;
    font-family: ${(props) => props.theme.serif};
    &:hover {
      color: ${(props) => props.theme.gold[50]};
    }
  }
`;
