import React from 'react';
import styled from 'styled-components';

import { ManagedFlexModal, ManagedFlexModalProps } from './ManagedFlexModal';
import { BoxButton } from '../Buttons/BoxButton';

export const ConfirmFlexModalTextContainer = styled.div`
  padding: 2em 0;
`;

export const ConfirmFlexModalButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  
  justify-content: flex-start;
  gap: 1em;
`;

type ConfirmFlexModalProps = ManagedFlexModalProps & {
  confirmButtonText?: string,
  rejectButtonText?: string,
  hideConfirmButton?: boolean,
  hideRejectButton?: boolean,
  onConfirm: () => void,
  onReject: () => void,
};

export const ConfirmFlexModal = (props: ConfirmFlexModalProps): JSX.Element => {
  return (
    <ManagedFlexModal
      className = {props.className}
      isOpen = {props.isOpen}
      title = {props.title}
      isDismissable = {props.isDismissable}
      onClose = {props.onReject}
      showCloseButton = {props.showCloseButton}
    >
      <ConfirmFlexModalTextContainer>
        {props.children}
      </ConfirmFlexModalTextContainer>
      <ConfirmFlexModalButtonContainer>
        {!props.hideConfirmButton && (
          <BoxButton onPress = {props.onConfirm}>{props.confirmButtonText || 'Yes'}</BoxButton>
        )}
        {!props.hideRejectButton && (
          <BoxButton onPress = {props.onReject}>{props.rejectButtonText || 'No'}</BoxButton>
        )}
      </ConfirmFlexModalButtonContainer>
    </ManagedFlexModal>
  );
};
