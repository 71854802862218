import React from 'react';
import { useContext } from 'react';

import { Panel, Menu, AccountButtons, CreateAccount } from 'src/components/Header/Offcanvas/Offcanvas.style';
import { LoginContext } from 'src/lib/LoginContext';
import { TextButton } from '@riac/design-system';
import { OutlineButton } from '@riac/design-system';

type OffcanvasProps = React.PropsWithChildren<{
  show: boolean,
  toggle: () => void,
}>;

export const Offcanvas = ({ show, toggle } : OffcanvasProps) => {
  const { logout } = useContext(LoginContext);
  const { isLoggedIn } = useContext(LoginContext);

  return (
    <Panel show={show}>
      <div>
        <Menu>
          <TextButton excludeFromTabOrder={!show} to='/gun-auctions' onPress={(e) => {toggle();}}>Auctions</TextButton>
          <TextButton excludeFromTabOrder={!show} to='/consignment' onPress={() => toggle()}>Consignment</TextButton>
          <TextButton excludeFromTabOrder={!show} to='/riac-blog' onPress={() => toggle()}>Blog</TextButton>
          <TextButton excludeFromTabOrder={!show} to='/faq' onPress={() => toggle()}>FAQ</TextButton>
          <TextButton excludeFromTabOrder={!show} to='/news-and-events' onPress={() => toggle()}>News &amp; Events</TextButton>
          <TextButton excludeFromTabOrder={!show} to='/about-us' onPress={() => toggle()}>About Us</TextButton>
        </Menu>
        <AccountButtons>
          {!isLoggedIn
          && <>
            <OutlineButton excludeFromTabOrder={!show} to='/login' onPress={() => toggle()}>Login</OutlineButton>
            <CreateAccount>
              <TextButton excludeFromTabOrder={!show} to='/create-account' onPress={() => toggle()}>
                Create Account
              </TextButton>
            </CreateAccount>
          </>
          }
          {isLoggedIn && <OutlineButton excludeFromTabOrder={!show} onPress={() => logout()}>Logout</OutlineButton>}
        </AccountButtons>
      </div>
    </Panel>
  );
};
