import React, { ReactNode } from 'react';

interface ILoadableErrorBoundary {
  onError: (error: any, errorInfo: any) => void;
  children: React.ReactNode;
}

export class LoadableErrorBoundary extends React.Component<ILoadableErrorBoundary> {
  state: {
    loadableErrorRefreshTimeout: number | undefined,
    isReloading: boolean,
  } = {
      loadableErrorRefreshTimeout: -1,
      isReloading: false,
    };

  componentDidCatch(error): void {
    const shouldReload = localStorage.getItem('loadable-error-refresh') !== '1';
    localStorage.setItem('loadable-error-refresh', '1');
    clearTimeout(this.state.loadableErrorRefreshTimeout);
    if(error.toString().indexOf('ChunkLoadError: Loading chunk') >= 0) {
      if(shouldReload) {
        this.setState({
          isReloading: true,
        });
        location.reload();
      } else {
        console.log('chunk error but not reloading');
        throw error;
      }
    } else {
      throw error;
    }
  }

  componentDidMount(): void {
    this.setState({ loadableErrorRefreshTimeout:
      setTimeout(() => {
        localStorage.setItem('loadable-error-refresh', '0');
      }, 10000),
    });
  }

  render(): JSX.Element | ReactNode {
    if(this.state.isReloading) {
      return <div>
        Loading...
      </div>;
    }
    return this.props.children;
  }
}
