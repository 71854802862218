import * as React from 'react';
/* LazyLoadObserver
 *
 * Exports:
 *   LazyLoadObserver - Instance of LLO which implements a pub/sub api for an IntersectionObserver instance which
 *   can be utilized as a centralize intersection detection method for lazy loading.
 *
 * Documentation for IntersectionObserver can be found at: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 *
 * Intended Usage:
 *   Calling entities will subscribe through the subscribe() method, which takes a React useRef() reference and a callback to be called once intersection is detected
 *     React ref is used instead of a DOM element to ensure current rendered element is still available through ref.current
 *
 *   Calling entities should unsubscribe through the unsubscribe() method when intersection detection is no longer needed.  LLO will NOT take care of that on its own.
 *
 */
export interface T_LLO {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  constructor(): void;
  subscribe(ref: React.MutableRefObject<undefined>, cb: () => void): void;
  unsubscribe(ref: React.MutableRefObject<undefined>): void;
  publishIntersect(elem: IntersectionObserverEntry): void;
  observerCallback(elements: IntersectionObserverEntry[]): void;


}

interface T_Element {
  ref: React.MutableRefObject<undefined>;
  cb: () => void;
}

export class LLO {
  supported: boolean;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  observer: IntersectionObserver = null!;
  elements: T_Element[] = [];

  constructor() {
    /* make sure we're in an environment that supports IntersectionObserver */
    if((typeof window !== 'undefined') && ('IntersectionObserver' in window))
    {
      this.supported = true;
      this.observer = new IntersectionObserver((elements) => {
        this.observerCallback(elements);
      }, {
        rootMargin: '800px',
        threshold: 0.1,
      });

      // list of subscribed elements
      this.elements = [];
    }
    else
    {
      this.supported = false;
    }
  }

  subscribe(ref: React.MutableRefObject<undefined>, cb: () => void): void {
    if(this.supported == true)
    {
      let existing = false;
      for(const i in this.elements)
      {
        // we are counting on ref to continue to point to the same object throughout component lifecycle
        // if this assertion fails then so will this logic, and we'll start accumulating dead references
        if(this.elements[i].ref === ref)
        {
          existing = true;
          this.elements[i].cb = cb;
          break;
        }
      }

      if(existing == false)
      {
        this.observer.observe(ref.current);
        this.elements.push({ ref, cb });
      }
    }
  }

  unsubscribe(ref: React.MutableRefObject<undefined>): void {
    if(this.supported == true && ref.current)
    {
      // we are counting on ref to continue to point to the same object throughout component lifecycle
      // if this assertion fails then so will this logic, and we'll start accumulating dead references
      this.elements = this.elements.filter((elem) => elem.ref !== ref);
      this.observer.unobserve(ref.current);
    }
  }

  publishIntersect(elem: IntersectionObserverEntry): void {
    for(const i in this.elements)
    {
      if(this.elements[i]?.ref.current === elem.target)
      {
        this.elements[i].cb();
      }
    }
  }

  observerCallback(elements: IntersectionObserverEntry[]): void {
    // eslint-disable-next-line guard-for-in
    for(const e in elements)
    {
      const elem = elements[e];

      if(elem.isIntersecting)
      {
        this.publishIntersect(elem);
      }
    }
  }
}

export const LazyLoadObserver = new LLO();
