import styled from 'styled-components';

import { TextButton, SolidButton, Input, InputLabel, InputControl, Container } from '../index';

export const SearchBar = styled.section`
  fieldset {
    position: relative;
    width: 100%;
    max-width: 800px;
  }
  div {
    position: relative;
  }
  p {
    padding: 100px 0 200px;
    width: 100%;
    max-width: 800px;
    text-align: center;
  }
`;

export const SearchContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
`;

export const SearchField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchLabel = styled.label`

`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

export const SearchInput = styled(Input)`
  margin: 0;
  align-items: stretch;

  ${InputControl} {
    margin-bottom: 0;
  }

  ${InputLabel} {
    display: none;
    ${(props) => `
      ${props.theme.tablet} { 
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
      }
    `}
    font-size: 2.4rem;
    font-family: ${(props) => props.theme.serif};
    margin: 0;
    padding-right: 10px;
  }
`;

export const SearchClearButton = styled(TextButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 65px;

  display: flex;
  align-items: center;

  font-size: 20px;
  color: ${(props) => props.theme.black[50]};

  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.black[30]};
  }

  svg {
    margin-right: 3px;
    margin-bottom: 4px;
  }
`;

export const SearchButton = styled(SolidButton)`
  line-height: 1;
  padding: 9px 15px;
  height: 100%;
`;
