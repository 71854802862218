import React, { useRef } from 'react';
import styled from 'styled-components';
import { useOverlay, useModal } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { AriaDialogProps } from '@react-types/dialog';
import { useScrollControl } from './useScrollControl';

export type ModalAriaProps = React.PropsWithChildren<{
  className?: string,
  title: string,
  isOpen: boolean,
}> & AriaDialogProps;

export const ModalAria = (props: ModalAriaProps): JSX.Element => {
  const ref = useRef(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  useScrollControl(props.isOpen);

  return (
    <ModalAriaBase {...underlayProps} className={props.className} >
      <FocusScope contain restoreFocus autoFocus>
        <ModalAriaDialog
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref = {ref}
        >
          {props.title
            && <ModalAriaTitle {...titleProps}>{props.title}</ModalAriaTitle>
          }
          <ModalAriaContent>
            {props.children}
          </ModalAriaContent>
        </ModalAriaDialog>
      </FocusScope>
    </ModalAriaBase>
  );
};

export const ModalAriaBase = styled.div`
  position: fixed;  
  display: flex;
  
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 9000;
`;

export const ModalAriaDialog = styled.div``;
export const ModalAriaTitle = styled.h3``;
export const ModalAriaContent = styled.div``;

