import React, { useRef } from 'react';
import { ScrollContext } from 'src/lib/ScrollContext';

interface ICatalogHistoryContext {
  latestCatalogPage: string;
  setLatestCatalogPage: React.Dispatch<React.SetStateAction<string>>;
  scrollToTopRef: React.MutableRefObject<HTMLElement>;
  scrollToTop: () => void;
}

export const CatalogHistoryContext = React.createContext<Partial<ICatalogHistoryContext>>({});
export const CatalogHistoryContextProvider = ({ children }) => {
  const { headerPadding } = React.useContext(ScrollContext);
  const [latestCatalogPage, setLatestCatalogPage] = React.useState<string>(null);
  const scrollToTopRef = useRef<HTMLElement>();
  return (
    <CatalogHistoryContext.Provider value={{
      latestCatalogPage, setLatestCatalogPage,
      scrollToTopRef,
      scrollToTop: () => {
        const targetY = scrollToTopRef.current?.getBoundingClientRect()?.top - headerPadding + (window.scrollY || window.pageYOffset);
        if((window.scrollY || window.pageYOffset) > targetY) {
          window.scrollTo(0, targetY);
        }
      },
    }}>
      {children}
    </CatalogHistoryContext.Provider>
  );
};
