import { Component } from 'react';

export default class ErrorBoundary extends Component {
  state = { hasError: false, error: null };
  props: any;

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    if(this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}
