import { Column } from '@riac/design-system';
import { ConfirmModal, ManagedModalTitle } from '@riac/design-system';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const AgeVerificationModal = styled(ConfirmModal)`
  &&& ${ManagedModalTitle} {
    padding: 0;
  }
`;

export type AgeVerificationCheckProps = React.PropsWithChildren<{}>;
export const AgeVerificationCheck = (props: AgeVerificationCheckProps): JSX.Element => {
  const [isVerified, setIsVerified] = useState<boolean>(true);


  useEffect(() => {
    setIsVerified(window.localStorage.getItem('ageVerified') == 'true');
  }, []);

  return (
    <>
      <AgeVerificationModal
        isOpen = {!isVerified}
        title = 'Age Verification'
        confirmButtonText = 'Yes, I Am Of Legal Age'
        onConfirm = {() => {
          window.localStorage.setItem('ageVerified', 'true');
          setIsVerified(true);
        }}
        rejectButtonText = 'No, I Am NOT Of Legal Age'
        onReject = {() => {
          window.location.href = 'https://www.google.com';
        }}
      >
        <Column gap = '1em'>
          <div>
            Welcome to Rock Island Auction,
          </div>
          <div>
            This website contains content intended for individuals who are 18 years of age or older.
            By entering this website, you affirm that you understand and comply with the age restrictions in place. If you are not of legal age to access such content, we kindly request that you exit the site immediately.
            Thank you for respecting our guidelines and policies.
          </div>
          <div>
            <div>
              Best regards,
            </div>
            <div>
              Rock Island Auction Company
            </div>
          </div>
        </Column>
      </AgeVerificationModal>
      {props.children}
    </>
  );
};
