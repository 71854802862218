import React from 'react';
import styled from 'styled-components';

import { ModalAria } from './ModalAria';
import { XButton } from '../../Buttons/XButton';
import { AriaDialogProps } from '@react-types/dialog';

export {
  ModalAriaBase as CloseButtonModalBase,
  ModalAriaContent as CloseButtonModalContent,
  ModalAriaDialog as CloseButtonModalDialog,
  ModalAriaTitle as CloseButtonModalTitle,
} from './ModalAria';

export type CloseButtonModalProps = React.PropsWithChildren<{
  className?: string,
  title: string,
  isOpen: boolean,
  isDismissable?: boolean,
  onClose?: () => void,
  showCloseButton?: boolean,
}> & AriaDialogProps;

export const ModalClose = styled(XButton)`
  background: ${({ theme }) => theme.gold[50]};
`;

export const CloseButtonModal = (props : CloseButtonModalProps) => {
  const { showCloseButton=false, children } = props;

  return (
    <ModalAria {...props}>
      {children}
      {showCloseButton
        && <ModalClose onPress={() => {props.onClose && props.onClose();}} aria-label='Close'/>
      }
    </ModalAria>
  );
};
