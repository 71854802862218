import styled from 'styled-components';

export const Column = styled.div.withConfig({
  shouldForwardProp: (prop, defaultCB) => (
    !['wrap', 'gap'].includes(prop)
    && defaultCB(prop)
  ),
})`
  display: flex;
  flex-direction: column;
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  ${({ gap }) => gap ? `gap: ${gap};` : ''}
`;
