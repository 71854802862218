import React, { createContext } from 'react';
import styled from 'styled-components';
import { useRadioGroupState, RadioGroupState } from '@react-stately/radio';
import { useRadioGroup } from '@react-aria/radio';
import { AriaRadioGroupProps } from '@react-types/radio';

export const RadioContext = createContext<RadioGroupState | null>(null);

export type RadioGroupAriaProps = React.PropsWithChildren<{}> & AriaRadioGroupProps & {className?: string};

export const RadioGroupAria = (props: RadioGroupAriaProps) => {
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <RadioGroupAriaContainer { ...radioGroupProps } className={props.className}>
      <RadioGroupAriaLabel { ...labelProps }>{props.label}</RadioGroupAriaLabel>
      <RadioContext.Provider value = {state}>{props.children}</RadioContext.Provider>
    </RadioGroupAriaContainer>
  );
};

export const RadioGroupAriaContainer = styled.div``;
export const RadioGroupAriaLabel = styled.span``;
