import React from 'react';
import { GhostDoc } from 'src/components/GhostDocDangerouslyRender/GhostDocDangerouslyRender.style';

const findAnchors = (data) => {
  /*
  let doc = new JSDOM(data);
  let body = doc.window.document.getElementsByTagName('body')[0];
  let links = doc.window.document.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    links[i].target = '_blank';
  }
  return body.innerHTML;
  */

  return data;
};

export const GhostDocDangerouslyRender = ({ data }) => {
  return (
    <GhostDoc dangerouslySetInnerHTML={{ __html: data ? findAnchors(data) : '' }} />
  );
};
