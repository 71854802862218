import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { IInputError } from './InputError.i';
import { InputErrorContainer, InputErrorIcon } from './InputError.style';

export const InputError = ({ error, className, invert }: IInputError) => {
  if(!error) return null;
  return (
    <InputErrorContainer className = {className} invert={invert}>
      <InputErrorIcon><FaExclamationTriangle/></InputErrorIcon> {error}
    </InputErrorContainer>
  );
};
export { InputErrorContainer } from './InputError.style';
export * from './InputError.i';
export * from './InputError.style';
