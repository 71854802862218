import React from 'react';
import styled from 'styled-components';
import { ConfirmFlexModal } from './ConfirmFlexModal';
import { ManagedFlexModalProps } from './ManagedFlexModal';

export type AlertFlexModalProps = ManagedFlexModalProps & {
  acknowledgeButtonText?: string,
  hideAcknowledgeButton?: boolean,
  onAcknowledge: () => void,
};

export const AlertFlexModalContainer = styled(ConfirmFlexModal)``;

export const AlertFlexModal = (props: AlertFlexModalProps): JSX.Element => {
  return (
    <AlertFlexModalContainer
      className = {props.className}
      hideRejectButton
      confirmButtonText = {props.acknowledgeButtonText || 'Ok'}
      onConfirm = {props.onAcknowledge}
      {...props}
    >
      {props.children}
    </AlertFlexModalContainer>
  );
};
