export const importPolyfills = async () => {
  const promises = [];

  // If browser doesn't support timeZone, load polyfills for dayjs
  if(
    !((new window.Intl.DateTimeFormat()).resolvedOptions().timeZone)
    || !Intl.DateTimeFormat.prototype.formatToParts
  ) {
    promises.push(import('./dayjstzpolyfill'));
  }

  function isIE() {
    const myNav = navigator.userAgent.toLowerCase();
    return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
  }
  if(isIE() && isIE() < 10) {
    setInterval(() => {
      import('flexibility').then((flexibility) => flexibility(document.body));
    }, 1000);
  }

  // Add more conditional polyfills here if needed...

  await Promise.all(promises);
};

