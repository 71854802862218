import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContainer } from '@riac/design-system';
import { Spinner } from '@riac/design-system';
import { usePrefetch, usePrefetchSuspense } from 'src/lib/usePrefetch';
import { getSlug } from 'src/lib/getSlug';
import { Page404 } from 'src/pages/Page404';
import { GhostDocDangerouslyRender } from 'src/components/GhostDocDangerouslyRender/GhostDocDangerouslyRender';
import { useFetch } from 'src/lib/FetchContext';
import { base64ToString, stringToBase64 } from 'src/lib/IsometricBase64';
import { HeadContext } from 'src/lib/HeadContext';

export const Title = styled.h1`
  margin-top: 50px;
  text-align: center;
`;

export const GhostPages = ({ children, ...props }) => {
  const { fetch } = useFetch();
  const head = useContext(HeadContext);
  const slug = getSlug(props);

  const pageData = usePrefetchSuspense(`ghostpages-getpagebyslug-${slug}`, async () => {
    const response = await fetch(`/api/ghost/getPageBySlug/${encodeURIComponent(slug)}`, {
      credentials: 'include',
    });
    const result = await response.json();
    try {
      result.docs.html = encodeURIComponent(result?.docs?.html);
    } catch (e) {}
    return { ...result, slug: props.match.url };
  });
  const page = pageData.read();

  if(page && !page?.success) return children || <Page404/>;
  if(head.isHead) return null; //TODO: Actual head content for this page.

  let content = '';
  try {
    content = decodeURIComponent(page.docs.html);
  } catch (e) {}

  return (
    <ContentContainer display='block'>
      <Title>{page?.docs?.title}</Title>
      <GhostDocDangerouslyRender data={content}/>
    </ContentContainer>
  );
};
