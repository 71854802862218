import React, { useEffect } from 'react';

export const CrispChat = () => {
  useEffect(() => {
    // Timer will prevent script from running until after load
    const timer = setTimeout(() =>{
      // Create script element for Live Chat
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        window.$crisp=[];
        window.CRISP_WEBSITE_ID="8d76a183-5360-4a54-a71e-0b092af5d96a";
        (function(){
          d=document;
          s=d.createElement("script");
          s.src="https://client.crisp.chat/l.js";
          s.defer=1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();
      `;
      // Add Live Chat script to page after load
      document.body.appendChild(script);
      // Remove script when page is unloaded
      // This doesn't really accomplish much - there is no clear and simple way to destroy the chat window.
      return () => {
        document.body.removeChild(script);
        clearTimeout(timer);
      };
    }, 100);
  }, []);
  return <></>;
};


