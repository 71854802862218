import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncFAQSinglePage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/FAQPage/FAQSinglePage/FAQSinglePage'
    componentImport={async () => (await import('src/pages/FAQPage/FAQSinglePage/FAQSinglePage'))}
    head={({ fetch, params }) => async () => {
      const req = await fetch('/api/faq/get');
      const res = await req.json();
      const matchingItem = res.faqItems.find((item) => item.FAQSlug === params.slug);
      if(!matchingItem) {
        return {
          error404: true,
        };
      }
      const title = matchingItem?.FaqItem || '404';
      return {
        title,
        description: `Frequently Asked Questions about firearms auctions: ${title}`,
      };
    }}
    {...props}
  />
);
