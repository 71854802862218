import React, { useEffect, useContext, useState } from 'react';
import { useFetch } from 'src/lib/FetchContext';
import { LoginContext } from 'src/lib/LoginContext';

export type AuctionWishlist = {
  WishListID: number,
  WishListName: string,
};

interface ITextAlert {
  FormattedPhone: string;
  LotNumber: number;
}

interface IUserItemContext {
  auctionWishlists: AuctionWishlist[];
  auctionTextAlerts: ITextAlert[];
  setAuctionWishlists: React.Dispatch<React.SetStateAction<AuctionWishlist[]>>;
  fetchError: boolean;
}

export type UserItemContextProvider = React.PropsWithChildren<{
  auctionId?: number,
  children: React.ReactNode,
}>;

export const UserItemContext = React.createContext<Partial<IUserItemContext>>({});

export const UserItemContextProvider = ({ auctionId, children }: UserItemContextProvider) => {
  const { isLoggedIn } = useContext(LoginContext);
  const [auctionWishlists, setAuctionWishlists] = useState<AuctionWishlist[]>([]);
  const [auctionTextAlerts, setAuctionTextAlerts] = useState<ITextAlert[]>([]);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const { fetch } = useFetch();

  useEffect(() => {
    const fetchWishAndText = async () => {
      try {
        setFetchError(false);
        const [wlRes, textRes] = await Promise.all([
          fetch(`/api/account/wishlist/auctionLists/${auctionId}`, {
            credentials: 'include',
          }).then((resp) => resp.json()),
          fetch(`/api/account/textalerts/get/${auctionId}`, {
            credentials: 'include',
          }).then((resp) => resp.json()),
        ]);

        setAuctionWishlists(Array.isArray(wlRes) ? wlRes : []);
        setAuctionTextAlerts(Array.isArray(textRes) ? textRes : []);
      } catch (err) {
        console.log(err);
        setFetchError(true);
      }
    };

    if(isLoggedIn && auctionId) {
      fetchWishAndText();
    } else {
      setAuctionWishlists([]);
      setAuctionTextAlerts([]);
    }
  }, [isLoggedIn, auctionId]);

  return (
    <UserItemContext.Provider
      value={{
        auctionWishlists,
        auctionTextAlerts,
        setAuctionWishlists,
        fetchError,
      }}
    >
      {children}
    </UserItemContext.Provider>
  );
};
