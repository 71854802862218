import React, { useContext, useEffect, useState } from 'react';
import { useFetch } from 'src/lib/FetchContext';
import { LoginContext } from 'src/lib/LoginContext';

interface IAccountHeaderContext {
  bidCart: number;
  bidsSubmitted: number;
  messages: number;
  notifications: number;
  updateHeaderCounts: () => Promise<void>;
  bidUpdates: number;
}

export const AccountHeaderContext = React.createContext<Partial<IAccountHeaderContext>>({});
export const AccountHeaderContextProvider = ({ children }) => {
  const [bidCart, setBidCart] = useState(0);
  const [bidsSubmitted, setBidsSubmitted] = useState(0);
  const [messages, setMessages] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [bidUpdates, setBidUpdates] = useState(0);
  const { fetch } = useFetch();

  const { isLoggedIn } = useContext(LoginContext);

  const updateHeaderCounts = async (): Promise<void> => {
    const headerCountResponse = await fetch('/api/account/headercounts', { credentials: 'include' });
    const headerCountResult = await headerCountResponse.json();
    const activeAuctions = await (await fetch('/api/auction/activeAuctions', {
      credentials: 'include',
    })).json();

    const allBidCounts: Record<string, number> = await (await fetch('/api/account/bids/getAllCurrentBidsCount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        auctionIds: activeAuctions.map((x) => x.AuctionID).join(','),
      }),
    })).json();

    const bidCount = Object.values(allBidCounts).reduce((pv, cv) => pv + cv, 0);

    setMessages(headerCountResult.messages);
    setNotifications(headerCountResult.notifications);
    setBidCart(headerCountResult.cart);
    setBidUpdates(bidUpdates + 1);
    setBidsSubmitted(bidCount);
  };

  useEffect(() => {
    if(!isLoggedIn) {
      return;
    }
    updateHeaderCounts();
  }, [isLoggedIn]);

  return (
    <AccountHeaderContext.Provider value={{
      bidCart,
      bidsSubmitted,
      messages,
      notifications,
      updateHeaderCounts,
      bidUpdates,
    }}>
      {children}
    </AccountHeaderContext.Provider>
  );
};
