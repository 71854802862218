import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncConsignmentGettingStartedPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/ConsignmentGettingStartedPage/ConsignmentGettingStartedPage'
    componentImport={async () => (
      await import('src/pages/ConsignmentGettingStartedPage/ConsignmentGettingStartedPage')
    )}
    head={() => async () => {
      return {
        title: 'Consignment Getting Started',
        description: 'Consign Your Firearms and Antiques with Rock Island Auction Company today',
      };
    }}
    {...props}
  />
);
