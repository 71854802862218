import styled from 'styled-components';

import { Button } from './Button';
import { shouldForwardPropFilter } from '@riac/core';
import { cn } from '../../cn';
import React from 'react';
import { IPolymorphicBaseButtonProps } from '../BaseComponents/Buttons/PolymorphicBaseButton';

export type ITextButtonProps = {
  blueText?: boolean,
  goldText?: boolean,
  whiteText?: boolean,
};

export const TextButton = styled(Button).withConfig({
  shouldForwardProp: shouldForwardPropFilter(['blueText', 'goldText', 'whiteText']),
})<ITextButtonProps>`
  display: inline-block;
  padding: 0;
  ${(props) => props.blueText && `
    color: ${props.theme.blueLink} !important;
    &:hover {
      text-decoration: underline;
    }
  `};
  ${(props) => props.goldText && `
    color: ${props.theme.gold[50]} !important;
    &:hover {
      text-decoration: underline;
    }
  `};
  ${(props) => props.whiteText && 'color: white !important'};
`;

export const TailwindTextButton: React.FC<IPolymorphicBaseButtonProps> = (props) => {
  return (
    <Button className={cn(
      'inline-block p-0 text-sc-gold-50 hover:text-sc-gold-20 text-xl',
      props.isDisabled && 'text-gray-400  hover:text-gray-400'
    )} {...props}/>
  );
};
