import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';
import { IAuctionData } from 'src/pages/CatalogPage/Catalog.i';

export const AsyncCatalogPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/Catalog'
    componentImport={async () => (await import('src/pages/CatalogPage/Catalog'))}
    head={({ fetch, params }) => async () => {
      if(!params.p0) {
        const req = await fetch('/api/auction/next', { credentials: 'include' });
        const res = await req.json();
        return {
          redirect: true,
          redirectPath: `/catalog/${res[0].AuctionID}`,
          redirectType: 'temporary',
        };
      }
      try {
        const auctionDataReq = await fetch(`/api/catalog/initAuctionData/${params.p0}`);
        const auctionDataRes: { AuctionData: IAuctionData, Error?: string} = await auctionDataReq.json();
        if(auctionDataRes.Error) throw new Error(auctionDataRes.Error);
        return {
          title: auctionDataRes.AuctionData.Title,
          description: `View and bid on firearms for sale in Auction ${
            auctionDataRes.AuctionData.AuctionID
          } by Rock Island Auction Company`,
          meta: [],
        };
      } catch (e) {
        if(e.message === 'Auction Not Found') {
          return {
            error404: true,
          };
        }
        throw e;
      }
    }}
    {...props}
  />
);
