import styled from 'styled-components';
import { Button } from '../../AriaControls/Buttons/Button';
import { shouldForwardPropFilter } from '@riac/core';
import { IAccodionItemPoorlyNamedSecondTypeWithTheSameName } from '../Accordion.i';


export const AccordionItemContainer = styled.article`
  border-bottom: 1px solid ${(props) => props.theme.black[80]};
  margin: 0 15px;
  padding: 20px 0 15px;
`;

export const AccordionItemItemPoorlyNamedDuplicateComponentToggle = styled(Button).withConfig({
  shouldForwardProp: shouldForwardPropFilter(['show', 'compact']),
})<IAccodionItemPoorlyNamedSecondTypeWithTheSameName>`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.black[0]};
  font-size: ${(props) => props.compact ? '2.4rem' : '3.6rem'};
  font-family: ${(props) => props.theme.serif};
  ${(props) => !props.compact ? `
    font-variant: small-caps;
  ` : ''}

  &:before {
    display: none;
  }

  svg {
    font-size: 1.8rem;
    display: block;
    color: ${(props) => props.theme.gold[50]};
    transition: all 300ms;
  }

  ${(props) => props.show && `
    svg {
      transform: rotate(-180deg);
    }
  `}
`;

export const AccordionItemItemContent = styled.div<IAccodionItemPoorlyNamedSecondTypeWithTheSameName>`
  height: 0;
  overflow:hidden;
  transition: all 100ms;
  
  ${(props) => props.show && `
    height: ${props.ht}px;
    transition: all 300ms;
  `}
  
  > div {
    padding: 15px 30px;
    margin: 0 15px;
    color: ${(props) => props.theme.black[20]};
    transition: all 100ms;
    ${(props) => props.show && `
      transition: all 300ms;
    `}
  }
`;

