import styled from 'styled-components';

import {
  SVGCheckboxAria,
  SVGCheckBoxAriaBorderBox,
  CheckboxSVGBase,
} from '../BaseComponents/Inputs/SVGCheckboxAria';

export {
  SVGCheckBoxAriaContainer as CyanCheckboxContainer,
  SVGCheckBoxAriaLabel as CyanCheckboxLabel,
  SVGCheckboxAriaInput as CyanCheckboxInput,
  SVGCheckBoxAriaBorderBox as CyanCheckboxBorderBox,
  SVGCheckBoxCheckmark as CyanCheckboxCheckmark,
  SVGCheckBoxFocusBox as CyanCheckboxFocusBox,
} from '../BaseComponents/Inputs/SVGCheckboxAria';

export const CustomSVG = styled(CheckboxSVGBase)`
  ${SVGCheckBoxAriaBorderBox} {
    fill: ${(props) => props.isSelected ? '#00ffff' : 'none'};
  }
`;

export const CheckboxCyan = styled(SVGCheckboxAria).attrs({
  CheckboxSVG: CustomSVG,
})``;
