import React from 'react';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { OverlayContainer } from '@react-aria/overlays';

import { Modal, ModalProps } from './Modal';
export {
  ModalBase as ManagedModalBase,
  ModalContent as ManagedModalContent,
  ModalDialog as ManagedModalDialog,
  ModalTitle as ManagedModalTitle,
  ModalCloseButton as ManagedModalCloseButton,
  ModalContentArea as ManagedModalContentArea,
} from './Modal';

export type ManagedModalProps = ModalProps;

export const ManagedModal = (props : ManagedModalProps) => {
  const overlayState = useOverlayTriggerState({});

  if( (overlayState.isOpen == false) && (props.isOpen == true))
  {
    overlayState.open();
  }
  else if( (overlayState.isOpen == true) && (props.isOpen == false))
  {
    overlayState.close();
  }

  return (
    <>
      {overlayState.isOpen
      && <OverlayContainer>
        <Modal
          {...props}
          onClose = { ()=> {
            overlayState.close();
            props.onClose && props.onClose();
          }}
        />
      </OverlayContainer>
      }
    </>
  );
};
