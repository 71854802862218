import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncFAQPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/FAQPage/FAQPage'
    componentImport={async () => (await import('src/pages/FAQPage/FAQPage'))}
    head={() => async () => {
      return {
        title: 'Frequently Asked Questions About Bidding at Gun Auctions',
        description: 'Frequently Asked Questions About Bidding at Gun Auctions',
      };
    }}
    {...props}
  />
);
