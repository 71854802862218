import styled from 'styled-components';
import { IContainer } from './Container.i';
import { ClassValue } from 'clsx';
import { cn } from '../cn';

export const Container = styled.div<IContainer>`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;
  display: ${(props) => props.display ? props.display : 'flex'};
  
  @media screen and (min-width: 1601px) {
    max-width: 1570px;
  }

  ${(props) => !props.display || props.display === 'flex' ? `
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  ` : ''}
`;

export const TailwindContainer: React.FC<{
  className?: ClassValue[], flex?: boolean,
  children?: React.ReactNode,
}> = (
  { children, className }
) => {
  return <div className={cn(
    'max-w-7xl', 'mx-auto', 'p-0', 'px-4', 'flex',
    'flex-wrap', 'justify-center', 'items-start',
    '2xl:max-w-[1530px]',
    ...(className || []))}
  >
    {children}
  </div>;
};
