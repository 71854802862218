import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncCatalogLandingPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/CatalogPage/CatalogLandingPage/CatalogLandingPage'
    componentImport={async () => (await import('src/pages/CatalogPage/CatalogLandingPage/CatalogLandingPage'))}
    head={() => async () => {
      return {
        title: props.basicLandingPage.title,
        description: props.basicLandingPage.description,
        canonical: `/category/${props.basicLandingPage.slug}`,
      };
    }}
    {...props}
  />
);
