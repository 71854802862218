import React, { useRef } from 'react';
import styled from 'styled-components';

import { DismissButton, useOverlay } from '@react-aria/overlays';
import { FocusScope } from '@react-aria/focus';
import { useBubbleShield } from '../../Hooks/useBubbleShield';

export type PopoverAriaProps = React.PropsWithChildren<{
  popoverRef?: React.RefObject<HTMLDivElement>,
  isOpen: boolean,
  inverted?: boolean,
  onClose: () => void,
}>;

export const PopoverAria = (props : PopoverAriaProps) => {
  const ref = useRef(null);
  const { popoverRef = ref, isOpen, onClose, children } = props;

  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef
  );

  useBubbleShield(ref);

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      <PopoverAriaContainer
        { ...overlayProps }
        ref = { popoverRef }
      >
        { children }
        <DismissButton onDismiss = { onClose } />
      </PopoverAriaContainer>
    </FocusScope>
  );
};

export const PopoverAriaContainer = styled.div`
  position: relative;
  background: lightgray;
  margin-top: 0px;
`;
