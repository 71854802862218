import styled from 'styled-components';

export const Item = styled.article`
  border-bottom: 1px solid ${(props) => props.theme.black[80]};
  margin: 0 15px;
  padding: 20px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
