import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';
import AcquisitionsTeam from 'images/consignment/AcquisitionsTeam.jpg';

export const AsyncConsignmentProcessPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/ConsignmentProcessPage/ConsignmentProcess'
    componentImport={async () => (await import('src/pages/ConsignmentProcessPage/ConsignmentProcess'))}
    head={() => async () => {
      return {
        title: 'The Consignment Process',
        description: 'When you agree to consign with Rock Island Auction Company, we take care of '
          + 'all the work: photography, descriptions, advertising, storage, insurance, as well as legal '
          + 'transfer and shipping to the buyer. We handle everything and charge a simple percentage of '
          + 'your item\'s sale price with our only incentive being to bring you the most money for your items.',
        image: AcquisitionsTeam,
        imageType: 'image/jpeg',
      };
    }}
    {...props}
  />
);
