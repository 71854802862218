import React, { useContext } from 'react';
import { Menu, LogoutButton, AccountCount } from 'src/components/Header/Account/Account.style';
import { FaComment, FaGavel, FaShoppingCart, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { LoginContext } from 'src/lib/LoginContext';
import { AccountHeaderContext } from 'src/lib/AccountHeaderContext';
import { Button } from '@riac/design-system';


export const AccountMenu = ({ showOn }: {showOn: string}) => {
  const { logout } = useContext(LoginContext);
  const { bidsSubmitted, bidCart, messages } = useContext(AccountHeaderContext);

  return (
    <Menu showOn={showOn}>
      <Button to='/account/cart'>
        <FaShoppingCart/>
        <small>Cart</small>
        {bidCart > 0 && <AccountCount>{bidCart}</AccountCount> || ''}
      </Button>
      <Button to='/account/bids'>
        <FaGavel/>
        <small>Bids</small>
        {bidsSubmitted > 0 && <AccountCount>{bidsSubmitted}</AccountCount> || ''}
      </Button>
      <Button to='/account/messages'>
        <FaComment/>
        <small>Messages</small>
        {messages > 0 && <AccountCount>{messages}</AccountCount> || ''}
      </Button>
      <Button to='/account'>
        <FaUser/>
        <small>Account</small>
      </Button>
      <LogoutButton onPress={() => logout()}>
        <FaSignOutAlt/>
        <small>Logout</small>
      </LogoutButton>
    </Menu>
  );
};
