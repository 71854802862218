import React from 'react';
import { FaCheckSquare } from 'react-icons/fa';
import { IInputSuccess } from './InputSuccess.i';
import { InputSuccessContainer, InputSuccessIcon } from './InputSuccess.style';

export const InputSuccess = ({ success, className, invert }: IInputSuccess) => {
  if(!success) return null;
  return (
    <InputSuccessContainer className={className} invert={invert}>
      <InputSuccessIcon><FaCheckSquare/></InputSuccessIcon> {success}
    </InputSuccessContainer>
  );
};
export * from './InputSuccess.style';
export * from './InputSuccess.i';
