import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncGhostPostIndexPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/GhostPostIndexPage/GhostPostIndexPage'
    componentImport={async () => (await import('src/pages/GhostPostIndexPage/GhostPostIndexPage'))}
    head={({ location }) => async () => {
      const isBlog = location.pathname.includes('riac-blog');
      const title = isBlog ? 'RIAC Blog' : 'RIAC News';
      const description = isBlog
        ? 'Read the latest blog posts from the world record setting firearms auction house Rock Island Auction Company.'
        : 'Read the latest news from the world record setting firearms auction house Rock Island Auction Company.';
      return {
        title,
        description,
      };
    }}
    {...props}
  />
);
