import React, { useState } from 'react';

export type LazyLoadContext = {
  forceLoad: boolean,
  setForceLoad: (forceLoad: boolean) => void,
};

export const LazyLoadContext = React.createContext<Partial<LazyLoadContext>>({});
export const LazyLoadContextProvider = ({ children }: any): JSX.Element => {
  const [forceLoad, setForceLoad] = useState<boolean>(false);
  return (
    <LazyLoadContext.Provider
      value = {{
        forceLoad,
        setForceLoad,
      }}
    >
      {children}
    </LazyLoadContext.Provider>
  );
};

