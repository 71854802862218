import React, { useContext, useState } from 'react';
import { Offcanvas } from 'src/components/Header/Offcanvas/Offcanvas';
import { MainMenu } from 'src/components/Header/Main/Main';
import { AccountMenu } from 'src/components/Header/Account/Account';
import { Navbar, Logo, AccountButtons, Login, Placeholder, Toggle, HeaderContentWrapper } from 'src/components/Header/Header.style';
import { LoginContext } from 'src/lib/LoginContext';
import { ScrollContext } from 'src/lib/ScrollContext';

import { OutlineButton } from '@riac/design-system';
import { TextButton } from '@riac/design-system';
import { SkipLink } from 'src/components/SkipLink/SkipLink';
import RIACLogo from 'images/riac-logo.png';

export const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const { isLoggedIn } = useContext(LoginContext);
  const { headerRef } = useContext(ScrollContext);


  return (
    <div>
      <Navbar ref={headerRef}>
        <SkipLink/>

        <HeaderContentWrapper>
          <Logo to='/'>
            <img src={RIACLogo} alt='Rock Island Auction Company' />
          </Logo>
        </HeaderContentWrapper>


        <MainMenu />

        <HeaderContentWrapper>
          {isLoggedIn
            ? <AccountMenu showOn='desktop' />
            : (
              <AccountButtons>
                <TextButton to='/create-account'>
                Create Account
                </TextButton>
                <Login>
                  <OutlineButton to='/login'>
                  Login
                  </OutlineButton>
                </Login>
              </AccountButtons>
            )
          }
        </HeaderContentWrapper>

        <Toggle active={showOffcanvas} onPress={() => setShowOffcanvas(!showOffcanvas)} aria-label='Main Menu'>
          <span /><span /><span />
        </Toggle>
        <Offcanvas show={showOffcanvas} toggle={() => setShowOffcanvas(!showOffcanvas)} />
      </Navbar>

      <Placeholder />
      { isLoggedIn
        ? <AccountMenu showOn='mobile' />
        : <div/>
      }
      <a id='maincontent'/>
    </div>
  );
};
