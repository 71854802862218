import * as React from 'react';
export const DataContext = React.createContext({});

export const InternalContext = React.createContext({
  requests: [],
  resolved: false,
  current: 0,
});

interface IInternalContext {
  requests: {
    promise: Promise<any>,
    id: number,
    cancel: any,
  }[];
  resolved: boolean;
  current: number;
  pushesThisFrame?: number;
}
interface IDataContext {
  [k: string]: any;
}

declare global {
  interface Window {
    [k: string]: any;
    _initialDataContext: object;
  }
}


export function usePrefetch<T>(
  effect: () => Promise<any>,
  dependencies?: React.DependencyList
): T[] {
  const internalContext: IInternalContext = React.useContext(InternalContext);
  const callId = internalContext.current;
  internalContext.current++;
  const ctx: IDataContext = React.useContext(DataContext);
  const [data, setData] = React.useState(ctx[callId]?.data !== undefined ? ctx[callId].data : null);
  const [error, setError] = React.useState(ctx[callId]?.error || null);

  if(data === null && error === null && !internalContext.resolved) {
    let cancel = Function.prototype;

    const effectPr = new Promise((resolve) => {
      cancel = () => {
        if(!ctx[callId]) {
          ctx[callId] = { error: { message: 'timeout' }, id: callId };
        }
        resolve(callId);
      };
      return effect()
        .then((res) => {
          return res;
        })
        .then((res) => {
          ctx[callId] = { data: res };
          resolve(callId);
        })
        .catch((error) => {
          console.error(error); // log error in case consuming entity ignores it
          ctx[callId] = { error: error };
          resolve(callId);
        });
    });
    internalContext.pushesThisFrame++;
    internalContext.requests.push({
      id: callId,
      promise: effectPr,
      cancel: cancel,
    });
  }

  React.useEffect(() => {
    if(internalContext.resolved && !ctx[callId]) {
      effect()
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          setError(error);
        });
    }
    delete ctx[callId];
  }, dependencies);

  return [data, error];
}


interface IPrefetchProfilingContext {
  mark: MarkFunc;
}


export const PrefetchProfilingContext = React.createContext<Partial<IPrefetchProfilingContext>>({});

export type MarkFunc = (label: string, phase: 'Start' | 'Success' | 'Error') => void;

export const PrefetchProfilingContextProvider = ({ children, mark }: React.PropsWithChildren<{mark: MarkFunc}>) => {
  return (
    <PrefetchProfilingContext.Provider value={{
      mark,
    }}>
      {children}
    </PrefetchProfilingContext.Provider>
  );
};

export function usePrefetchSuspense<T>(
  key: string, effect: () => Promise<T>,
): {read(): T} {
  const resources = React.useContext(DataContext);
  const { mark } = React.useContext(PrefetchProfilingContext);
  if(!resources[key]) {
    mark(key, 'Start');
    resources[key] = {};
    resources[key].promise = effect().then(
      (r) => {
        mark(key, 'Success');
        resources[key].status = 'success';
        resources[key].result = r;
      },
      (e) => {
        mark(key, 'Error');
        resources[key].status = 'error';
        resources[key].error = e;
      }
    );
    resources[key].status = 'pending';
  }
  React.useEffect(() => {
    return () => {
      delete resources[key];
    };
  }, []);
  return {
    read() {
      if(resources[key].status === 'success') {
        return resources[key].result;
      }
      if(resources[key].status === 'error') {
        console.error('usePrefetchSuspense error', resources[key]);
        throw resources[key].error;
      }
      throw resources[key].promise;
    },
  };
}

export const createBrowserContext = (
  variableName = '_initialDataContext'
) => {
  const initial = window && window[variableName] ? window[variableName] : {};
  const internalContextValue: IInternalContext = {
    current: 0,
    resolved: true,
    requests: [],
    pushesThisFrame: 0,
  };
  function BrowserDataContext<T>(props: Props<T>) {
    return (
      <InternalContext.Provider value={internalContextValue}>
        <DataContext.Provider value={initial}>
          {props.children}
        </DataContext.Provider>
      </InternalContext.Provider>
    );
  }

  return BrowserDataContext;
};

const wait = (time: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({ error: 'timeout' });
    }, time);
  });
};

export const createServerContext = () => {
  const ctx: IDataContext = {};
  const internalContextValue: IInternalContext = {
    current: 0,
    resolved: false,
    requests: [],
    pushesThisFrame: 0,
  };
  function ServerDataContext<T>(props: Props<T>) {
    return (
      <InternalContext.Provider value={internalContextValue}>
        <DataContext.Provider value={ctx}>
          {props.children}
        </DataContext.Provider>
      </InternalContext.Provider>
    );
  }
  const resolveData = async (timeout?: number) => {
    const effects = internalContextValue.requests.map((item) => item.promise);

    if(timeout) {
      const timeOutPr = wait(timeout);

      await Promise.all(
        internalContextValue.requests.map((effect, index) => {
          return Promise.race([effect.promise, timeOutPr]).catch(() => {
            return effect.cancel();
          });
        })
      );
    } else {
      await Promise.all(effects);
    }

    const promisesResolved = internalContextValue.pushesThisFrame;
    internalContextValue.pushesThisFrame = 0;
    internalContextValue.current = 0;
    return {
      promisesResolved,
      data: ctx,
      toJSON: function() {
        return this.data;
      },
      toHtml: function(variableName = '_initialDataContext') {
        return `<script>window.${variableName} = ${JSON.stringify(
          this
        )};</script>`;
      },
      toScript: function(variableName = '_initialDataContext') {
        return `window.${variableName} = ${JSON.stringify(
          this
        )};`;
      },
    };
  };
  const currentData = () => {
    return {
      data: ctx,
      toJSON: function() {
        return this.data;
      },
      toHtml: function(variableName = '_initialDataContext') {
        return `<script>window.${variableName} = ${JSON.stringify(
          this
        )};</script>`;
      },
      toScript: function(variableName = '_initialDataContext') {
        return `window.${variableName} = ${JSON.stringify(
          this
        )};`;
      },
    };
  };
  return {
    ServerDataContext,
    resolveData,
    currentData,
  };
};
