import styled from 'styled-components';

interface IInputErrorContainerProps {
  invert?: boolean;
}

export const InputErrorContainer = styled.div<IInputErrorContainerProps>`
  && {
    background-color: rgba(120, 0, 0, 1);
    padding: 5px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.85);
    ${(props) => props.invert && `
      box-shadow: 0px 3px 1px -2px rgb(120 0 0 / 20%), 0px 2px 2px 0px rgb(120 0 0 / 14%), 0px 1px 5px 0px rgb(120 0 0 / 12%);
      border-radius: 4px;
      background-color: rgba(255, 0, 0, 0.125);
      color: #D10000;
      a {
        color: blue;
      }
    ` || ''}
  }
`;

export const InputErrorIcon = styled.span`
  font-size: 1.1em;
  line-height: 1;
  vertical-align: middle;
`;
