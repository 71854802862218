import styled from 'styled-components';

import { DisplayButton } from './DisplayButton';

export const OutlineButton = styled(DisplayButton)` 
  border: 2px solid ${({ theme }) => theme.gold[50]};
  background: transparent;

  color: ${({ theme }) => theme.black[0]};
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-100% - 15px);
    width: 100%;
    transform: skew(-20deg);
    transition: all 1.5s ease-out;
    background: ${({ theme }) => theme.gold[50]};
    opacity: 1;
    z-index: -1;
  }
  
  &&&&:hover {
    color: ${({ theme }) => theme.black[100]};
    
    &:before {
      left: -20px;
      width: calc(100% + 40px);
    }
  }

  &:focus, &:active, :visited {
    color: ${({ theme }) => theme.black[0]};
  }

  &:disabled {
    &:before {
      display: none;
    }
  }
`;
