import styled from 'styled-components';

import { CloseButtonModal, CloseButtonModalDialog, CloseButtonModalTitle, ModalClose, CloseButtonModalProps } from '../BaseComponents/Modals/CloseButtonModal';
import { TextButton } from '../Buttons/TextButton';
import { MarkdownContentArea } from '../../MarkdownContentArea/MarkdownContentArea';

export {
  CloseButtonModalBase as ModalBase,
  CloseButtonModalContent as ModalContent,
  CloseButtonModalDialog as ModalDialog,
  CloseButtonModalTitle as ModalTitle,
  ModalClose as ModalCloseButton,
} from '../BaseComponents/Modals/CloseButtonModal';

export type ModalProps = {
  fullHeight?: boolean,
} & CloseButtonModalProps;


/* Base style is additive to ModalAriaBase */
export const Modal = styled(CloseButtonModal)<ModalProps>`
  background-color: rgba(13, 13, 13, .8);
  align-items: center;
  justify-content: center;

  ${CloseButtonModalDialog} {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: -1;
    
    min-width: 50%;
    max-width: 100%;
    max-height: 100%;
    padding: 24px;
  
    background-color: white;
    box-shadow:
      0 1px 4px rgba(0,0,0,.09),
      0 3px 8px rgba(0,0,0,.09),
      0 4px 13px rgba(0,0,0,.13);
    
    ${(props) => props.fullHeight && 'height: 100%;'}
  }

  ${CloseButtonModalTitle} {
    color: rgba(0, 0, 0, 0.9);
    padding-left: 55px;
    padding-right: 55px;
    text-align: center; 
  }

  ${ModalClose} {
    position: absolute;
    top: 8px;
    right: 8px;
  
    padding: 12px;
    
    color: #0d0d0d;
    user-select: none;
    
    font-size: 18px;
    line-height: 0.7;
    text-align: center;
    
    border: 1px solid transparent;
    border-radius: 3px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:focus {
      border-color: black;
    }
  }
`;

export const ModalContentArea = styled.div`
  color: rgba(0, 0, 0, 0.8);

  max-width: 750px;

  h1,h2,h3,h4,h5 {
    color: rgba(0, 0, 0, 0.9);
    padding-left: 55px;
    padding-right: 55px;
    text-align: center; 
  }
  
  p,div {
    color: rgba(0, 0, 0, 0.8);
    margin-top: 2px;
    margin-bottom: 5px;
  }
  
  ${TextButton} {
    color: ${({ theme }) => theme.gold[50]};
  }
  
  img {
    margin: auto;
    display: block;
  }
`;
