import * as React from 'react';

export interface FetchContext {
  fetch(input: RequestInfo, init?: RequestInit): Promise<Response>;
}

export const FetchContext = React.createContext<Partial<FetchContext>>({});

export const useFetch = () => {
  return React.useContext(FetchContext);
};
