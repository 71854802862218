import styled from 'styled-components';

import { BoxButton } from './BoxButton';

export const DisplayButton = styled(BoxButton)`
  position: relative;
  z-index: 2;
  
  transition: all 1.5s;
  
  font-size: 1.8rem;
  ${({ theme }) => `
    ${theme.phone} {
      font-size: 2rem;
    }
    ${theme.desktop} {
      font-size: 2.4rem;
    }
  `}
`;
