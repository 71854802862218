import React from 'react';

import { Button } from '@riac/design-system';

export type IconProps = React.PropsWithChildren<{
  href: string,
  title: string,
  icon: JSX.Element,
}>;

export const Icon = ({ href, title, icon }: IconProps): JSX.Element => {
  return (
    <Button
      className = 'justify-center' 
      to={href}
      aria-label={title}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon}
    </Button>
  );
};
