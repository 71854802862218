import styled from 'styled-components';

import { Button } from './Button';

export const BoxButton = styled(Button)`
  display: inline-flex;
  padding: 12px 22px;
  overflow: hidden;

  align-items: center;
  cursor: pointer;

  font-family: ${({ theme }) => theme.serif};
  font-size: 2rem;

  background: ${({ theme }) => theme.gold[50]};
  border: none;

  color: ${({ theme }) => theme.black[100]};
  text-align: center;

  &:focus, &:active, :visited {
    color: ${({ theme }) => theme.black[100]};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.gold[50]};
  }

  &:hover {
    color: ${({ theme }) => theme.black[100]};
  }

  ${({ isDisabled, theme }) => isDisabled && `
    background: ${theme.black[80]};
    color: rgba(255, 255, 255, 0.5);
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      background: ${theme.black[80]};
      color: rgba(255, 255, 255, 0.5);
    }
  `}
`;
