
import styled from 'styled-components';
import { shouldForwardPropFilter } from '@riac/core';
import { ContentContainer } from '../Content';
import { Button } from '../AriaControls';
import { IAccodionItemPoorlyNamedSecondTypeWithTheSameName } from './Accordion.i';

export const Section = styled.section`
  background: ${(props) => props.theme.black[100]};
  background: linear-gradient(to bottom, rgba(13, 13, 13, 1), rgba(13,13,13, 0.985));
  padding: 100px 0;
`;

export const AccordionContainer = styled(ContentContainer)`
  display: block;
  max-width: 1200px !important;
`;

export const AccordionTitle = styled.h2`
  font-family: ${(props) => props.theme.serif};
  color: ${(props) => props.theme.gold[50]};
  font-variant: small-caps;
  font-size: 48px;
  text-align: left;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;

export const AccordionItemToggle = styled(Button).withConfig({
  shouldForwardProp: shouldForwardPropFilter(['show', 'compact']),
})<IAccodionItemPoorlyNamedSecondTypeWithTheSameName>`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.black[0]};
  font-size: ${(props) => props.compact ? '2.4rem' : '3.6rem'};
  font-family: ${(props) => props.theme.serif};
  ${(props) => !props.compact ? `
    font-variant: small-caps;
  ` : ''}

  &:before {
    display: none;
  }

  svg {
    font-size: 1.8rem;
    display: block;
    color: ${(props) => props.theme.gold[50]};
    transition: all 300ms;
  }

  ${(props) => props.show && `
    svg {
      transform: rotate(-180deg);
    }
  `}
`;

export const AccordionItemContent = styled.div<IAccodionItemPoorlyNamedSecondTypeWithTheSameName>`
  height: 0;
  overflow:hidden;
  transition: all 100ms;
  
  ${(props) => props.show && `
    height: ${props.ht}px;
    transition: all 300ms;
  `}
  
  > div {
    padding: 15px 30px;
    margin: 0 15px;
    color: ${(props) => props.theme.black[20]};
    transition: all 100ms;
    ${(props) => props.show && `
      transition: all 300ms;
    `}
  }
`;

