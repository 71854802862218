import React from 'react';

import { CTASection, CTAContainer, CTAContent, CTATitle, CTACopy } from './CallToAction.style';

type CallToActionProps = {
  title: string,
  content: string,
  button?: JSX.Element,
}

export const CallToAction = ({ title, content, button } : CallToActionProps) => {
  return (
    <CTASection>
      <CTAContainer>
        <CTAContent>
          <CTATitle>{title}</CTATitle>
          <CTACopy>{content}</CTACopy>
        </CTAContent>
        {button}
      </CTAContainer>
    </CTASection>
  );
};
