import React from 'react';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { OverlayContainer } from '@react-aria/overlays';

import { FlexModal, FlexModalProps } from './FlexModal';
export {
  FlexModalBase as ManagedFlexModalBase,
  FlexModalContent as ManagedFlexModalContent,
  FlexModalDialog as ManagedFlexModalDialog,
  FlexModalTitle as ManagedFlexModalTitle,
  FlexModalCloseButton as ManagedFlexModalCloseButton,
} from './FlexModal';

export type ManagedFlexModalProps = FlexModalProps;

export const ManagedFlexModal = (props : ManagedFlexModalProps): JSX.Element => {
  const overlayState = useOverlayTriggerState({});

  if( (overlayState.isOpen == false) && (props.isOpen == true))
  {
    overlayState.open();
  }
  else if( (overlayState.isOpen == true) && (props.isOpen == false))
  {
    overlayState.close();
  }

  return (
    <>
      {overlayState.isOpen
      && <OverlayContainer>
        <FlexModal
          {...props}
          onClose = { ()=> {
            overlayState.close();
            props.onClose && props.onClose();
          }}
        />
      </OverlayContainer>
      }
    </>
  );
};
