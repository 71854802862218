import { AriaFocusRingProps, FocusRingAria, useFocusRing as useFocusRingInternal } from '@react-aria/focus';

export const useFocusRing = (props?: AriaFocusRingProps | undefined): FocusRingAria => {
  if(typeof HTMLElement !== 'undefined')
  {
    return useFocusRingInternal(props);
  } else {
    return {
      isFocused: false,
      isFocusVisible: false,
      focusProps: {},
    };
  }
};
