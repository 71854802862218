import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncGhostPostSinglePage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/GhostPostSinglePage/GhostPostSingle'
    componentImport={async () => (await import('src/pages/GhostPostSinglePage/GhostPostSingle'))}
    head={({ fetch, location, params }) => async () => {
      const tag = location.pathname.startsWith('/news/') ? 'news' : 'blogs';
      const req = await fetch(`/api/ghost/getGhostSingleData/${params.title}/${tag}/0`);
      const res = await req.json();
      if(res.success === false) {
        return {
          error404: true,
        };
      }
      const title = res.blogPost.title;
      const description = res.blogPost.meta_description;
      const image = res.blogPost.image;
      const structuredData = `{
        "@context": "https://schema.org",
        "@type": "BlogPost",
        "headline": "${title}",
        "image": ["${image}"],
        "datePublished": "${res.blogPost.published_at}",
        "dateModified": "${res.blogPost.updated_at}",
        "author": ${res.blogPost.author_name.map((author) => `{
          "@type": "Person",
          "name": "${author}"
        }`).join(',')}
      }`;
      return {
        title,
        description,
        image,
        structuredData,
      };
    }}
    {...props}
  />
);
