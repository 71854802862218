import { SocketHookManager } from 'src/lib/SocketHookManager';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useSocketEvent = (
  socketHookManager: SocketHookManager | undefined,
  eventName: string,
  onEvent: (...params: any) => void,
): void => {
  useEffect((): (() => void) | undefined => {
    if(socketHookManager) {
      const uuid = uuidv4();
      socketHookManager.addEventListener(eventName, uuid, onEvent);
      return (): void => {
        socketHookManager.removeEventListener(uuid);
      };
    }
  }, [socketHookManager, onEvent]);
};
