import styled from 'styled-components';
import { ArrowLink } from '../../index';
import { IPanelsContent, IPanelsPanel, IPanelsTitle } from './Panel.i';

export const PanelsPanelContainer = styled.article<IPanelsPanel>`
  background: ${(props) => props.theme.black[30]};
  border: 10px solid ${(props) => props.theme.black[100]};
  position: relative;
  padding: 50px 15px;
  text-align: center;
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: ${(props) => props.posts ? 'center' : 'flex-start'};
  justify-content: center;
  overflow: hidden;

  ${(props) => props.theme.tablet} {
    flex: 0 0 50%;
  }

  ${(props) => props.theme.desktop} {
    flex: 1;
  }

  ${(props) => props.theme.widescreen} {
    padding: 100px 30px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.black[100]};
    opacity: 0.9;
    z-index: 0;
  }

  > ${ArrowLink} {
    z-index: 2;
  }
`;

export const PanelsPanelContainerImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
`;


export const PanelsPanelType = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 3px 10px 0;
  background: ${(props) => props.theme.gold[50]};
  color: ${(props) => props.theme.black[90]};
  font-family: ${(props) => props.theme.serif};
`;

export const PanelsPanelTitle = styled.h3<IPanelsTitle>`
  font-size: 2.8rem;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 100%;

  ${(props) => props.theme.tablet} {
    font-size: 3rem;
  }

  ${(props) => props.theme.desktop} {
    font-size: 3.4rem;
  }
`;

export const PanelsPanelContent = styled.p<IPanelsContent>`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: ${(props) => props.posts ? '0 0 20px' : 0};
`;
