import styled from 'styled-components';

import {
  Modal,
  ModalProps,
  ModalCloseButton,
  ModalDialog,
} from './Modal';

export const FloatingDialogModal = styled(Modal)<ModalProps>`
  
  ${ModalDialog} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 100%;
    width: 100%;
    height: 100vh;
    
    background-color: rgba(0, 0, 0, 0);

    overflow: hidden;  
  }

  ${ModalCloseButton} {
    background: none;
    color: ${(props) => props.theme.black[0]};
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding: 10px;
    font-size: 2.5rem;
  
    ${(props) => props.theme.phone} {
      color: ${(props) => props.theme.black[0]};
    }
  
    &:hover {
      color: ${(props) => props.theme.black[0]};
    }
  
    &:before {
      display: none;
    }
  }
`;
