import styled from 'styled-components';

export const SkipLinkContainer = styled.div`
  position: absolute;
  left: 2px;
  right: 2px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: -1000;
  opacity: 0;
  width: fit-content;

  &:focus-within {
    opacity: 1;
    z-index: 1;
  }
`;
