import { useContext, useEffect, useState } from 'react';

import { ModalScrollContext } from './ModalScrollContext';

export const useScrollControl = (isOpen: boolean ): void => {
  const { updateOpenState, destroyModal } = useContext(ModalScrollContext);
  const [modalId, setModalId] = useState<string>('');

  useEffect(() => {
    if(modalId) {
      updateOpenState(isOpen, modalId);
    }

    return () => {
      destroyModal(modalId);
    };
  }, [isOpen, modalId]);

  useEffect(() => {
    setModalId(updateOpenState(isOpen));
  }, []);
};
