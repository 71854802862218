import { YoutubeLazyLoaderPlaceholder } from '../YoutubeLazyLoader/YoutubeLazyLoader.style';
import styled from 'styled-components';
import { OutlineButton, TextButton } from '../AriaControls/Buttons';

export const MarkdownContentArea = styled.div`
  h1, h2, h3, h4, h5 {
    max-width: 750px;
    margin: 40px auto 6px;
    text-align: center;
    @media print {
      color: ${(props) => props.theme.black[100]};
    }
  }

  strong {
    font-weight: 700;
    color: ${(props) => props.theme.black[20]};
    
    @media print {
      color: ${(props) => props.theme.black[100]};
    }
  }

  img {
    max-width: 100%;
    margin: auto;
    display: block;
  }

  p, div, ol, ul, figure {
    max-width: 750px;
    margin: auto;
    overflow-wrap: break-word;
  }
  p, ol, ul, figure, ${YoutubeLazyLoaderPlaceholder} {
    padding: 0 25px;
    margin: 0 auto 20px;
    
    @media print {
      color: ${(props) => props.theme.black[100]};
    }
  }
  a,a>strong {
    color: ${({ theme }) => theme.gold[50]};
    
    &:hover {
      text-decoration: underline;
    }
    
    @media print {
      color: blue;
      text-decoration: underline;
    }
  }
  ol, ul {
    padding-left: 30px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  em {
    font-style: italic;
  }
  a + em,
  figcaption {
    font-style: italic;
    font-size: 1.6rem;
    line-height: 2;
    text-align: center;
    padding: 0 10px;
    display: block;
    margin-bottom: 20px;
  }
  iframe:not(.instagram-media) {
    margin: 0 auto 20px;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    height: 100%;
  }
  blockquote {
    max-width: 750px;
    margin: 30px auto;
    padding: 0 15px;
    border-left: 5px solid ${(props) => props.theme.gold[50]};
    font-family: ${(props) => props.theme.serif};
    font-size: 2.8rem;
    font-style: italic;
  }
  ${TextButton} {
    min-height: unset;
    min-width: unset;
    display: inline;
  }
  .instagram-media {
    //Instagram embeds use element style attribute, must be overwritten
    display: block!important;
    max-width: 690px!important;
    width: 100%!important;
    margin: auto!important;
    background-color: transparent!important;
    border: none!important;
  }
  
  .kg-callout-card  {
    border: 2px solid ${({ theme }) => theme.black[80]};
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;
    .kg-callout-emoji, .kg-callout-text {
      strong {
        color: ${({ theme }) => theme.black[30]};
      }
      margin: auto 0;
    }
    ${OutlineButton} {
      color: ${({ theme }) => theme.black[20]};;
      text-decoration: none;
      margin: 0 .5em;
    }
  }
}`;
