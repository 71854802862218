import styled from 'styled-components';

import { Container } from '../Container/Container';
import { IHero, IContent } from './Hero.i';
import { TextButton } from '../AriaControls/Buttons';

export const HeroSection = styled.section<IHero>`
  background: ${(props) => props.theme.black[100]};
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;

  ${(props) => props.centered ? `
    min-height: calc(100vh - 87px);
  ` : `
    min-height: 700px;
    height: calc(100vh - 87px);
  `}

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    //background: linear-gradient(to right, ${(props) => props.theme.black[100]} 20%, rgba(13, 13, 13, 0.4));
    width: 100%;
    z-index: 0;

    ${(props) => props.theme.tablet} {
      //background: linear-gradient(
      //  to right,
      //  ${(props) => props.theme.black[100]} 10%,
      //  rgba(13, 13, 13, 0.6) 70%,
      //  transparent
      //);
      width: 70%;
      ${(props) => props.noFade && 'background: none;' || ''}
    }
    ${(props) => props.noFade && 'background: none;' || ''}
  }
`;

export const HeroImage = styled.img<IHero>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  min-width: 100%;

  object-fit: cover;
  object-position: center center;

${(props) => props.centered ? `
    min-height: calc(100vh - 87px);
    ` : `
    min-height: 700px;
    height: calc(100vh - 87px);
  `}
`;

export const HeroContainer = styled(Container)<IContent>`
  position: relative;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 0 30px;

  ${(props) => props.theme.tablet} {
    justify-content: ${(props) => props.centered ? 'center' : 'flex-start'};
  }

  @media screen and (min-width:1601px) {
    max-width: 1600px;
  }
`;

export const HeroContent = styled.div<IContent>`
  max-width: ${(props) => props.centered ? '700px' : '550px'};
  text-align: center;
  background: rgba(13, 13, 13, 0.6);
  padding: 10px;
  box-shadow: 2px 2px 2px rgba(13, 13, 13, 0.6);
`;

export const HeroTitle = styled.h1``;

export const HeroSubtitle = styled.div<IContent>`
  margin: 10px 0 15px;
  
  ${(props) => props.theme.desktop} {
    margin: ${(props) => props.centered ? '40px 0 50px' : '20px 0 30px'};
  }
  table {
    margin: 0 auto;
  }

  ${TextButton}
  {
    font-size: 2rem;
  }
`;


export const HeroSecondaryContent = styled.div`
  z-index: 1;
`;
