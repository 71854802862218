import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncConsignmentPastSuccessPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/ConsignmentPastSuccessPage/ConsignmentPastSuccessPage'
    componentImport={async () => (await import('src/pages/ConsignmentPastSuccessPage/ConsignmentPastSuccessPage'))}
    head={() => async () => {
      return {
        title: 'Proven History of Success',
        description: 'View Past Auction Sales and Successes of Rare Firearms',
      };
    }}
    {...props}
  />
);
