import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';
import RIACBedfordExterior from 'images/locations/riac-bedford-exterior.jpg';

export const AsyncLocationBedfordPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/LocationsPage/LocationBedfordPage/LocationBedfordPage'
    componentImport={async () => (await import('src/pages/LocationsPage/LocationBedfordPage/LocationBedfordPage'))}
    head={() => async () => {
      return {
        title: 'RIAC Bedford',
        description: 'Rock Island Auction Company has firmly established itself as the #1 firearms auction house '
        + 'in the world. Premier Firearms Auction will take place in our new state-of-the-art facility at '
        + '3600 Harwood Rd., Bedford, Texas. No rivaling firm can match our attention to detail, '
        + 'respect for legacy, extraordinary selection, and most importantly, our results.',
        image: RIACBedfordExterior,
      };
    }}
    {...props}
  />
);
