import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';
import { sanitizeWithDashes } from 'src/lib/catalog';

export const AsyncDetailPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/DetailPage/DetailPage'
    componentImport={async () => (await import('src/pages/DetailPage/DetailPage'))}
    head={({ fetch, location, params }) => {
      return async () => {
        const itemInfo = await (await fetch(`/api/detail/${params.auctionid}/${params.lotnumber}`)).json();
        const primaryItem = itemInfo?.Items?.length > 0 ? itemInfo.Items[0] : null;
        const detailSlug = sanitizeWithDashes(primaryItem.WebTitle).toLowerCase();
        if(!primaryItem) {
          return {
            error404: true,
          };
        }
        return {
          title: primaryItem?.WebTitle,
          description: primaryItem?.Headline + primaryItem?.DetailDesc,
          image: `${window.CDN_PATH}${itemInfo?.AuctionID}/${itemInfo?.Photos[0]}`,
          imageType: 'image/jpeg',
          canonical: `/detail/${params.auctionid}/${params.lotnumber}/${detailSlug}`,
          structuredData: `{
            "@context": "http://schema.org",
            "@type": "Product",
            "description": ${JSON.stringify(primaryItem?.DetailDesc)},
            "name": ${JSON.stringify(primaryItem?.WebTitle)},
            "image": [
              "${window.CDN_PATH}${
    itemInfo?.AuctionID}/${primaryItem?.LotNumber}.jpg",
              "${window.CDN_PATH}${
    itemInfo?.AuctionID}/255X159/${primaryItem?.LotNumber}.jpg",
              "${window.CDN_PATH}${
    itemInfo?.AuctionID}/thumbs/${primaryItem?.LotNumber}.jpg"
            ],
            "sku": "${primaryItem?.LotNumber}-${itemInfo?.AuctionID}",
            "brand": "${primaryItem?.ManufacturerName}",
            "offers": {
              "@type": "AggregateOffer",
              "lowPrice": "${primaryItem?.LowEst}",
              "highPrice": "${primaryItem?.HighEst}",
              "priceCurrency": "USD",
              "offerCount": 1,
              "url": "https://www.rockislandauction.com/detail/${
  itemInfo?.AuctionID
  }/${primaryItem?.LotNumber}/"
            }`,
        };
      };
    }}
    {...props}
  />
);
