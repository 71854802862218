import styled from 'styled-components';

export const InputErrorSummaryContainer = styled.div`
  background-color: rgba(100, 0, 0, 1);
  padding: 8px;
  h4 {
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;
