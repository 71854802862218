import React from 'react';
import styled from 'styled-components';

import { ButtonAria } from './ButtonAria';
import { ILinkButtonProps, LinkButtonAria } from './LinkButtonAria';
import { AriaButtonProps } from '@react-aria/button';

export type IPolymorphicBaseButtonProps = AriaButtonProps<'button'> & ILinkButtonProps;

export const PolymorphicBaseButton = (props: IPolymorphicBaseButtonProps) => {
  const ButtonComponent = props.to ? LinkButtonAria : ButtonAria;

  return (
    <PolymorphicBaseButtonTag
      as = {ButtonComponent}
      {...props}
    />
  );
};

export const PolymorphicBaseButtonTag = styled.div``;
