import React from 'react';

import { AriaWrappedButtonFactory } from './AriaWrappedButtonFactory';
import { useBubbleShield } from '../../../Hooks/useBubbleShield';

export type ButtonAriaProps = React.PropsWithChildren<{
  tabindex: number,
  ref: React.MutableRefObject<HTMLButtonElement>,
}>;

export const ButtonAria = AriaWrappedButtonFactory('button', (props : ButtonAriaProps): JSX.Element => {
  //useBubbleShield(props.ref);

  return (
    <button
      { ...props }
      ref = { props.ref }
    >
      { props.children }
    </button>
  );
});
