import React, { createContext } from 'react';

export interface IHeadContext {
  isHead: boolean;
  is404Error?: boolean;
  isUnknownError?: boolean;
  isRedirect?: boolean;
  redirectPath?: string;
  redirectType?: 'permanent' | 'temporary';
}

export const HeadContext = createContext<Partial<IHeadContext>>({});

export const HeadContextProvider = ({ children, value }): JSX.Element => {
  return <HeadContext.Provider
    value={value}
  >
    {children}
  </HeadContext.Provider>;
};
