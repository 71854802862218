import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncAboutUsPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/AboutUsPage/AboutUs'
    componentImport={async () => (await import('src/pages/AboutUsPage/AboutUs'))}
    head={() => {
      return async () => {
        return {
          title: 'About Rock Island Auction Company',
          description: 'Rock Island Auction holds over 12 gun auctions per year. '
          + 'We buy & sell collectible firearms for serious gun collectors, '
          + 'including antique firearms & military arms. Click now to start '
          + 'selling your guns & investing in collectables today.',
        };
      };
    }}
    {...props}
  />
);
