import styled from 'styled-components';

import { PolymorphicBaseButton } from '../BaseComponents/Buttons/PolymorphicBaseButton';

export const Button = styled(PolymorphicBaseButton)` 
  display: inline-flex;
  cursor: pointer;
  
  min-width: 48px;
  min-height: 48px;

  align-items: center;

  :disabled {
    cursor: default;
  }

  ${({ theme }) => `
    ${theme.desktop}
    {
      min-width: unset;
      min-height: unset;
    }
  `}
`;
