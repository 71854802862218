import styled from 'styled-components';
import { Container } from '../index';
import { ICardSection } from './Panels.i';

export const PanelsSection = styled.section`
  background: ${(props) => props.theme.black[100]};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 100px 0 0;
`;

export const PanelsHeader = styled.header`
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const PanelsTitle = styled.h2`
  width: 100%;
  text-align: center;
  padding: 20px 0  0;
  position: relative;
  z-index: 1;
`;

export const PanelsCardSection = styled.section<ICardSection>`
  background: ${(props) => props.theme.black[100]};
  padding: 50px 0 100px;
  position: relative;
  page-break-inside: avoid;
  ${(props) => props.background && `
    background-image: url(${props.background});
    background-size: auto 50%;
    background-position: center bottom;
    background-repeat: no-repeat;

    ${props.theme.desktop} {
      background-size: 100% auto;
      background-position: center bottom;
    }
  `}

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to bottom, ${(props) => props.theme.black[100]} 50%, rgba(13, 13, 13, 0.7));
    z-index:0;
  }
`;

export const PanelsContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  max-width: 1200px;
  z-index: 1;
  position: relative;

  @media print {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) => props.theme.tablet} {
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
  }
  ${(props) => props.theme.widescreen} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
