import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncConsignmentThePastThatDrivesUsPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/ConsignmentThePastThatDrivesUsPage/ConsignmentThePastThatDrivesUsPage'
    componentImport={async () => (await import(
      'src/pages/ConsignmentThePastThatDrivesUsPage/ConsignmentThePastThatDrivesUsPage'
    ))}
    head={() => async () => {
      return {
        title: 'The Past That Drives Us',
        description: 'Over the last quarter century, RIAC has been the preferred auction firm to market '
        + 'and sell the collections of many industry icons, pioneers in gun collecting, and numerous world '
        + 'record setting pieces. It is a great honor an a tremendous responsibility.',
      };
    }}
    {...props}
  />
);
