import styled from 'styled-components';

import { SVGCheckboxAria } from '../BaseComponents/Inputs/SVGCheckboxAria';

export {
  SVGCheckBoxAriaContainer as SVGCheckboxContainer,
  SVGCheckBoxAriaLabel as SVGCheckboxLabel,
  SVGCheckboxAriaInput as SVGCheckboxInput,
  SVGCheckBoxAriaBorderBox as SVGCheckboxBorderBox,
  SVGCheckBoxCheckmark as SVGCheckboxCheckmark,
  SVGCheckBoxFocusBox as SVGCheckboxFocusBox,
} from '../BaseComponents/Inputs/SVGCheckboxAria';

export const SVGCheckbox = styled(SVGCheckboxAria)`
`;
