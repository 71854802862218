import React from 'react';

import { TextButton } from '@riac/design-system';
import { Menu } from 'src/components/Header/Main/Main.style';

export const MainMenu = () => (
  <Menu>
    <TextButton to='/gun-auctions'>Auctions</TextButton>
    <TextButton to='/consignment'>Consignment</TextButton>
    <TextButton to='/riac-blog'>Blog</TextButton>
    <TextButton to='/faq'>FAQ</TextButton>
    <TextButton to='/news-and-events'>News</TextButton>
    <TextButton to='/about-us'>About Us</TextButton>
  </Menu>
);
