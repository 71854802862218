import React from 'react';
import { TextButton } from '@riac/design-system';
import { SkipLinkContainer } from 'src/components/SkipLink/SkipLink.style';

export const SkipLink = () => {
  return (
    <SkipLinkContainer>
      <TextButton as='a' href='#maincontent'>
        Skip to main content
      </TextButton>
    </SkipLinkContainer>
  );
};
