import styled from 'styled-components';
import { ISpinnerContainer, ISpinnerSVG, ILoadedContent } from './Spinner.i';

export const SpinnerContainer = styled.div<ISpinnerContainer>`
  height: ${(props) => props.size === 'sm' ? '300px' : 'calc(100vh - 100px)'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SpinnerSVG = styled.svg<ISpinnerSVG>`
	width: 75px;
	animation: spin 5s infinite;
  #cylinder {
    fill: ${(props) => props.inverted ? props.theme.black[80] : props.theme.gold[50]};
  }
  #bullet1 {
    animation: ${(props) => props.inverted ? 'ammo1inverted' : 'ammo1'} 5s infinite;
  }
  #bullet2 {
    animation: ${(props) => props.inverted ? 'ammo2inverted' : 'ammo2'} 5s infinite;
  }
  #bullet3 {
    animation: ${(props) => props.inverted ? 'ammo3inverted' : 'ammo3'} 5s infinite;
  }
  #bullet4 {
    animation: ${(props) => props.inverted ? 'ammo4inverted' : 'ammo4'} 5s infinite;
  }
  #bullet5 {
    animation: ${(props) => props.inverted ? 'ammo5inverted' : 'ammo5'} 5s infinite;
  }
  #bullet6 {
    animation: ${(props) => props.inverted ? 'ammo6inverted' : 'ammo6'} 5s infinite;
  }

  @keyframes spin {
    0% {transform: rotate(0deg)}
    5% {transform: rotate(0deg)}
    15% {transform: rotate(-60deg)}
    25% {transform: rotate(-120deg)}
    35% {transform: rotate(-180deg)}
    45% {transform: rotate(-240deg)}
    55% {transform: rotate(-300deg)}
    65% {transform: rotate(-300deg)}
    85% {transform: rotate(720deg)}
    100% {transform: rotate(720deg)}
  }

  @keyframes ammo1 {
    0% {fill: ${(props) => props.theme.gold[50]}}
    5% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.gold[50]}}
  }
  @keyframes ammo2 {
    0% {fill: ${(props) => props.theme.gold[50]}}
    10% {fill: ${(props) => props.theme.gold[50]}}
    20% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.gold[50]}}
  }
  @keyframes ammo3 {
    0% {fill: ${(props) => props.theme.gold[50]}}
    20% {fill: ${(props) => props.theme.gold[50]}}
    35% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.gold[50]}}
  }
  @keyframes ammo4 {
    0% {fill: ${(props) => props.theme.gold[50]}}
    30% {fill: ${(props) => props.theme.gold[50]}}
    45% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.gold[50]}}
  }
  @keyframes ammo5 {
    0% {fill: ${(props) => props.theme.gold[50]}}
    40% {fill: ${(props) => props.theme.gold[50]}}
    55% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.gold[50]}}
  }
  @keyframes ammo6 {
    0% {fill: ${(props) => props.theme.gold[50]}}
    50% {fill: ${(props) => props.theme.gold[50]}}
    65% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.gold[50]}}
  }
  @keyframes ammo1inverted {
    0% {fill: ${(props) => props.theme.black[80]}}
    5% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.black[80]}}
  }
  @keyframes ammo2inverted {
    0% {fill: ${(props) => props.theme.black[80]}}
    10% {fill: ${(props) => props.theme.black[80]}}
    20% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.black[80]}}
  }
  @keyframes ammo3inverted {
    0% {fill: ${(props) => props.theme.black[80]}}
    20% {fill: ${(props) => props.theme.black[80]}}
    35% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.black[80]}}
  }
  @keyframes ammo4inverted {
    0% {fill: ${(props) => props.theme.black[80]}}
    30% {fill: ${(props) => props.theme.black[80]}}
    45% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.black[80]}}
  }
  @keyframes ammo5inverted {
    0% {fill: ${(props) => props.theme.black[80]}}
    40% {fill: ${(props) => props.theme.black[80]}}
    55% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.black[80]}}
  }
  @keyframes ammo6inverted {
    0% {fill: ${(props) => props.theme.black[80]}}
    50% {fill: ${(props) => props.theme.black[80]}}
    65% {fill: none}
    85% {fill: none}
    100% {fill: ${(props) => props.theme.black[80]}}
  }
`;

export const LoadedContent = styled.div<ILoadedContent>`
  display: ${(props) => props.isLoading ? 'none' : 'block'};
`;
