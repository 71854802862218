import styled from 'styled-components';
import { TextButton } from '../../index';
import { IPanelsCardContent } from './Card.i';

export const CardContainer = styled.article`
  background: rgba(196,196,196, 0.1);
  text-align:center;
  z-index: 2;
  height: 100%;

  ${(props) => props.theme.tablet} {
    &:last-of-type:nth-child(odd) {
      grid-column: 1 / span 2;
    }
  }
  ${(props) => props.theme.widescreen} {
    &:last-of-type:nth-child(odd) {
      grid-column: auto / auto;
    }
  }

  ${TextButton} {
    :hover {
      color: ${({ theme }) => theme.gold[50]};
      
      * {
        color: ${({ theme }) => theme.gold[50]};
      }
    }
  }
`;

export const CardImage = styled.figure`
  max-width: 100%;
  position: relative;

  ${(props) => props.theme.tablet} {
    height: 200px;
  }
  
  img {
    max-height: 100%;
    max-width: 100%;
    ${(props) => props.theme.tablet} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    :not([src]) {
        visibility: hidden;
    }
  }

`;

export const CardContent = styled.div<IPanelsCardContent>`
  padding: ${(props) => props.image ? '15px 15px 50px' : '50px 15px'};

  a {
    display: inline-block;
    min-height: 44px;
    
    ${(props) => props.theme.desktop} {
      min-height: auto;
    }
  }
`;

export const CardTitle = styled.h2<IPanelsCardContent>`
  font-family: ${(props) => props.theme.serif};
  color: ${(props) => props.theme.black[0]};
  text-transform: uppercase;
  font-size: ${(props) => props.image ? '2.6rem' : '3rem'};
  line-height: 1.5;
  margin-bottom: ${(props) => props.image ? '20px' : '40px'};
`;

export const CardCopy = styled.p`
  margin: 20px 0 30px;
`;
