import styled from 'styled-components';
import { ContentContainer } from '../Content';
import { TextButton } from '../AriaControls';

export const BreadcrumbList = styled(ContentContainer)`
  justify-content: flex-start;
  ul {
    list-style: none;
  }
  li {
    display: inline;
    :not(:last-child):after {
      content: '/';
      display: inline-block;
      margin: 0 10px;
    }
    font-size: 1.6rem;
    text-decoration: none;
    color: ${(props) => props.theme.black[50]};

    @media print {
      color: ${(props) => props.theme.black[50]};
      font-size: 1.2rem;
    }

    a {
      @media print {
        color: ${(props) => props.theme.black[50]};
        font-size: 1.2rem;
      }
      
      &:hover {
        color: ${(props) => props.theme.gold[50]};
      }
    }
  }

  /* This may or may not be necessary.
   * Might be better to just make TextButton itself inline-flex,
   * which Button already is, but I'm scared there was a reason
   * why I made it inline-block instead, and changing it may introduce a regression
   */
  ${TextButton} {
    display: inline-flex;
  }
`;
