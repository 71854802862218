import styled from 'styled-components';
import { ISection } from './Content.i';
import { Container } from '../Container/Container';

export const ContentSection = styled.section<ISection>`
  background: ${(props) => props.theme.black[100]};
  padding: 50px 0;
  min-height: ${(props) => props.size === 'lg' ? '750px' : '0'};
  display: flex;

  @media print {
    min-height: 0;
    padding: 0;
    border: 2px solid red;
  }

  ${(props) => props.theme.desktop} {
    padding: ${(props) => props.size === 'md' ? '200px 0' : '100px 0'};
  }
`;

export const ContentContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
`;

export const ContentTitle = styled.h2`
  font-family: ${(props) => props.theme.serif};
  color: ${(props) => props.theme.black[0]};
  font-variant: small-caps;
  font-size: 48px;
  text-align: center;
  max-width: 900px;
`;

export const ContentCopy = styled.div`
  font-family: ${(props) => props.theme.sansSerif};
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  color: ${(props) => props.theme.black[40]};
  margin: 20px 0 30px;
  text-align: center;
  max-width: 900px;

  @media print {
    margin: 0;
  }

  p {
    margin-bottom: 30px;
  }
`;
