import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTextField } from '@react-aria/textfield';
import { useForceFocus } from '../../../Hooks/useForceFocus';
import { InputError } from '../../../InputError/InputError';
import { InputSuccess } from '../../../InputSuccess/InputSuccess';

export type AddonsInputAriaProps = React.PropsWithChildren<{
  id?: string,
  name?: string,
  defaultValue?: string,
  className?: string,
  label?: string,
  required?: boolean,
  requiredSymbol?: string,
  type?: string,
  value?: string,
  onChange?: (value: string) => void,
  onKeyDown?: (event: any) => void,
  description?: string,
  error?: string,
  success?: string,
  before?: JSX.Element | JSX.Element[],
  after?: JSX.Element | JSX.Element[],
  inverted?: boolean,
  inline?: boolean,
  forceFocus?: boolean,
  theRef?: React.Ref<HTMLInputElement>,
}> & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const AddonsInputAria = (props : AddonsInputAriaProps) => {
  const ref = props.theRef || useRef<HTMLInputElement | HTMLTextAreaElement>();
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);

  useForceFocus(ref, props.forceFocus);

  return (
    <AddonsInputAriaContainer className = {props.className}>
      <AddonsInputAriaLabel {...labelProps}>
        {props.label}
        {props.required
          && <AddonsInputAriaRequiredSymbol>{props.requiredSymbol || '*'}</AddonsInputAriaRequiredSymbol>
        }
      </AddonsInputAriaLabel>
      <AddonsInputAriaControlWrapper>
        <AddonsInputAriaBeforeContainer>
          {props.before || ''}
        </AddonsInputAriaBeforeContainer>
        {props.type === 'textarea'
          && <AddonsInputAriaControl
            {...inputProps as unknown as React.InputHTMLAttributes<HTMLTextAreaElement>}
            disabled={props.disabled}
            as = {'textarea'}
            value = {props.value}
            ref = {ref}
          />
        || <AddonsInputAriaControl
          {...inputProps as React.InputHTMLAttributes<HTMLInputElement>}
          disabled={props.disabled}
          value = {props.value}
          ref = {ref}
        />
        }
        <AddonsInputAriaAfterContainer>
          {props.after || ''}
        </AddonsInputAriaAfterContainer>
      </AddonsInputAriaControlWrapper>
      {props.description && <AddonsInputAriaDescription {...descriptionProps}>{props.description}</AddonsInputAriaDescription> }
      {props.error && <AddonsInputAriaError {...errorMessageProps} error={props.error} /> }
      {props.success && <AddonsInputAriaSuccess {...errorMessageProps} success={props.success} /> }
    </AddonsInputAriaContainer>
  );
};

// STUB: Changing this to display flex while bringing in
// the Table component.  This may have unforseen consequences elsewhere
export const AddonsInputAriaContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;
export const AddonsInputAriaControlWrapper = styled.div`
  display: flex;
`;
export const AddonsInputAriaLabel = styled.label``;
export const AddonsInputAriaBeforeContainer = styled.div``;
export const AddonsInputAriaAfterContainer = styled.div``;
export const AddonsInputAriaControl = styled.input`
  flex: 0 1 100%;
`;
export const AddonsInputAriaRequiredSymbol = styled.span``;
export const AddonsInputAriaDescription = styled.div``;
export const AddonsInputAriaError = styled(InputError)``;

export const AddonsInputAriaSuccess = styled(InputSuccess)``;


