import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

// Component to scroll to top of page when route changes.
const ToTop = ({history, location}) => {
  const dontScrollIntoViewOnPaths = ['/riac-blog', '/news'];

  useEffect(() => {
    if (history.action === 'POP') {
      return;
    }

    let {hash, pathname} = location;
    if (hash) {
      let element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({block: 'start', behavior: 'smooth'});
      }
    } else if (!dontScrollIntoViewOnPaths.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  });

  return (null);
};

ToTop.propTypes = {
  history: PropTypes.object,
};

export const ScrollToTop = withRouter(ToTop);
