import styled from 'styled-components';
import { Container } from '../index';

export const ListSection = styled.section`
  background: ${(props) => props.theme.black[100]};
  background: linear-gradient(to bottom, rgba(13, 13, 13, 1), rgba(13,13,13, 0.985));
  padding: 200px 0;
`;

export const ListContainer = styled(Container)`
  display: block;
  max-width: 1200px;
`;

export const ListTitle = styled.h2`
  font-family: ${(props) => props.theme.serif};
  color: ${(props) => props.theme.black[0]};
  font-variant: small-caps;
  font-size: 48px;
  text-align: left;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;
