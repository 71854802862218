import React from 'react';
import styled from 'styled-components';
import { GoX } from 'react-icons/go';

import { ITextButtonProps, TextButton } from './TextButton';
import { IPolymorphicBaseButtonProps } from '../../AriaControls/BaseComponents/Buttons/PolymorphicBaseButton';

const XButtonComponent = (props: ITextButtonProps & IPolymorphicBaseButtonProps) => {
  return <TextButton {...props}><GoX/></TextButton>;
};

export const XButton = styled(XButtonComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
`;
