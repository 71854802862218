import React, { useRef } from 'react';
import styled from 'styled-components';
import { useComboBoxState } from '@react-stately/combobox';
import { useComboBox } from '@react-aria/combobox';
import { useFilter } from '@react-aria/i18n';
import { SelectProps, useSelectState } from '@react-stately/select';
import { HiddenSelect, useSelect } from '@react-aria/select';
import { useButton } from '@react-aria/button';

import { PopoverAria, PopoverAriaProps } from '../PopoverAria';
import { ListBoxAria, ListBoxAriaProps } from '../ListBoxAria';
import { InputError } from '../../../InputError/InputError';

export type SelectAriaProps = React.PropsWithChildren<{
  onChange?: (key: React.Key) => void,
  value?: React.Key,
  Popover?: React.ComponentType<PopoverAriaProps>,
  ListBox?: React.ComponentType<ListBoxAriaProps>,
  className?: string,
  name?: string,
  dropDownIcon?: React.ReactNode,
  inverted?: boolean,
  noOptionsBorder?: boolean,
  required?: boolean,
  requiredSymbol?: string,
  error?: string | JSX.Element,
  isSearchable?: boolean,
  searchFilter?: 'startsWith' | 'endsWith' | 'contains',
}> & Omit<
SelectProps<{}>,
'onSelectionChange' | 'selectedKey'
>;

export const SelectAria = (props : SelectAriaProps): JSX.Element => {
  // We expose onChange and value in props but that's not what react aria state management calls them
  // so we'll transfer and delete
  const reactAriaNamedSelectProps: Omit<SelectAriaProps, 'value'> & {value?: React.Key} = Object.assign({}, props, {
    onSelectionChange: props.onChange,
    selectedKey: props.value,
  });
  delete reactAriaNamedSelectProps.onChange;
  delete reactAriaNamedSelectProps.value;

  // https://react-spectrum.adobe.com/react-aria/useFilter.html#api
  // but the docs for the options we feed here are actually here:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
  const searchFilter = useFilter({ sensitivity: 'base' });

  // If we specified a searchable select we're going to transparently use a combobox instead
  const state = props.isSearchable
    ? useComboBoxState({
      ...reactAriaNamedSelectProps,
      defaultFilter: searchFilter[props.searchFilter || 'startsWith'],
    })
    : useSelectState({ ...reactAriaNamedSelectProps });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  let theProps;

  if(props.isSearchable) {
    theProps = useComboBox({
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    }, state);
  } else {
    theProps = useSelect(props, state, buttonRef);
  }

  const { buttonProps } = useButton(
    { ...(props.isSearchable ? theProps.buttonProps : theProps.triggerProps) }
    , buttonRef
  );

  const Popover = props.Popover || PopoverAria;
  const ListBox = props.ListBox || ListBoxAria;

  return (
    <SelectAriaContainer className = { props.className }>
      <SelectAriaLabel { ...theProps.labelProps }>
        { props.label }
        { props.required && (
          <SelectAriaRequiredSymbol>{props.requiredSymbol || '*'}</SelectAriaRequiredSymbol>
        )}
      </SelectAriaLabel>
      {typeof HTMLElement == 'undefined' ? '' : <HiddenSelect
        state={state}
        triggerRef={buttonRef}
        label={props.label}
        name={props.name}
      />}
      <SelectAriaControlContainer>
        <SelectAriaTrigger { ...buttonProps } ref = { buttonRef }>
          {props.isSearchable && (
            <SelectAriaSearchInput
              {...theProps.inputProps}
              ref = {inputRef}
            />
          )}
          { !props.isSearchable && (
            <SelectAriaSelectedValue { ...theProps.valueProps }>
              {/* Docs do not show feeding a type to useSelectState or useSelect
              * https://react-spectrum.adobe.com/react-aria/useSelect.html
              * Not really sure why these TS errors are happening
              * Code works, so these fields definitely exist
              */}
              {state.selectedItem
                ? state.selectedItem.rendered
                : props.placeholder || 'Select an option'
              }
            </SelectAriaSelectedValue>
          )}
          <SelectAriaDropDownIcon>
            {props.dropDownIcon}
          </SelectAriaDropDownIcon>
        </SelectAriaTrigger>
        {state.isOpen && (
          <Popover
            isOpen = { state.isOpen }
            onClose = { state.close }
            inverted = { props.inverted }
            popoverRef = {props.isSearchable ? popoverRef : undefined}
          >
            {
              /* Because of typescript constraints from AriaListBoxProps,
              * children must be present, so we can't do self closing tag
              */
            }
            <ListBox
              {...(props.isSearchable ? theProps.listBoxProps : theProps.menuProps)}
              state = { state }
              inverted = { props.inverted }
              noOptionsBorder = {props.noOptionsBorder}
            >
            </ListBox>
          </Popover>
        )}
        <InputError error = {props.error}/>
      </SelectAriaControlContainer>
    </SelectAriaContainer>
  );
};

export const SelectAriaContainer = styled.div`
  position: relative;
`;

export const SelectAriaLabel = styled.div``;
export const SelectAriaRequiredSymbol = styled.span``;

/* Though we don't like to use !important we have to
 * because of the global tag style for input:not([type='submit'])
 * in src/styles/global.ts
 */
export const SelectAriaSearchInput = styled.input`
  background-color: rgba(0,0,0,0) !important;
  margin: 0 !important;
  border: none !important;
`;

export const SelectAriaTrigger = styled.button`
  display: flex;

  min-width: 44px;
  min-height: 44px;

  ${({ theme }) => `${theme.desktop} {
    min-width: unset;
    min-height: unset;
  }`}
`;

export const SelectAriaSelectedValue = styled.div`
  display: flex;
  align-items: center; 
`;
export const SelectAriaDropDownIcon = styled.div``;
export const SelectAriaControlContainer = styled.div``;


