export const svg = {
  outer: `M 4.00,0.00
    C 4.00,0.00 7.00,3.00 7.00,3.00
      7.00,3.00 4.00,0.00 4.00,0.00 Z
    M 291.00,0.00
    C 291.00,0.00 292.00,0.00 292.00,0.00
      292.00,0.00 291.00,1.00 291.00,1.00
      291.00,1.00 291.00,0.00 291.00,0.00 Z
    M 318.00,0.00
    C 318.00,0.00 323.00,1.00 323.00,1.00
      323.00,1.00 318.00,0.00 318.00,0.00 Z
    M 333.00,0.00
    C 333.00,0.00 337.00,0.00 337.00,0.00
      337.00,0.00 336.00,3.00 336.00,3.00
      336.00,3.00 333.00,0.00 333.00,0.00 Z
    M 285.00,1.00
    C 285.00,1.00 286.00,1.00 286.00,1.00
      286.00,1.00 285.00,2.00 285.00,2.00
      285.00,2.00 285.00,1.00 285.00,1.00 Z
    M 307.00,1.00
    C 307.00,3.33 306.03,2.93 304.00,3.00
      304.00,3.00 307.00,1.00 307.00,1.00 Z
    M 339.00,1.00
    C 343.99,5.78 347.83,3.57 354.00,3.78
      354.00,3.78 362.00,3.78 362.00,3.78
      383.77,4.24 401.66,6.72 423.00,10.58
      428.91,11.64 451.30,16.79 455.86,18.99
      459.52,20.76 459.74,24.39 460.58,28.00
      461.97,33.96 464.07,40.53 466.81,46.00
      475.21,62.82 486.94,76.28 503.00,86.19
      516.48,94.50 533.10,99.81 549.00,100.00
      558.84,100.11 564.49,99.27 574.00,97.00
      577.53,96.16 583.65,93.96 587.00,95.15
      590.42,96.37 596.32,102.33 599.00,105.00
      599.00,105.00 620.73,129.42 620.73,129.42
      646.50,159.78 668.28,199.25 680.93,237.00
      683.03,243.25 691.11,268.84 691.24,274.00
      691.37,279.03 687.30,281.05 684.09,284.17
      681.69,286.51 677.00,292.22 674.90,295.00
      665.69,307.18 657.18,326.56 657.00,342.00
      656.72,366.51 658.18,379.58 671.62,401.00
      675.01,406.40 679.53,411.47 684.00,416.00
      686.30,418.33 690.35,421.80 691.11,425.00
      691.70,427.45 690.34,431.54 689.74,434.00
      689.74,434.00 684.35,453.00 684.35,453.00
      676.69,477.51 667.00,501.87 653.79,524.00
      640.78,545.82 623.17,571.29 605.13,589.08
      601.32,592.85 592.29,604.09 588.41,606.02
      585.36,607.54 580.11,604.94 577.00,604.04
      571.68,602.50 562.53,601.03 557.00,601.00
      546.40,600.95 538.45,600.96 528.00,603.59
      494.17,612.12 462.18,645.66 459.00,681.00
      459.00,681.00 446.00,684.72 446.00,684.72
      446.00,684.72 421.00,690.54 421.00,690.54
      415.67,691.54 405.05,692.01 401.00,695.00
      401.00,695.00 369.00,697.00 369.00,697.00
      369.00,697.00 337.00,697.00 337.00,697.00
      324.25,696.98 294.85,692.75 282.00,689.88
      275.08,688.34 256.38,684.71 251.04,681.59
      245.09,678.11 245.19,671.03 243.25,665.00
      240.28,655.73 236.26,649.02 230.93,641.00
      215.86,618.36 186.51,601.04 159.00,601.00
      159.00,601.00 149.00,601.00 149.00,601.00
      143.59,601.03 135.24,602.37 130.00,603.75
      126.55,604.67 122.49,606.87 119.00,605.50
      115.74,604.22 109.49,597.65 106.83,594.91
      106.83,594.91 84.87,571.00 84.87,571.00
      61.52,543.83 42.72,509.20 29.55,476.00
      25.31,465.30 16.36,438.82 15.07,428.00
      14.38,422.28 18.30,419.75 22.00,416.00
      27.47,410.44 31.55,405.69 35.60,399.00
      48.15,378.25 49.27,365.41 49.00,342.00
      48.78,323.42 38.95,306.72 28.56,292.00
      26.28,288.76 23.93,285.86 20.99,283.18
      18.09,280.55 14.75,279.16 14.15,274.96
      13.49,270.38 16.29,266.36 17.41,262.00
      20.32,250.62 23.91,238.94 28.20,228.00
      43.52,188.97 59.93,160.78 86.20,128.00
      86.20,128.00 105.00,107.01 105.00,107.01
      107.50,104.37 115.02,97.10 118.01,95.60
      122.01,93.60 126.99,95.77 131.00,96.85
      137.35,98.56 145.43,99.92 152.00,100.00
      183.81,100.37 216.17,84.64 233.08,57.00
      238.24,48.55 241.54,41.44 244.49,32.00
      245.36,29.22 246.63,21.61 248.58,19.98
      250.06,18.75 262.31,15.59 265.00,14.83
      265.00,14.83 274.00,11.53 274.00,11.53
      274.00,11.53 282.00,10.68 282.00,10.68
      282.00,10.68 310.00,6.00 310.00,6.00
      310.00,6.00 310.00,3.00 310.00,3.00
      314.71,7.51 318.04,4.41 324.00,4.14
      329.91,3.87 334.27,5.35 339.00,1.00 Z
    M 289.00,3.00
    C 289.00,3.00 290.00,3.00 290.00,3.00
      290.00,3.00 289.00,4.00 289.00,4.00
      289.00,4.00 289.00,3.00 289.00,3.00 Z
    M 303.00,4.00
    C 303.00,4.00 304.00,4.00 304.00,4.00
      304.00,4.00 303.00,5.00 303.00,5.00
      303.00,5.00 303.00,4.00 303.00,4.00 Z
    M 0.00,8.00
    C 0.00,8.00 1.00,8.00 1.00,8.00
      1.00,8.00 0.00,9.00 0.00,9.00
      0.00,9.00 0.00,8.00 0.00,8.00 Z
    M 277.00,8.00
    C 277.00,8.00 278.00,8.00 278.00,8.00
      278.00,8.00 277.00,9.00 277.00,9.00
      277.00,9.00 277.00,8.00 277.00,8.00 Z
    M 273.00,9.00
    C 273.00,9.00 274.00,9.00 274.00,9.00
      274.00,9.00 273.00,10.00 273.00,10.00
      273.00,10.00 273.00,9.00 273.00,9.00 Z
    M 333.00,26.28
    C 333.00,26.28 319.00,27.29 319.00,27.29
      319.00,27.29 292.00,31.95 292.00,31.95
      287.29,32.48 270.76,35.48 267.43,37.98
      265.19,39.66 264.07,44.34 262.99,47.00
      262.99,47.00 253.60,66.00 253.60,66.00
      244.35,81.63 229.51,96.38 214.00,105.80
      192.57,118.80 172.62,122.28 148.00,122.00
      148.00,122.00 130.00,119.76 130.00,119.76
      124.44,119.13 121.67,122.36 118.00,126.00
      112.35,131.63 103.09,142.57 98.12,149.00
      77.21,176.04 64.97,197.64 51.78,229.00
      49.14,235.26 40.26,260.23 39.57,266.00
      38.99,270.89 42.70,273.51 45.54,277.00
      48.96,281.20 55.64,291.23 58.29,296.00
      67.39,312.34 72.21,334.39 72.00,353.00
      71.78,371.44 65.40,391.98 56.42,408.00
      54.16,412.05 48.96,419.50 45.94,423.00
      43.58,425.73 39.86,429.21 39.62,433.00
      39.51,434.79 41.10,439.94 41.63,442.00
      41.63,442.00 49.32,465.00 49.32,465.00
      54.75,479.68 61.21,494.32 68.86,508.00
      68.86,508.00 75.58,518.57 75.58,518.57
      75.58,518.57 83.52,532.00 83.52,532.00
      83.52,532.00 96.49,549.91 96.49,549.91
      96.49,549.91 113.72,570.00 113.72,570.00
      116.46,573.13 121.25,579.13 125.00,580.74
      128.94,582.43 135.69,579.63 140.00,579.09
      140.00,579.09 166.00,579.09 166.00,579.09
      172.44,579.08 178.82,580.88 185.00,582.58
      213.17,590.33 234.95,606.87 251.26,631.00
      255.86,637.81 259.16,644.40 262.20,652.00
      263.33,654.83 265.24,661.38 267.43,663.07
      269.63,664.76 281.57,666.92 285.00,667.74
      300.53,671.46 316.13,673.00 332.00,674.09
      332.00,674.09 342.00,675.00 342.00,675.00
      367.69,675.30 387.70,673.68 413.00,669.25
      413.00,669.25 431.00,665.47 431.00,665.47
      433.19,664.93 436.83,664.41 438.57,663.07
      441.09,661.13 443.44,652.34 444.87,649.00
      448.40,640.79 453.09,633.23 458.29,626.00
      477.79,598.91 508.69,581.35 542.00,578.91
      545.87,578.63 547.80,577.85 552.00,578.09
      552.00,578.09 569.00,579.66 569.00,579.66
      573.06,580.48 578.11,582.31 582.00,580.17
      585.47,578.26 590.56,572.12 593.28,569.00
      593.28,569.00 610.77,547.99 610.77,547.99
      630.93,519.84 641.27,503.26 654.40,471.00
      654.40,471.00 664.15,443.00 664.15,443.00
      664.85,440.48 666.79,435.34 666.50,433.00
      666.09,429.69 662.04,425.58 659.92,423.00
      656.94,419.36 651.92,412.08 649.59,408.00
      640.72,392.48 634.21,370.92 634.00,353.00
      633.79,334.71 638.78,312.03 647.71,296.00
      650.36,291.23 657.04,281.21 660.46,277.00
      663.43,273.35 666.94,271.08 666.20,266.00
      665.00,257.83 657.20,236.16 653.81,228.00
      644.34,205.23 633.00,183.08 618.57,163.00
      618.57,163.00 610.45,153.00 610.45,153.00
      610.45,153.00 590.91,129.00 590.91,129.00
      588.82,126.75 583.62,121.23 581.00,120.11
      578.11,118.88 573.31,120.60 570.00,120.93
      570.00,120.93 545.00,122.00 545.00,122.00
      528.58,121.80 507.05,114.92 493.00,106.55
      478.73,98.04 461.24,81.34 452.86,67.00
      452.86,67.00 443.45,48.00 443.45,48.00
      442.40,45.40 441.15,40.43 439.30,38.58
      436.44,35.71 418.91,32.50 414.00,31.42
      394.00,27.04 353.31,24.40 333.00,26.28 Z
    M 338.00,57.51
    C 338.00,57.51 361.00,57.00 361.00,57.00
      367.85,57.03 372.53,58.47 379.00,60.50
      403.62,68.22 422.74,86.76 431.56,111.00
      436.26,123.90 436.02,131.72 436.00,145.00
      435.97,163.05 428.72,178.81 417.86,192.91
      411.04,201.77 400.87,209.49 391.00,214.68
      375.56,222.80 362.85,223.19 346.00,223.00
      340.33,222.93 339.33,222.36 334.00,221.21
      306.34,215.24 283.55,194.61 274.09,168.00
      269.68,155.59 269.85,146.80 270.00,134.00
      270.36,103.45 291.79,75.58 319.00,63.31
      324.10,61.01 332.44,58.89 338.00,57.51 Z
    M 344.00,79.29
    C 314.83,84.13 292.14,107.87 292.00,138.00
      291.97,143.70 292.04,149.43 293.44,155.00
      299.81,180.37 323.46,200.69 350.00,201.00
      369.11,201.22 387.06,195.07 399.68,180.00
      426.37,148.12 414.98,97.25 375.00,82.69
      364.54,78.89 355.03,78.24 344.00,79.29 Z
    M 4.00,146.00
    C 4.00,146.00 5.00,146.00 5.00,146.00
      5.00,146.00 4.00,147.00 4.00,147.00
      4.00,147.00 4.00,146.00 4.00,146.00 Z
    M 161.00,162.21
    C 161.00,162.21 174.00,162.21 174.00,162.21
      185.39,162.02 191.44,163.80 202.00,167.50
      211.45,170.82 221.91,177.99 229.00,185.00
      268.78,224.36 260.83,289.82 212.00,317.99
      204.73,322.18 192.34,326.53 184.00,327.72
      171.76,329.45 165.12,329.60 153.00,326.63
      138.40,323.04 128.83,319.27 117.00,309.54
      84.78,283.02 78.93,237.69 98.64,202.00
      104.42,191.54 115.39,181.92 125.00,175.04
      135.93,167.21 147.99,164.31 161.00,162.21 Z
    M 593.00,309.00
    C 588.00,309.74 576.49,318.42 570.00,321.34
      554.67,328.22 540.48,329.82 524.00,327.95
      466.77,321.46 434.53,256.10 461.04,206.00
      472.07,185.15 493.59,167.95 517.00,163.46
      525.10,161.90 530.86,161.91 539.00,162.00
      551.76,162.15 564.79,165.88 575.91,172.05
      588.35,178.96 598.38,187.85 605.94,200.00
      614.35,213.53 619.18,231.08 619.00,247.00
      618.83,261.32 614.06,276.77 606.72,289.00
      603.20,294.87 594.29,303.40 593.00,309.00 Z
    M 163.00,184.29
    C 134.60,188.99 110.05,210.86 110.00,241.00
      110.00,241.00 110.00,251.00 110.00,251.00
      110.10,258.81 112.34,265.07 115.76,272.00
      125.25,291.27 145.01,305.74 167.00,306.00
      179.54,306.14 185.36,306.08 197.00,300.51
      205.82,296.28 211.08,292.68 217.38,285.00
      243.32,253.42 233.76,204.78 195.00,188.51
      185.70,184.61 173.00,183.34 163.00,184.29 Z
    M 585.00,276.00
    C 589.13,275.01 588.42,272.72 590.45,269.42
      597.09,258.64 596.14,251.11 596.00,239.00
      595.65,209.90 566.38,184.33 538.00,184.00
      514.17,183.73 491.12,194.70 480.40,217.00
      461.37,256.59 487.35,305.93 533.00,306.00
      543.27,306.02 553.84,304.98 563.00,299.78
      569.99,295.81 568.03,292.97 577.00,290.00
      577.00,290.00 576.00,288.00 576.00,288.00
      581.12,285.36 583.41,281.41 585.00,276.00 Z
    M 78.00,221.00
    C 78.00,221.00 79.00,221.00 79.00,221.00
      79.00,221.00 78.00,222.00 78.00,222.00
      78.00,222.00 78.00,221.00 78.00,221.00 Z
    M 582.00,272.00
    C 582.00,272.00 583.00,272.00 583.00,272.00
      583.00,272.00 582.00,273.00 582.00,273.00
      582.00,273.00 582.00,272.00 582.00,272.00 Z
    M 586.00,273.00
    C 586.00,273.00 587.00,273.00 587.00,273.00
      587.00,273.00 586.00,274.00 586.00,274.00
      586.00,274.00 586.00,273.00 586.00,273.00 Z
    M 575.00,278.00
    C 575.00,278.00 576.00,278.00 576.00,278.00
      576.00,278.00 575.00,279.00 575.00,279.00
      575.00,279.00 575.00,278.00 575.00,278.00 Z
    M 573.00,280.00
    C 573.00,280.00 574.00,280.00 574.00,280.00
      574.00,280.00 573.00,281.00 573.00,281.00
      573.00,281.00 573.00,280.00 573.00,280.00 Z
    M 576.00,280.00
    C 576.00,280.00 580.00,283.00 580.00,283.00
      580.00,283.00 577.00,284.00 577.00,284.00
      577.00,284.00 576.00,280.00 576.00,280.00 Z
    M 348.00,298.42
    C 354.37,297.65 362.86,298.61 369.00,300.44
      374.59,302.10 382.60,306.43 387.00,310.21
      419.20,337.91 406.58,393.50 364.00,401.39
      360.58,402.02 353.29,403.13 350.00,402.90
      350.00,402.90 342.00,401.28 342.00,401.28
      299.94,392.43 286.74,339.76 318.00,311.10
      326.79,303.05 336.58,300.15 348.00,298.42 Z
    M 352.00,320.45
    C 337.14,321.83 323.73,332.19 323.04,348.00
      322.28,365.26 334.33,379.79 352.00,380.00
      384.16,380.37 393.93,340.89 369.00,324.68
      364.15,321.53 357.67,320.66 352.00,320.45 Z
    M 161.00,372.21
    C 161.00,372.21 171.00,372.21 171.00,372.21
      179.91,372.04 192.75,374.13 201.00,377.45
      210.55,381.30 220.51,387.21 228.00,394.31
      260.64,425.28 262.95,475.53 234.54,510.00
      227.96,517.99 217.26,526.10 208.00,530.73
      194.62,537.42 181.71,539.17 167.00,539.00
      157.60,538.89 146.63,536.08 138.00,532.42
      95.03,514.19 76.01,465.77 93.74,423.00
      105.86,393.79 130.36,377.06 161.00,372.21 Z
    M 526.00,372.42
    C 567.75,367.33 605.76,394.71 616.42,435.00
      620.70,451.17 619.09,467.21 614.28,483.00
      611.29,492.83 604.11,504.66 596.98,512.00
      590.34,518.84 584.55,524.28 576.00,528.74
      559.52,537.32 548.25,539.21 530.00,539.00
      523.74,538.92 514.00,536.66 508.00,534.67
      461.03,519.02 439.38,465.66 458.87,421.00
      467.65,400.90 486.40,383.99 507.00,376.70
      512.98,374.58 519.74,373.37 526.00,372.42 Z
    M 168.00,394.28
    C 168.00,394.28 158.00,395.51 158.00,395.51
      138.82,399.48 122.09,412.98 114.34,431.00
      112.82,434.51 111.77,439.22 111.00,443.00
      111.00,443.00 109.18,455.00 109.18,455.00
      109.17,483.23 128.58,508.40 156.00,515.00
      161.35,516.29 162.30,516.93 168.00,517.00
      177.81,517.11 190.35,514.86 199.00,510.13
      214.21,501.83 226.77,487.26 230.48,470.00
      236.68,441.21 224.42,414.28 198.00,400.90
      189.07,396.37 178.07,393.38 168.00,394.28 Z
    M 531.00,394.28
    C 531.00,394.28 522.00,395.52 522.00,395.52
      509.62,398.20 496.38,406.33 488.32,416.00
      462.52,446.95 472.77,495.11 510.00,511.65
      516.43,514.51 527.93,516.92 535.00,517.00
      543.24,517.09 552.42,515.17 560.00,511.83
      598.94,494.67 608.58,443.80 579.83,413.04
      573.15,405.90 563.21,400.29 554.00,397.17
      547.66,395.02 537.66,393.69 531.00,394.28 Z
    M 296.00,624.00
    C 296.28,621.59 293.70,619.77 292.08,618.00
      288.12,613.65 284.49,608.99 281.37,604.00
      271.03,587.45 270.72,580.71 269.32,562.00
      268.86,555.80 271.38,541.12 273.34,535.00
      290.30,482.09 354.33,460.33 400.00,491.58
      406.80,496.24 411.17,499.45 416.54,506.00
      444.31,539.86 443.41,590.45 411.00,621.02
      402.15,629.37 391.61,636.26 380.00,640.11
      369.16,643.71 359.27,644.13 348.00,644.00
      328.78,643.77 311.51,634.56 296.00,624.00 Z
    M 350.00,499.29
    C 350.00,499.29 335.00,502.04 335.00,502.04
      318.52,506.94 304.36,519.45 297.12,535.00
      291.02,548.11 290.27,568.53 295.61,582.00
      295.61,582.00 299.83,590.00 299.83,590.00
      301.37,593.04 301.91,594.98 304.06,597.91
      313.96,611.44 334.20,621.80 351.00,622.00
      360.94,622.11 370.99,620.41 380.00,616.02
      420.43,596.31 425.69,542.02 392.00,513.30
      386.45,508.57 378.95,504.60 372.00,502.36
      366.65,500.63 355.56,498.89 350.00,499.29 Z`,
  inner1: `M 345.00,81.43
    C 386.92,76.25 419.74,112.92 410.49,154.00
      408.31,163.66 402.80,172.58 396.41,179.99
      391.05,186.19 384.51,190.59 377.00,193.78
      367.76,197.69 361.01,199.11 351.00,199.00
      343.78,198.91 337.65,197.28 331.00,194.60
      307.00,184.90 293.71,163.60 294.00,138.00
      294.34,108.60 316.75,86.07 345.00,81.43 Z`,
  inner2: `M 529.00,186.42
    C 555.70,183.16 581.88,199.71 590.82,225.00
      592.91,230.91 593.92,233.64 594.00,240.00
      594.00,240.00 594.00,250.00 594.00,250.00
      593.79,267.37 581.02,282.48 568.00,292.52
      565.18,294.69 563.18,296.85 560.00,298.59
      551.45,303.26 541.52,304.11 532.00,304.00
      523.62,303.90 516.47,301.66 509.00,298.02
      478.36,283.11 468.00,246.89 482.99,217.00
      492.08,198.88 509.71,189.34 529.00,186.42 Z`,
  inner3: `M 533.00,396.44
    C 540.13,395.87 547.31,397.32 554.00,399.66
      577.24,407.77 593.70,427.93 594.00,453.00
      594.00,453.00 594.00,457.00 594.00,457.00
      593.95,487.58 569.91,511.63 540.00,514.83
      534.12,515.46 529.67,514.56 524.00,513.40
      497.86,508.06 480.49,489.30 476.73,463.00
      475.64,455.40 475.85,447.42 478.04,440.00
      484.28,418.88 500.52,403.04 522.00,397.90
      522.00,397.90 533.00,396.44 533.00,396.44 Z`,
  inner4: `M 351.00,501.47
    C 356.71,501.17 365.53,502.74 371.00,504.51
      378.37,506.89 385.07,510.24 391.00,515.30
      421.42,541.24 418.49,588.55 385.00,610.46
      376.73,615.87 366.78,618.90 357.00,619.83
      348.28,620.67 341.05,618.93 333.00,615.93
      323.19,612.28 312.33,605.45 306.11,596.83
      303.71,593.51 303.48,591.21 301.74,588.00
      301.74,588.00 297.64,581.00 297.64,581.00
      292.39,568.68 293.32,550.29 298.26,538.00
      305.00,521.21 320.53,508.00 338.00,503.59
      338.00,503.59 351.00,501.47 351.00,501.47 Z`,
  inner5: `M 168.00,396.44
    C 177.84,395.65 188.29,398.48 197.00,402.90
      222.42,415.77 234.84,442.28 228.10,470.00
      221.82,495.86 199.92,512.32 174.00,514.83
      167.44,515.47 164.22,514.59 158.00,513.21
      122.06,505.19 102.34,467.06 116.03,433.00
      123.30,414.91 140.00,401.44 159.00,397.51
      159.00,397.51 168.00,396.44 168.00,396.44 Z`,
  inner6: `M 163.00,186.44
    C 198.78,181.85 231.49,210.53 229.96,247.00
      229.39,260.69 224.00,273.45 215.38,284.00
      209.86,290.76 203.77,294.77 196.00,298.51
      185.72,303.44 179.17,304.13 168.00,304.00
      138.62,303.65 112.14,277.45 112.00,248.00
      112.00,248.00 112.00,241.00 112.00,241.00
      112.04,233.01 114.22,226.13 117.76,219.00
      127.22,199.97 142.54,190.22 163.00,186.44 Z`,
};
