import React, { CSSProperties, useContext, useState } from 'react';
import { BoxButton, Container, Input, SVGCheckbox } from '@riac/design-system';
import { HeadContext } from 'src/lib/HeadContext';

const WhiskeyAndWarStories = (): JSX.Element => {
  const [formSending, setFormSending] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [plusOne, setPlusOne] = useState(false);
  const head = useContext(HeadContext);

  if(head.isHead) {
    return null;
  }

  const submitForm = async (): Promise<void> => {
    setFormSending(true);
    const submitData = await (await fetch('/api/data-collect/submit', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: email,
        campaign: 'whiskey-and-war-stories',
        plus: plusOne,
      }),
    })).json();

    if(submitData.success) {
      setFormSending(false);
      setFormSuccess(true);
      setName('');
      setEmail('');
      setPlusOne(false);
    } else {
      setFormSending(false);
    }
  };
  const wrapperStyle: CSSProperties = {
    padding: '150px 10px',
  };

  const backgroundImageStyle: CSSProperties = {
    backgroundImage: `url(https://cdn.rockislandauction.com/dev_cdn/4092/1291.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  return (<div className={'container mx-auto'}>

    <div className={'flex flex-row justify-center mb-5 relative'}>
      <div className={'absolute z-0 opacity-60 w-full h-full top-0'} style={backgroundImageStyle}></div>
      <div className={'text-center relative'} style={wrapperStyle}>
        {formSuccess
          ? <div className={'relative z-10'} style={{ minHeight: '498px' }}>
            <h1>Success!</h1>
            <h3>We look forward to seeing you, Saturday, August 17th</h3>
          </div>
          : <div className={'relative z-10'}>
            <h1>You are invited to a Private Event</h1>
            <h2>Whiskey & War Stories - Horse Soldier Bourbon</h2>
            <h3>Saturday, August 17th 5pm - 9pm</h3>
            <h3 className={'mb-10'}>Hosted by Rock Island Auction Company</h3>
            <ul className={'list-none'}>
              <li className={'mb-1'}>5:00 pm - 5:30 pm- Tasting Horse Soldier Bourbon</li>
              <li className={'mb-1'}>5:30 pm - 6:30 pm- BBQ and more</li>
              <li className={'mb-1'}>6:30 pm - Whiskey & War Stories</li>
              <li className={'mb-1'}>8:00 pm - Private Auction Viewing</li>
            </ul>
            <form>
              <Input
                name={'name'}
                label={'Name'}
                onChange={(val) => setName(val)}
                required
              />
              <Input
                name={'email'}
                label={'Email'}
                onChange={(val) => setEmail(val)}
                required
              />
              <Container className={'mb-5'}>
                <SVGCheckbox fill={'black'} label={'Plus one?'} onChange={(val) => setPlusOne(val)} />
              </Container>
              <BoxButton
                onPress={() => submitForm()} isDisabled={formSending || formSuccess}
              >{formSending ? 'Please wait...' : 'Submit'}</BoxButton>
            </form>
          </div>}
      </div>
    </div>
  </div>);
};

export default WhiskeyAndWarStories;
