import React, { useEffect, useState } from 'react';

export function useBubbleShield(ref: React.RefObject<HTMLElement>): void {
  const [isSet, setIsSet] = useState<boolean>(false);
  useEffect(() => {
    const cancelBubble = (event: Event): void => {
      event.preventDefault();
      event.stopPropagation();
    };

    if(ref.current && !isSet) {
      ref?.current?.addEventListener('touchend', cancelBubble);
      setIsSet(true);
    }

    () => {
      ref?.current?.removeEventListener('touchend', cancelBubble);
    };
  }, [ref.current]);
}
