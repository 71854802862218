import React, { useRef, useContext } from 'react';
import styled from 'styled-components';
import { useRadio } from '@react-aria/radio';
import { VisuallyHidden } from '@react-aria/visually-hidden';

import { RadioContext } from './RadioGroupAria';
import { AriaRadioProps } from '@react-types/radio';
import { useFocusRing } from '../../../useFocusRing';

export type SVGRadioAriaProps = React.PropsWithChildren<{
  className?: string,
  inverted?: boolean,
  RadioSVG?: React.ComponentType,
}> & AriaRadioProps;

export const SVGRadioAria = (props : SVGRadioAriaProps) => {
  const state = useContext(RadioContext);
  if(state === null) {
    throw new Error('SVGRadioAria must be used within a RadioContext');
  }
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = state.selectedValue === props.value;

  const RadioSVG = props.RadioSVG || RadioSVGBase;

  return (
    <SVGRadioAriaLabel className = { props.className } isFocused = { isFocusVisible } isSelected = { isSelected } inverted = { props.inverted }>
      <VisuallyHidden>
        <SVGRadioAriaInput
          { ...inputProps }
          { ...focusProps }
          ref = { ref }
        />
      </VisuallyHidden>
      <RadioSVG isFocused = { isFocusVisible } isSelected = { isSelected } inverted = {props.inverted} />
      { props.children }
    </SVGRadioAriaLabel>
  );
};

export type RadioSVGBaseProps = React.PropsWithChildren<{
  className?: string,
  isFocused: boolean,
  isSelected: boolean,
  inverted?: boolean,
}>;

export const RadioSVGBase = (props : RadioSVGBaseProps) => {
  return (
    <SVGRadioAriaSVG { ...props } className = { props.className } aria-hidden = 'true'>
      <SVGRadioAriaCircle/>
      {props.isFocused && (
        <SVGRadioAriaFocusRing/>
      )}
    </SVGRadioAriaSVG>
  );
};

export const SVGRadioAriaLabel = styled.label<{
  isFocused?: boolean,
  isSelected?: boolean,
  inverted?: boolean,
}>`
  cursor: pointer;
`;
export const SVGRadioAriaInput = styled.input``;
export const SVGRadioAriaCircle = styled.circle``;
export const SVGRadioAriaFocusRing = styled.circle``;
export const SVGRadioAriaSVG = styled.svg<RadioSVGBaseProps>`
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 4px;

  ${SVGRadioAriaCircle} {
    cx: 12px;
    cy: 12px;
    r: 8px;
    stroke-width: 2px;
    fill: ${(props) => props.isSelected ? 'white' : 'none' };
    stroke: ${(props) => props.isSelected ? 'white' : 'grey' };
  }

  ${SVGRadioAriaFocusRing} {
    cx: 12px;
    cy: 12px;
    r: 11px;
    fill: none;
    stroke: orange;
    stroke-width: 2px;
  }
`;
