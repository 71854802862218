export const getSlug = (props) => {
  const params = [
    props.match.params.p0,
    props.match.params.p1,
    props.match.params.p2,
    props.match.params.p3,
    props.match.params.p4,
    props.match.params.p5,
  ].filter((param) => param);
  const slug = params.join('-');
  return slug;
};
