import styled from 'styled-components';

import {
  SVGRadioAria,
  SVGRadioAriaCircle,
  RadioSVGBase,
} from '../BaseComponents/Inputs/SVGRadioAria';

export {
  SVGRadioAriaCircle as SVGRadioCircle,
  SVGRadioAriaFocusRing as SVGRadioFocusRing,
  SVGRadioAriaInput as SVGRadioInput,
  SVGRadioAriaLabel as SVGRadioLabel,
} from '../BaseComponents/Inputs/SVGRadioAria';


// These could probably be named better.   This is the component that's
// actually responsible for rendering the SVG for the radio button
const RadioSVG = styled(RadioSVGBase)`
  ${SVGRadioAriaCircle} {
    ${(props) => (props.inverted) && `
      fill: ${props.isSelected ? props.theme.black[70] : ''};
      stroke: ${props.isSelected ? props.theme.black[40] : props.theme.black[70] };
    `}
  }
`;

// This is the overall encompassing radio button component
export const SVGRadio = styled(SVGRadioAria).attrs({
  RadioSVG: RadioSVG,
})`
  display: flex;
  align-items: center;
`;
