import styled from 'styled-components';

export const BannerSection = styled.aside`
  background: ${(props) => props.theme.black[95]};
  padding: 50px 0;
`;

export const BannerContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  display: grid;

  > * {
    text-align: center;
    color: ${(props) => props.theme.black[20]};

    h1, h2, h3, h4, h5 {
      margin: 0;
      color: ${(props) => props.theme.gold[50]};
    }

    > strong {
      color: ${(props) => props.theme.gold[50]};
      font-family: ${(props) => props.theme.serif};
      font-size: 3rem;
      font-variant: small-caps;
      line-height: 1;
    }

    p {
      margin: 0px 0 10px;
      font-size: 1.6rem;
    }
  }
  width: 100%;
  
  @media screen and (min-width: 460px) {
    grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
    > * {
      padding: 30px 50px;
    }
  }
`;
