import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';
import ConsignmentLeverAction from 'images/consignment/HenryLeverActionRifle.jpg';

export const AsyncConsignmentPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/ConsignmentPage/ConsignmentPage'
    componentImport={async () => (await import('src/pages/ConsignmentPage/ConsignmentPage'))}
    head={() => async () => {
      return {
        title: 'Gun Consignment',
        description: 'Rock Island Auction has established itself as the #1 firearms '
        + 'auction house in the world for collectible firearms, edged weapons, & military artifacts.',
        image: ConsignmentLeverAction,
        imageType: 'image/jpeg',
      };
    }}
    {...props}
  />
);
