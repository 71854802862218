import styled from 'styled-components';

import { Button } from '../index';

export const ShareBarContainer = styled.div`
  margin: 30px 0;

  @media print {
    display: none;
  }
`;

export const ShareBarText = styled.p`
  font-size: 1.6rem;
  margin-bottom: 5px;
`;

export const ShareBarButton = styled(Button)`
  font-size: 4rem;
  color: ${(props) => props.theme.black[10]};
  padding: 4px;
  margin: 4px;
  transition: color 0.5s;

  &:hover {
    color: ${(props) => props.theme.gold[50]};
  }
`;
