import React, { useState } from 'react';

import { useFetch } from 'src/lib/FetchContext';
import {
  IWishlistItem,
  IWishlist,
  WishlistDetails,
} from 'src/lib/WishlistTypes.i';


export interface IWishlistActionWishlist {
  WishListID: number;
  WishListName: string;
  items: IWishlistItem[];
}

export interface IWishlistActionContext {
  getAllUserWishlists(): Promise<IWishlist[]>;
  getWishlistShareId(wishlistId: number): Promise<string>;
  getWishlistIdFromShareId(shareId: string): Promise<number>;
  importWishlist(shareId: string): Promise<void>;
  getWishlistDetails(wishlistId: number): Promise<WishlistDetails>;
  editWishlistName(wishlistId: number, wishlistName: string): Promise<void>;
  getWishlistItems(wishlistId: number): Promise<IWishlistItem[]>;
  deleteWishlist(wishlistId: number): Promise<void>;
  addWishlistItem(
    wlid: number,
    auctionitemid: number | string,
    notes?: string
  ): Promise<void>;
  saveWishlistItemNotes(wlitemid: number, note: string): Promise<void>;
  deleteWishlistItem(
    wlitemid: number,
    wlid: number,
    auctionitemid: number | string
  ): Promise<void>;
  createNewWishlist(
    WLname: string,
    auctionID: number,
    itemid?: string | number,
    notes?: string
  ): Promise<number>;
  setAuction(auctionId: number): void;
  matchItem(itemId: number): IWishlistActionWishlist[];
  currentWishlists: IWishlistActionWishlist[];
}

export const WishlistActionContext = React.createContext<Partial<IWishlistActionContext>>({});
export const WishlistActionContextProvider = ({ children }: {children?: React.ReactNode}) => {
  const { fetch } = useFetch();
  const [currentAuctionId, setCurrentAuctionId] = useState<number>();
  const [currentWishlists, setCurrentWishlists] = useState<IWishlistActionWishlist[]>([]);

  function setAuction(auctionId: number) {
    if(currentAuctionId !== auctionId)
    {
      setCurrentAuctionId(auctionId);
      reloadWishlists(auctionId);
    }
  }

  async function reloadWishlists(auctionId: number) {
    const resp = await fetch(`/api/account/wishlist/auctionLists/${auctionId}`);

    if(resp.status == 200)
    {
      const lists = await resp.json();
      const listsWithItems = (await Promise.all(
        lists.map((list) => fetch(`/api/account/wishlist/listItems/${list.WishListID}`).then((r) =>{
          if(r.status != 200)
          {
            throw new Error(`Fetch Error: Status ${r.status}`);
          }

          return r.json();
        }))
      )).map((items, index) => Object.assign(lists[index], { items }));

      setCurrentWishlists(listsWithItems);
    }
  }

  function matchItem(itemId: number): IWishlistActionWishlist[] {
    const matches = currentWishlists.filter((wishlist) => wishlist.items.some((item) => item.AuctionItemID === itemId));

    return matches;
  }

  async function getAllUserWishlists(): Promise<IWishlist[]> {
    // This api endpoint uses session verification to get the userid
    const resp = await fetch('/api/account/wishlist/allLists');

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }

    return resp.json();
  }

  async function getWishlistShareId(wishlistId: number): Promise<string> {
    const resp = await fetch(`/api/account/wishlist/getSharedId/${wishlistId}`);

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }

    return (await resp.json())[0]?.UID;
  }

  async function getWishlistIdFromShareId(shareId: string): Promise<number> {
    const resp = await fetch(`/api/account/wishlist/getWlidFromShared/${shareId}`);

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }

    return (await resp.json())[0]?.WishListID;
  }

  async function importWishlist(shareId: string): Promise<{
    nwl: number,
    WishListName: string,
  }> {
    const resp = await fetch(`/api/account/wishlist/importList/${shareId}`);
    const respJson = await resp.json();

    if(respJson.success == false)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }

    return respJson.data;
  }

  async function getWishlistDetails(wishlistId: number): Promise<WishlistDetails> {
    const resp = await fetch(`/api/account/wishlist/listNameById/${wishlistId}`);

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }

    return (await resp.json())[0];
  }

  async function editWishlistName(wishlistId: number, wishlistName: string): Promise<void> {
    const resp = await fetch(`/api/account/wishlist/editList/${wishlistId}`, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ wlname: wishlistName.trim() }),
    });

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }
  }

  async function deleteWishlist(wishlistId: number): Promise<void> {
    const resp = await fetch(`/api/account/wishlist/deleteList/${wishlistId}`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }
  }

  async function getWishlistItems(wishlistId: number): Promise<IWishlistItem[]> {
    const resp = await fetch(`/api/account/wishlist/listItems/${wishlistId}`);

    if(resp.status != 200)
    {
      throw new Error(`Fetch Error: Status ${resp.status}`);
    }

    return resp.json();
  }

  async function addWishlistItem(
    wlid: number,
    auctionitemid: number | string,
    notes?: string
  ) {
    const addItemReq = await fetch(
      `/api/account/wishlist/addItem/${wlid}/${auctionitemid}`,
      {
        credentials: 'include',
        method: 'POST',
      }
    );
    const addItem = await addItemReq.json();
    const newWishlistItem = addItem[0].wli;
    if(notes) {
      saveWishlistItemNotes(newWishlistItem, notes);
    }

    if(currentAuctionId)
    {
      reloadWishlists(currentAuctionId);
    }
  }

  async function saveWishlistItemNotes(wlitemid: number, note: string) {
    await fetch(`/api/account/wishlist/saveNotes/${wlitemid}`, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        note: note,
      }),
    });
  }

  async function deleteWishlistItem(
    wlitemid: number,
    wlid: number,
    auctionitemid: number | string
  ) {
    await fetch(`/api/account/wishlist/deleteItem/${wlitemid}/${wlid}/${auctionitemid}`, {
      credentials: 'include',
      method: 'PUT',
    });

    if(currentAuctionId)
    {
      reloadWishlists(currentAuctionId);
    }
  }

  async function createNewWishlist(
    WLname: string,
    auctionID: number,
    itemid?: string | number,
    notes?: string
  ) {
    const addWLReq = await fetch(`/api/account/wishlist/createList/${auctionID}`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        wlname: WLname,
      }),
    });
    const addWL = await addWLReq.json();
    const newList: number = addWL[0].wlid;
    if(itemid) {
      addWishlistItem(newList, itemid, notes);
    }
    return newList;
  }

  return (
    <WishlistActionContext.Provider value={{
      getAllUserWishlists,
      getWishlistShareId,
      getWishlistIdFromShareId,
      importWishlist,
      getWishlistDetails,
      editWishlistName,
      deleteWishlist,
      getWishlistItems,
      addWishlistItem,
      saveWishlistItemNotes,
      createNewWishlist,
      deleteWishlistItem,
      setAuction,
      matchItem,
      currentWishlists,
    }}>
      {children}
    </WishlistActionContext.Provider>
  );
};
