import styled from 'styled-components';

/* Fake youtube play button css taken from: https://codepen.io/injms/pen/gemvi */
export const PlayButton = styled.div<{load?: boolean}>`
  background: rgba(0, 0, 0, 0.75);
  border-radius: 50% / 15%;
  color: #FFFFFF;
  font-size: 1.5em; /* change this to change size */
  height: 3em;
  margin: 20px auto;
  padding: 0;
  position: absolute;
  text-align: center;
  text-indent: 0.1em;
  transition: all 150ms ease-out;
  width: 4em;
  cursor: pointer;

  &::before { 
    border-radius: 5% / 50%;
    bottom: 9%;
    content: "";
    left: -5%;
    position: absolute;
    right: -5%;
    top: 9%;
  }

  &::after {
    border-style: solid;
    border-width: 1em 0 1em 1.732em;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
    content: ' ';
    font-size: 0.75em;
    height: 0;
    margin: -1em 0 0 -0.75em;
    top: 50%;
    position: absolute;
    width: 0;
  }
`;

/* container for placeholder */
export const YoutubeLazyLoaderPlaceholder = styled.div<{load?: boolean}>`
  position: relative;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  img,object {
    width: 100%;
    max-height: 100%;
  }
`;


/* container to allow flexbox centering of play button */
export const PlayButtonOverlay = styled.div<{load?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
  cursor: pointer;
  
  ${ ({ load }) => !load
    && `&:hover ${PlayButton} {
      background-color: #E14D42;
    }`
  || `
    ${PlayButton} { background-color: rgba(100, 100, 100, 0.6); }
  `}
`;

export const YoutubeVideoContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
