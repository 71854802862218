import React, { useState, createContext, useEffect } from 'react';
import { v4 } from 'uuid';

export type ModalScrollContextData = {
  updateOpenState: (isOpen: boolean, modalId?: string) => string,
  destroyModal: (modalId: string) => void,
};
export const ModalScrollContext = createContext<ModalScrollContextData>({});

export const ModalScrollContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const [modalStates, setModalStates] = useState<Record<string, boolean>>({});

  const updateOpenState = (isOpen: boolean, modalId?: string): string => {
    const theModalId = modalId || v4();

    setModalStates((prev) => Object.assign({}, prev, { [theModalId]: isOpen }));

    return theModalId;
  };

  const destroyModal = (modalId: string): void => {
    setModalStates((prev) => {
      const next = Object.assign({}, prev);
      if(next[modalId] !== undefined) {
        delete next[modalId];
      }

      return next;
    });
  };

  useEffect(() => {
    const numOpenModals = Object.keys(modalStates).reduce((pv, cv) => {
      if(modalStates[cv] == true) {
        pv++;
      }

      return pv;
    }, 0);


    // .body-no-scroll lives in design-system/src/global.css
    if(numOpenModals > 0) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
  }, [modalStates]);

  return (
    <ModalScrollContext.Provider
      value = {{
        updateOpenState,
        destroyModal,
      }}
    >
      {children}
    </ModalScrollContext.Provider>
  );
};
