import React, { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';

import { SocketHookManager } from 'src/lib/SocketHookManager';

interface ISocketContext {
  socket: Socket;
  socketHookManager: SocketHookManager;
  sendEvent: (event: string, ...args: any[]) => void,
}

export const SocketContext = React.createContext<Partial<ISocketContext>>({});

export const SocketContextConsumer = SocketContext.Consumer;
export const SocketContextProvider = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  const [socket, setSocket] = React.useState<null | Socket>(null);
  const [socketHookManager, setSocketHookManager] = useState<SocketHookManager>();
  useEffect(() => {
    console.log(`attempting connecting to socket at ${window.SOCKET_HOST}`);
    const socket = io(`${window.SOCKET_HOST}`, {
      path: '/api/socket.io',
      transports: ['websocket', 'polling'],
      secure: true,
      withCredentials: true,
    });
    socket.on('connect_error', (err) => {
      // console.log(err.message);
      // console.log(err.description);
      // console.log(err.context);
    });
    socket.onAny((event, ...args) => {
      // console.log(event, ...args);
    });
    setSocket(socket);
    setSocketHookManager(new SocketHookManager(socket));
  }, []);

  const sendEvent = (event: string, ...args: any[]): void => {
    if(socketHookManager.socket) {
      socketHookManager.socket.emit(event, ...args);
    }
  }
  return (
    <SocketContext.Provider value={{
      socket,
      socketHookManager,
      sendEvent,
    }}>
      {children}
    </SocketContext.Provider>
  );
};
