import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { App } from './App';
import { createBrowserContext, PrefetchProfilingContextProvider } from 'src/lib/usePrefetch';
import { FetchContext } from 'src/lib/FetchContext';
import { SsrInitDataContext } from 'src/lib/SsrInitDataContext';
import { importPolyfills } from 'src/lib/polyfills/polyfills';
import { HeadContextProvider } from 'src/lib/HeadContext';

import * as Sentry from '@sentry/react';
import { SocketContextProvider } from 'src/lib/SocketContext';

if(SENTRY_ENABLED) {
  Sentry.init({
    ignoreErrors: [
      'Load failed', // WEBSITE-FRONT-END-5
      'Suspense boundary', // WEBSITE-FRONT-END-4
      'Preflight response is not successful', // WEBSITE-FRONT-END-7
      'about:blank', // WEBSITE-FRONT-END-16
      'Importing a module script failed', // WEBSITE-FRONT-END-D
      'AbortError', // WEBSITE-FRONT-END-9
      'NetworkError when attempting to fetch resource', // WEBSITE-FRONT-END-W
      'doZoom', // WEBSITE-FRONT-END-N
      'loadableErrorRefreshTimeout', // WEBSITE-FRONT-END-Y
    ],
    dsn: `${SENTRY_IO_DSN}`,
    environment: `${SENTRY_ENV}`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'rockislandauction.com'],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: SENTRY_PERFORMANCE_SAMPLE_RATE, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: SENTRY_REPLAY_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(_event, _hint) {
      // TODO if the above filters are not working look at handling things in here.
    },
  });
}

window.reportClientError = (message) => {
  const code = Math.round(Math.random() * 10000);
  window.fetch('/api/errorlog/submit', {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message: message.message || message,
      code,
      stack: message.stack,
    }),
  });
  return code;
};
(async () => {
  await importPolyfills();
  const BrowserDataContext = createBrowserContext();

  const AppJSX = (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <PrefetchProfilingContextProvider mark={() => {}}>
      <BrowserDataContext>
        <FetchContext.Provider
          value={{ fetch: async (input, init) => {
            if(!init) {
              init = {};
            }
            init = Object.assign({}, init, {
              credentials: 'include',
            });
            return await fetch(input, init);
          } }}
        >
          <SsrInitDataContext.Provider
            value={{
              ssr: false,
              serverCompactMode: undefined,
              skipMaintenance: undefined,
              hasLoginToken: undefined,
              location: undefined,
            }}
          >
            <HeadContextProvider value={{ isHead: false }}>
              <SocketContextProvider>
                <App/>
              </SocketContextProvider>
            </HeadContextProvider>
          </SsrInitDataContext.Provider>
        </FetchContext.Provider>
      </BrowserDataContext>
    </PrefetchProfilingContextProvider>
  );
  if(window.serverSideRenderingError) {
    console.log('ssr error');
    ReactDOMClient.createRoot(document.getElementById('react-root')).render(AppJSX);
  } else {
    ReactDOMClient.hydrateRoot(document.getElementById('react-root'), AppJSX);
  }
})();
