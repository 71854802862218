import { Socket } from 'socket.io-client';

export class SocketHookManager {
  public eventListeners: {eventName: string, uuid: string, eventHandler: (...params: any) => void}[] = [];
  public socket: Socket;

  public constructor(socket: Socket) {
    this.socket = socket;
  }

  public addEventListener(eventName: string, uuid: string, eventHandler: (...params: any) => void): void {
    this.eventListeners.push({ eventName, eventHandler, uuid });
    this.socket.on(eventName, eventHandler);
  }

  public removeEventListener(uuid: string): void {
    this.eventListeners = this.eventListeners.filter((x): boolean => {
      if(x.uuid === uuid) {
        this.socket.off(x.eventName, x.eventHandler);
        return false;
      }
      return true;
    });
  }

  public isConnected(): boolean {
    return this?.socket?.connected === true;
  }
}
