import React, { createContext, useEffect, useState } from 'react';

export interface ISocialSharingContext {
  printButtonUsed: boolean;
  setPrintButtonUsed: (used: boolean) => void;
}

export const SocialSharingContext = createContext<Partial<ISocialSharingContext>>({});

export const SocialSharingProvider = ({ children }) => {
  const [printButtonUsed, setPrintButtonUsed] = useState<boolean>(false);
  const loadScript = () => (function(d) {
    const f = d.getElementsByTagName('SCRIPT')[0]; const p = d.createElement('SCRIPT') as HTMLScriptElement;
    p.type = 'text/javascript';
    p.defer = true;
    p.src = '//assets.pinterest.com/js/pinit.js';
    f.parentNode.insertBefore(p, f);
  }(document));

  useEffect(() => {
    const pinterestScript = document.querySelector('script[src*="assets.pinterest.com"]');
    if(!pinterestScript) loadScript();
  }, []);

  return (
    <SocialSharingContext.Provider value={{
      printButtonUsed,
      setPrintButtonUsed,
    }}>
      {children}
    </SocialSharingContext.Provider>
  );
};
