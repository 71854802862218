import styled from 'styled-components';

import { RadioAria } from '../BaseComponents/Inputs/RadioAria';
import { theme } from '../../theme';

export const Radio = styled(RadioAria)`
  font-family: ${theme.sansSerif};
  display: block;
  align-self: flex-start;
  padding: 2px;
  &,&>input {
    cursor: pointer;
  }
  &>input {
    margin-right: 8px;
  }
`;
