/* This button factory encapsulates the logic to inject react-aria control
 * into the button props.
 *
 * Currently it also handles a rock and hard place
 * situation where they want us to use onPress instead of onClick, but onPress
 * does not expose evt.preventDefault(), which is necessary to stop clicks from
 * bubbling up to elements below fixed elements such as modals.  One solution is
 * to use onClick, but react-aria doesn't handle keyboard events with onClick
 * so we lose the ability to capture Enter and Space as button engagement.
 * This is solved by handeling the keyboard logic ourself and passing our
 * custom onKeyDown handler to useButton()
 *
 * Arguments:
 *   elementType: required by useButton, this is the base tag for the custom button
 *     for html elements use a string: 'div'
 *     for react components pass identifier: Link
 *   wrappedComponent: React Component
 *
 * Returns
 *   A react component which will have useButton result loaded into its props as
 *   'ariaButtonProps' as well as a ref passed through as props.buttonRef
 *
 * Example Use:
 *
 * CustomButton = AriaWrappedButtonFactory('div', (props) =>
 *   <div
 *     { ...ariaButtonProps }
 *     className = { props.className }
 *     ref = { props.buttonRef }
 *   >
 *     {props.children}
 *   </div>
 * );
 *
 * The result will be a React Component named CustomButton with react-aria useButton() behavior
 */

import React, { useRef, FC, KeyboardEvent, SyntheticEvent } from 'react';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import { useForceFocus } from '../../../Hooks/useForceFocus';

const needsSafari12OrHuaweiP30Fix = typeof navigator !== 'undefined' && (
  /Version\/12\..+Safari/gm.test(navigator.userAgent)
  || /\(Linux; Android.*?MAR-LX3A\)/.test(navigator.userAgent)
);

export type AriaWrappedButtonProps<T> = React.PropsWithChildren<React.HTMLAttributes<HTMLElement> & T & {
  ref: React.RefObject<HTMLButtonElement>,
}>;

type ButtonClosureProps = React.PropsWithChildren<
AriaButtonProps & {
  active?: boolean,
  blurOnClick?: boolean,
}>

export const AriaWrappedButtonFactory = <T extends Object>(
  elementType : React.JSXElementConstructor<any> | React.ElementType<any>,
  wrappedComponent : FC<AriaWrappedButtonProps<T>>
) => {
  const buttonClosure = (props : T & ButtonClosureProps) => {
    const ref = useRef<HTMLButtonElement>();
    const kbPassthrough = (evt: KeyboardEvent<any>) => {
      handleKeyDown(evt);
      if(props.onKeyDown)
      {
        props.onKeyDown(evt as any);
      }
    };

    useForceFocus(ref, props.forceFocus);

    const { buttonProps } = useButton({ ...props, onKeyDown: kbPassthrough, elementType: elementType }, ref);
    const {
      isDisabled,
      excludeFromTabOrder,
      active,
      blurOnClick,
      forceFocus,
      ...cleanProps
    } = props;

    if(props.blurOnClick)
    {
      const origOnClick = buttonProps.onClick;
      buttonProps.onClick = (evt: SyntheticEvent) => {
        if(ref.current)
        {
          ref.current.blur();
        }
        if(origOnClick) {
          origOnClick(evt);
        }
      };
    }

    const handleKeyDown = (evt: KeyboardEvent<any>) => {
      if((evt.key === 'Enter') || evt.key === (' '))
      {
        buttonProps.onClick && buttonProps.onClick(evt as any);
      }
    };

    if(needsSafari12OrHuaweiP30Fix) {
      buttonProps.onTouchEnd = cleanProps.onPress;
    }
    const finalProps = {
      ...cleanProps,
      ...buttonProps,
      ref: ref,
    } as any;

    delete finalProps.onPress;

    return wrappedComponent(finalProps); // TODO: Remove as anys
  };

  return buttonClosure;
};
