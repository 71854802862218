import React, { useEffect, useRef, useState } from 'react';

interface IScrollContext {
  headerRef: React.MutableRefObject<HTMLElement>;
  headerPadding: number;
}

export const ScrollContext = React.createContext<Partial<IScrollContext>>({});
export const ScrollContextProvider = ({ children }) => {
  const headerRef = useRef<HTMLElement>();
  const [headerPadding, setHeaderPadding] = useState(0);
  useEffect(() => {
    const padding = headerRef.current?.getBoundingClientRect().height;
    setHeaderPadding(padding);
  }, [headerRef.current]);
  return (
    <ScrollContext.Provider value={{
      headerRef,
      headerPadding,
    }}>
      {children}
    </ScrollContext.Provider>
  );
};
