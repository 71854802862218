import styled from 'styled-components';
import { Button } from '../../index';

import { shouldForwardPropFilter } from '@riac/core';
import { IPanelsCollapseContent } from './Collapse.i';

export const Container = styled.article`
  margin: 20px;
  background: rgba(196,196,196, 0.1);
  text-align:center;
  z-index: 2;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Content = styled.div`
  padding: 50px 20px 30px;

  ${(props) => props.theme.desktop} {
    padding: 50px 15px 30px;
  }
  ${(props) => props.theme.widescreen} {
    padding: 50px 25px 30px;
  }
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.serif};
  color: ${(props) => props.theme.black[0]};
  text-transform: uppercase;
  font-size: 3rem;
  margin-bottom: 40px;
`;

export const Copy = styled.div<IPanelsCollapseContent>`
  height: 0;
  overflow:hidden;
  transition: all 1s;

  ${(props) => props.show && `
    height: ${props.ht}px;
  `}
  
  p {
    padding-top: 30px;
    margin: 0;
    transition: all 2s;
  }
`;

export const Toggle = styled(Button).withConfig({
  shouldForwardProp: shouldForwardPropFilter(['show']),
})<IPanelsCollapseContent>`
  height: 50px;
  width: 50px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.gold[50]};
  box-shadow: none;
  border: none;
  border-radius: 50%;
  transform: rotate(0deg);
  transition: all 1s;
  
  :before {
    display: none;
  }

  svg {
    width: 2.4rem;
    fill: ${(props) => props.theme.black[100]};
  }

  ${(props) => props.show && `
    transform: rotate(225deg);
  `}
`;

