import React, { useContext } from 'react';

import { SolidButton } from '@riac/design-system';
import { CallToAction } from '@riac/design-system';
import { Hero } from '@riac/design-system';
import { HeadContext } from 'src/lib/HeadContext';


export const Page404 = (): JSX.Element => {
  const head = useContext(HeadContext);

  head.is404Error = true;

  return head.isHead != true
    ? (
      <React.Fragment>
        <Hero
          title={'We\'re sorry - we can\'t find the page you\'re looking for.'}
          button={<SolidButton to = '/'>Return Home</SolidButton>}
          center
          lazyLoadImage={false}
        >
          We might have removed the page when we redesigned our website,
          the link you clicked might be old and does not work anymore,
          or you might have accidentally typed the wrong URL in the address bar.
          We can return you back to the Rock Island Auction home page.
        </Hero>

        <CallToAction
          title={'Didn\'t find what you were looking for?'}
          content={`Rock Island Auction Company is the global leader in firearms auctions,
          currently holding multiple records for our top-selling lots.
          View our latest catalog to find what you might be searching for.`}
          button={<SolidButton to = '/catalog'>View Catalog</SolidButton>}
        />
      </React.Fragment>
    )
    : <></>;
};
