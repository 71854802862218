export const formatCurrency = (num: number, options?: Intl.NumberFormatOptions): string => {
  return new Intl.NumberFormat('en-US', Object.assign({ style: 'currency', currency: 'USD' }, options)).format(num);
};

export const sanitizeWithDashes = (txt: string): string => {
  if(txt !== undefined) {
    return txt
      .trim()
      .toLowerCase()
      .replace(/[^\s\w]/g, '')
      .replace(/\s\s/g, ' ')
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  } else {
    return '';
  }
};
