import * as React from 'react';
import { LazyLoadObserver } from '../LazyLoad/LazyLoadObserver';


export const IntersectionObserverContext = React.createContext(LazyLoadObserver);

export const IntersectionObserverContextProvider = ({ children }: any): JSX.Element => {
  return (
    <IntersectionObserverContext.Provider
      value = {LazyLoadObserver}
    >
      {children}
    </IntersectionObserverContext.Provider>
  );
};
