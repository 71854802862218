import styled from 'styled-components';
import { theme } from '../theme';

export const NotificationBannerContainer = styled.div`
  border: 5px solid ${theme.gold[50]};
  background-color: ${theme.gold[50]};
  color: black;
  max-width: 680px;
  margin-bottom: 30px;
`;

export const NotificationBannerHeaderContainer = styled.div`
  padding: 2px 20px 5px;
  color: black;
  font-weight: 600;
`;

export const NotificationBannerContentContainer = styled.div`
  padding: 20px;
  color: ${theme.black[10]};
  background-color: ${theme.black[95]};
`;
