import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncAuctionsPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/AuctionsPage/AuctionsPage'
    componentImport={async () => (await import('src/pages/AuctionsPage/AuctionsPage'))}
    head={() => async () => {
      return {
        title: 'Online Antique Gun Auctions',
        description: 'Rock Island Auction holds over 12 gun auctions per year. We '
        + 'buy & sell collectible firearms for serious gun collectors, including antique '
        + 'firearms & military arms. Click now to start selling your guns & investing in '
        + 'collectables today.',
      };
    }}
    {...props}
  />
);
