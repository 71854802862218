import React from 'react';
import styled from 'styled-components';

import { ManagedModal, ManagedModalProps, ManagedModalContentArea } from './ManagedModal';
import { BoxButton } from '../Buttons/BoxButton';

export const ConfirmModalTextContainer = styled(ManagedModalContentArea)`
  padding: 2em 0;
`;

export const ConfirmModalButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  
  justify-content: flex-start;
  gap: 1em;
`;

type ConfirmModalProps = ManagedModalProps & {
  confirmButtonText: string,
  onConfirm: () => void,
  rejectButtonText: string,
  onReject: () => void,
};

export const ConfirmModal = (props: ConfirmModalProps): JSX.Element => {
  return (
    <ManagedModal
      className = {props.className}
      isOpen = {props.isOpen}
      title = {props.title}
      isDismissable = {props.isDismissable}
      onClose = {props.onReject}
      showCloseButton = {props.showCloseButton}
    >
      <ConfirmModalTextContainer>
        {props.children}
      </ConfirmModalTextContainer>
      <ConfirmModalButtonContainer>
        <BoxButton onPress = {props.onConfirm}>
          {props.confirmButtonText || 'Yes'}
        </BoxButton>
        <BoxButton onPress = {props.onReject}>
          {props.rejectButtonText || 'No'}
        </BoxButton>
      </ConfirmModalButtonContainer>
    </ManagedModal>
  );
};
