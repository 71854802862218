import React, { useRef, useContext } from 'react';
import styled from 'styled-components';
import { useRadio } from '@react-aria/radio';
import { useLabel } from '@react-aria/label';

import { RadioContext } from './RadioGroupAria';
import { AriaRadioProps } from '@react-types/radio';

export type RadioAriaProps = React.PropsWithChildren<{
  RadioSVG?: React.ComponentType,
  className?: string,
  inverted?: boolean,
}> & AriaRadioProps;

export const RadioAria = (props: RadioAriaProps) => {
  const state = useContext(RadioContext);
  if(!state) {
    throw new Error('RadioAria must be used within a RadioContext');
  }
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { labelProps, fieldProps } = useLabel(props);

  return (
    <RadioAriaLabel { ...labelProps } className={props.className}>
      <RadioAriaInput { ...inputProps } { ...fieldProps } ref = { ref } />
      { props.children }
    </RadioAriaLabel>
  );
};

export const RadioAriaLabel = styled.label``;
export const RadioAriaInput = styled.input``;
