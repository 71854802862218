import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncCreateAccountPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/CreateAccountPage/CreateAccountPage'
    componentImport={async () => (await import('src/pages/CreateAccountPage/CreateAccountPage'))}
    head={() => async () => {
      return {
        title: 'Create Account',
        description: 'Create Your Account With Rock Island Auction Company to Start'
        + 'Bidding or Consigning Antique and Modern Firearms',
      };
    }}
    {...props}
  />
);
