import styled from 'styled-components';
import { Container } from '../index';
import { IShowcase, IShowcaseContainer, IShowcaseContent, IShowcaseImage } from './Showcase.i';

export const ShowcaseSection = styled.section<IShowcase>`
  background: ${(props) => props.theme.black[100]};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 30px;

  @media print {
    display: block;
    padding: 30px 0 0;
    background: white;
  }

  ${(props) => props.theme.desktop} {
    padding: ${(props) => props.small ? '75px 0' : '150px 0'};
    display: block;
  }
  ${(props) => props.theme.widescreen} {
    padding: ${(props) => props.small ? '125px 0' : '250px 0'};
  }
`;

export const ShowcaseImage = styled.div<IShowcaseImage>`
  order: 1;
  height: 400px;
  width: 100%;
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  z-index: 0;

  @media print {
    display: none;
  }

  ${(props) => props.theme.desktop} {
    position: absolute;
    top: 0;
    bottom: 0;
    ${(props) => props.align === 'left' ? 'right' : 'left'}: 0;
    height: auto;
    width: 50%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to top, ${(props) => props.theme.black[100]}, transparent);

    ${(props) => props.theme.desktop} {
      background: linear-gradient(
        to ${(props) => props.align === 'left' ? 'right' : 'left'},
        ${(props) => props.theme.black[100]},
        transparent
      );
    }
  }
`;

export const ShowcaseContainer = styled(Container)<IShowcaseContainer>`
  width: 100%;
  order: 2;
  position: relative;
  top: -125px;
  flex-direction: column;
  align-items: ${(props) => props.align === 'left' ? 'flex-start' : 'flex-end'};
  z-index: 1;

  @media print {
    top: 0;
  }

  ${(props) => props.theme.desktop} {
    top: 0;
  }

  ${(props) => props.line && `
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      ${props.align === 'left' ? 'right' : 'left'}: 25%;
      width: calc(75% - 15px);
      height: 1px;
      background: ${props.theme.black[0]};
    }
  `}
`;

export const ShowcaseTitle = styled.h2<IShowcaseContent>`
  text-align: ${(props) => props.align};
  max-width: 375px;

  @media print {
    max-width: none;
    color: ${(props) => props.theme.black[100]};
  }

  ${(props) => props.theme.desktop} {
    font-size: 60px;
    max-width: 600px;
  }
`;

export const ShowcaseSubtitle = styled.div<IShowcaseContent>`
  margin: 0 0 15px;
  text-align: ${(props) => props.align};
  max-width: 600px;

  ${(props) => !props.plain && 'font-style: italic;'}

  ${(props) => props.theme.desktop} {
    margin: 0 0 30px;
  }

  @media print {
    max-width: none;
    color: ${(props) => props.theme.black[100]};
  }
`;
export const ShowcaseImageContentContainer = styled.div<IShowcaseImage>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;

  
  order: 1;
  height: 400px;
  width: 100%;
  position: relative;
  z-index: 1;

  @media print {
    display: none;
  }

  ${(props) => props.theme.desktop} {
    position: absolute;
    top: 0;
    bottom: 0;
    ${(props) => props.align === 'left' ? 'right' : 'left'}: 0;
    height: auto;
    width: 50%;
  }
`;
