import styled from 'styled-components';

import { TextButton } from '@riac/design-system';

export const Menu = styled.nav`
  display: none;
  flex 0 0 auto;
  
  ${(props) => props.theme.desktop} {
    display: flex; 
    text-align: center;

    gap: 24px;
  }

  ${TextButton} {
    color: ${(props) => props.theme.black[0]};
    font-family: ${(props) => props.theme.serif};
    font-size: 2rem;

    &:hover {
      color: ${(props) => props.theme.gold[50]};
    }
  }

  ${(props) => props.theme.extrawide} {
    gap: 40px;
  }
`;
