import styled from 'styled-components';

import {
  AddonsInputAria,
  AddonsInputAriaControlWrapper,
  AddonsInputAriaLabel,
  AddonsInputAriaControl,
  AddonsInputAriaRequiredSymbol,
  AddonsInputAriaContainer,
} from '../BaseComponents/Inputs/AddonsInputAria';
import { theme } from '../../theme';

export {
  AddonsInputAriaControlWrapper as InputControlWrapper,
  AddonsInputAriaLabel as InputLabel,
  AddonsInputAriaControl as InputControl,
  AddonsInputAriaRequiredSymbol as InputRequiredSymbol,
  AddonsInputAriaContainer as InputContainer,
};

export const Input = styled(AddonsInputAria)`
  width: 100%;
  margin: 10px 0;

  ${(props) => (props.disabled == true) && `
    color: ${theme.black[60]};
    ${AddonsInputAriaControl} {
      border: 1px solid ${theme.black[90]};
      background-color: ${theme.black[100]};
    }
  `}

  ${(props) => (props.inverted == true) && `
    color: ${props.theme.black[80]};
  `}

  ${(props) => (props.inline == true) && `
    display: flex;
    flex-flow: row nowrap;

    align-items: baseline;
  `}
  
  ${AddonsInputAriaLabel} {
    margin: 0;
    margin-top: 10px;
    padding: 0;

    ${(props) => (props.inline == true) && `
      flex: 0 0 auto;
      padding-right: 1em;
    `}
  }

  ${AddonsInputAriaControlWrapper} {
    ${(props) => (props.inline == true) && 'flex: 1 1 auto' }
  }

  ${AddonsInputAriaControl} {
    &:not([type='submit']) { 
      flex: 1;
      margin-bottom: 0;
      ${(props) => (props.inverted == true) && `
        background: ${props.theme.black[10]};
        color: ${props.theme.black[90]};
      `}
    }
  }

  ${AddonsInputAriaRequiredSymbol} {
    font-weight: 800;
    line-height: 0;
    color: rgba(255, 255, 255, 0.95);
    padding-left: 0.25em;
  }
`;
