import styled from 'styled-components';

import { Button } from '../../index';

import { IButton } from './Arrows.i';

export const ArrowButton = styled(Button)<IButton>`
  z-index: 9999;
  ${(props) => props.align}: 2px;
  height: 34px;
  width: 28px;

  align-items: center;

  &:hover {
    color: ${(props) => props.theme.black[0]};
  }

  ${(props) => props.theme.widescreen} {
    ${(props) => props.align}: 15px;
  }

  svg {
    color: rgba(255,255,255,0.5);
    height: 20px;
    width: 20px;
    transition: color 0.3s;
  }

  &:hover svg {
    color: var(--black-0);
  }
`;
