import React, { ComponentType, Suspense, lazy, startTransition, useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from '@riac/design-system';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { IPageHead, IPageHeadResults } from 'src/lib/IPageHead';
import { usePrefetchSuspense } from 'src/lib/usePrefetch';
import { HeadContext, IHeadContext } from 'src/lib/HeadContext';
import { ICatalogRouteParams } from 'src/pages/CatalogPage/Catalog.i';
import { Helmet } from 'react-helmet';
import { useFetch } from 'src/lib/FetchContext';
import { Page404 } from 'src/pages/Page404';

interface IPageLoaderProps {
  id: string;
  componentImport: () => Promise<{default: ComponentType<any>}>;
  head?: ({ fetch, location, params }) => IPageHead;
}

const buildUrl = (url:string):string =>{
  // We check for trailing slash here for the home page because some browsers don't handle it well
  // *coughstupidfacebookcough*
  if(url === '/') {
    url = '';
  }
  return url.includes('://') ? url : `https://${window.DOMAIN_NAME}${url}`;
};

const baseHelmet: IPageHeadResults = {
  title: 'Collectible Firearms for Serious Gun Collectors',
  description: 'Rock Island Auction holds over 12 gun auctions per year.'
  + 'We buy & sell collectible firearms for serious gun collectors, including'
  + 'antique firearms & military arms. Click now to start selling your guns & investing in collectables today.',
  meta: [],
};

const processHead = async ({
  headContext, head, fetch, location, params,
}: {
  headContext: Partial<IHeadContext>,
  head: ({ fetch, location, params }: {
    fetch: any,
    location: any,
    params: any,
  }) => IPageHead,
  fetch: any,
  location: any,
  params: any,
}): Promise<IPageHeadResults> => {
  if(head) {
    try {
      const headResults = await (head({ fetch, location, params }))();
      if(headResults.error404) {
        headContext.is404Error = true;
      }
      if(headResults.errorUnknown) {
        headContext.isUnknownError = true;
      }
      if(headResults.redirect) {
        headContext.isRedirect = true;
        headContext.redirectPath = headResults.redirectPath;
        headContext.redirectType = headResults.redirectType;
      }
      return Object.assign({}, baseHelmet, headResults);
    } catch (e) {
      headContext.isUnknownError = true;
      return Object.assign({}, baseHelmet, { errorUnknown: true });
    }
  } else {
    return baseHelmet;
  }
};

let pageLoadCount = 0;

export const PageLoader = ({ id, componentImport, head, ...props }: IPageLoaderProps): JSX.Element => {
  const headContext = useContext(HeadContext);
  const params = useParams<ICatalogRouteParams>();
  const location = useLocation();
  const { fetch } = useFetch();
  const [headResults, setHeadResults] = useState(usePrefetchSuspense('head', async () => {
    return processHead({ headContext, head, fetch, location, params });
  }).read());
  const [useHelmet, setUseHelmet] = useState(false);

  useEffect(() => {
    (async () => {
      const newHelmet = await processHead({ headContext, head, fetch, location, params });
      startTransition(() => {
        setHeadResults(newHelmet);
        if(!useHelmet) {
          setUseHelmet(true);
        }
      });
    })();
  }, [location.pathname, params.p0, params.p1, params.p2, params.p3, params.p4, params.p5]);

  const headContent = <>
    <title data-react-helmet="true">{headResults.title + ' | Rock Island Auction'}</title>
    <meta data-react-helmet="true" name='description' content={headResults.description}/>
    <link data-react-helmet="true" rel='canonical' href={buildUrl(headResults.canonical || location.pathname)} />
    <meta data-react-helmet="true" property='og:title' content={headResults.title} />
    <meta data-react-helmet="true" property='og:description' content={headResults.description} />
    {headResults.image
      && <meta data-react-helmet="true" property='og:image' content={buildUrl(headResults.image)} />
    }
    {headResults.image
      && <meta data-react-helmet="true" property='og:type' content={headResults.imageType || 'image/jpg'} />
    }
    <meta data-react-helmet="true" property='og:url' content={buildUrl(location.pathname)} />
    <meta data-react-helmet="true" property='og:site_name' content='Rock Island Auction Company' />
    <meta data-react-helmet="true" name='twitter:card' content='summary_large_image' />
    {headResults.image
      && <meta data-react-helmet="true" name='twitter:image' content={buildUrl(headResults.image)} />
    }
    <meta data-react-helmet="true" name='twitter:image:alt' content={headResults.title} />
    <meta data-react-helmet="true" name='twitter:description' content={headResults.description} />
    <meta data-react-helmet="true" name='twitter:site' content='@RIAuction' />
    <meta data-react-helmet="true" name='twitter:creator' content='@RIAuction' />
    {headResults.structuredData
      && <script data-react-helmet="true" type='application/ld+json'>
        {headResults.structuredData}
      </script>
    }
  </>;

  if(headContext.isHead === true) {
    return <>{headContent}</>;
  } else {
    const [isLive, setIsLive] = useState(import.meta.env.SSR);
    const [componentPromise] = useState(componentImport().then((x) => {
      return { default: x.default };
    }));
    useEffect(() => {
      componentPromise.then(() => {
        startTransition(() => {
          setIsLive(true);
          // Styled-components sometimes leaves behind style tags. I can't figure out why and it's causing problems
          // It should already be hydrated into a head tag by now, so it should be safe to remove them from the
          // document.

          // This is a hack, but it works.
          pageLoadCount++;
          if(pageLoadCount === 2) { // Second time we load a page, IE when you browse off the initial load
            document.querySelectorAll('[data-styled="true"]').forEach((x) => x.remove());
          }
        });
      });
    }, []);
    const componentPrerenderHTML = useMemo(() => {
      if(!isLive) {
        return document.getElementById(id)?.innerHTML;
      }
    }, []);
    const hasPreRender = componentPrerenderHTML !== undefined ? true : false;

    const Component = useMemo(() => lazy(async () => {
      return await componentPromise;
    }), []);

    return <>
      {useHelmet
        && <Helmet>
          <title>{headResults.title + ' | Rock Island Auction'}</title>
          <meta name='description' content={headResults.description}/>
          <link rel='canonical' href={buildUrl(headResults.canonical || location.pathname)} />
          <meta property='og:title' content={headResults.title} />
          <meta property='og:description' content={headResults.description} />
          {headResults.image && <meta property='og:image' content={buildUrl(headResults.image)} />}
          {headResults.image && <meta property='og:type' content={headResults.imageType || 'image/jpg'} />}
          <meta property='og:url' content={buildUrl(location.pathname)} />
          <meta property='og:site_name' content='Rock Island Auction Company' />
          <meta name='twitter:card' content='summary_large_image' />
          {headResults.image && <meta name='twitter:image' content={buildUrl(headResults.image)} />}
          <meta name='twitter:image:alt' content={headResults.title} />
          <meta name='twitter:description' content={headResults.description} />
          <meta name='twitter:site' content='@RIAuction' />
          <meta name='twitter:creator' content='@RIAuction' />
          {headResults.structuredData
            && <script type = 'application/ld+json'>
              {headResults.structuredData}
            </script>
          }
        </Helmet>
      }
      {(headResults.error404 || headResults.errorUnknown)
        ? <Page404/>
        : headResults.redirect
          ? <Redirect to={headResults.redirectPath}/>
          : <Suspense fallback={<Spinner/>}>
            <div
              id={(import.meta.env.SSR || !isLive) && id || undefined}
              dangerouslySetInnerHTML={(!isLive && hasPreRender) ? { __html: componentPrerenderHTML } : undefined}
            >
              {isLive || !hasPreRender ? <Component {...props}/> : undefined}
            </div>
          </Suspense>
      }
    </>;
  }
};
