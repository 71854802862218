import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncAdCampaignsPage = ({ ...props }) => <PageLoader
  id='src/pages/AdCampaigns/AdCampaigns'
  head={() => async () => {
    return {
      title: 'Ad Campaigns',
      description: 'We routinely place over 210 ads in top publications '
      + 'from around the world focusing on firearms, hunting, and collecting.',
    };
  }}
  componentImport={() => import('src/pages/AdCampaigns/AdCampaigns')}
  {...props}
/>;
