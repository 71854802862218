import styled from 'styled-components';

interface IInputSuccessContainerProps {
  invert?: boolean;
}

export const InputSuccessContainer = styled.div<IInputSuccessContainerProps>`
  && {
    background-color: rgba(0, 102, 0, 1);
    padding: 5px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.85);
    ${(props) => props.invert && `
      box-shadow: 0px 3px 1px -2px rgb(0 240 0 / 20%), 0px 2px 2px 0px rgb(0 240 0 / 14%), 0px 1px 5px 0px rgb(0 240 0 / 12%);
      border-radius: 4px;
      background-color: rgba(255, 0, 0, 0.125);
      color: #00D100;
      a {
        color: blue;
      }
    ` || ''}
  }
`;

export const InputSuccessIcon = styled.span`
  font-size: 1.1em;
  line-height: 1;
  vertical-align: middle;
`;
