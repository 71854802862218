import React from 'react';
import styled from 'styled-components';


import { Button } from '../index';
import { svgPath } from './Arrows.data';

const ArrowButtonComponent = (props: React.PropsWithChildren<{}>) => {
  return (
    <Button {...props}>
      <svg width="9.3347mm" height="23.058mm" version="1.1" viewBox="0 0 9.3347 23.058" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-22.086 -106.32)">
          <path/>
        </g>
      </svg>
    </Button>
  );
};

export const ArrowButton = styled(ArrowButtonComponent)`
  position: absolute;
  height: 106px;
  width: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: red;
  padding: 0;

  z-index: 99999;

  svg {
    height: 100%;
    width: 100%;
    filter: drop-shadow(0px 0px 3px rgb(115 115 115 / 0.7));
    path {
      d: path("${svgPath}");
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(115, 115, 115, 0.2);
      stroke-width: 0.5px;
    }

    :hover {
      filter: drop-shadow(0px 0px 10px rgb(115 115 115 / 0.9));
    }
  }
`;
