import styled from 'styled-components';

import { Button, Row, InputLabel, InputContainer, Column } from '../index';


export const TablePlaceHolder = styled.div`
  padding: 1em;
  width: 100%;
  text-align: center;
`;

export const TableData = styled.td`
  text-align: center;
`;

export const TableRow = styled.tr`
  &.highlight,
  &.highlight.highlight > * {
    background-color: #dddddd;
    color: black;
  }
`;

export const TableHeader = styled.th`
  min-height: 2em;
`;

export const TableHeaderRow = styled(Row)``;
export const TableTable = styled.table`
  margin: auto;  

  &, ${TableRow}, ${TableData}, ${TableHeader} {
    border-collapse: collapse;
  }
`;

export const TableHead = styled.thead``;
export const TableBody = styled.tbody`
  ${TableRow} {
    border-bottom: solid 1px rgb(128, 128, 128);

    :hover {
      background-color: #dddddd;
    }
  }
`;

export const TableHeaderButton = styled(Button)`
  display: flex;
  flex-flow: row nowrap;
  background: none;
  border: none;

  font-weight: bold;
  font-size: 16px;
  width: 100%;
  height: 100%;
  /*border: solid 1px red;*/
`;

export const TableHeaderSortIconsContainer = styled(Column)`
  justify-content: center;
`;

export const TableHeaderSortIcon = styled.div.withConfig({
  shouldForwardProp: (prop, defaultCB) => (
    !['active', 'hide'].includes(prop)
    && defaultCB(prop)
  ),
})`
  padding-left: 0.5em;
  background-color: white;
  font-size: 1em;

  ${({ active }) => `color: ${active ? 'black' : 'grey'};`}

  ${({ hide }) => hide && 'color: white;'}
`;

export const TableContainer = styled.div``;
export const TableBefore = styled.div``;
export const TableAfter = styled.div``;
export const TablePagingControlContainer = styled(Row)`
  margin-top: 2em;
`;

export const TablePagingDetails = styled(Row)`
 flex: 1 0 auto;
 align-items: center;

 padding-right: 0.5em;
`;

export const TablePagingControls = styled(Row)`
  flex: 1 1 auto;
  justify-content: flex-end;
  gap: 0.5em;
`;

export const TablePagingIterationButton = styled(Button)`
  flex: 0 0 auto;
  font-weight: normal;
  background-color: white;
  border: solid 1px white;

  :hover {
    ${({ disabled }) => !disabled && `
      background: linear-gradient(to bottom, #585858 0%, #111 100%);
      border: solid 1px black;

      color: white;
    `}
  }
`;

export const TablePagingPageButtonsContainer = styled(Row)`
  flex-flow: row wrap;
  gap: 0.5em;
`;

export const TablePagingPageButton = styled(Button)`
  padding: 0.5em 1em;  
  background-color: white;
  border: solid 1px white;

  font-weight:normal;

  :hover {
    ${({ disabled }) => !disabled && `
      background: linear-gradient(to bottom, #585858 0%, #111 100%);
      border: solid 1px black;

      color: white;
    `}
  }

  ${({ disabled }) => disabled && `
    background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
    border: solid 1px black;
    border-radius: 4px;

    color: black;
  `}
`;

export const TableSearchContainer = styled(Row)`
  justify-content: flex-end;
  padding-bottom: 0.5em;

  font-weight: bold;

  ${InputContainer} {
    align-items: center;
    flex: 0 0 auto;
    width: auto;

    ${InputLabel} {
      padding-right: 0.5em;
    }
  }
`;
