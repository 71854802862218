import styled from 'styled-components';

import { FaChevronDown } from 'react-icons/fa';

import {
  SelectAria,
  SelectAriaLabel,
  SelectAriaTrigger,
  SelectAriaSelectedValue,
  SelectAriaDropDownIcon,
  SelectAriaControlContainer,
  SelectAriaRequiredSymbol,
} from '../BaseComponents/Inputs/SelectAria';

export {
  SelectAriaDropDownIcon as SelectDropDownIcon,
  SelectAriaLabel as SelectLabel,
  SelectAriaSelectedValue as SelectSelectedValue,
  SelectAriaTrigger as SelectTrigger,
  SelectAriaRequiredSymbol as SelectRequiredSymbol,
} from '../BaseComponents/Inputs/SelectAria';

import { SelectAriaProps } from '../BaseComponents/Inputs/SelectAria';

export type SelectProps = {
  noOptionsBorder?: boolean,
  inline?: boolean,
  noMargin?: boolean,
} & SelectAriaProps;

export const Select = (props : SelectProps):JSX.Element => {
  return (
    <SelectContainer
      dropDownIcon = {<FaChevronDown/>}
      { ...props }
    />
  );
};

export const SelectContainer = styled(SelectAria)<SelectProps>`
  display: flex;
  flex-flow: ${(props) => props.inline ? 'row' : 'column'} nowrap;
  align-items: ${(props) => props.inline ? 'center' : 'stretch'};
  gap: ${(props) => props.inline ? '1em' : '0.5em'};
  flex: 1;
  width: 100%;
  margin: ${(props) => props.noMargin ? '0' : '10px'} 0;
  
  ${SelectAriaLabel} {
    color: ${(props) => props.inverted ? props.theme.black[90] : props.theme.black[15]};
    flex: 0 0 auto;
  }

  ${SelectAriaControlContainer} {
    display: flex;
    flex-flow: column nowrap;

    align-items: stretch;
  
    flex: ${(props) => props.inline ? '1 1 auto' : '1 1 auto'};
  }

  ${SelectAriaTrigger} {
    display: flex;
    align-items: stretch;
    padding: 0;
    background-color: ${(props) => props.inverted ? props.theme.black[10] : props.theme.black[90]};
    ${(props) => !props.noOptionsBorder && `border: 2px solid ${props.theme.black[80]};` || ''}
    cursor: pointer;
    min-height: unset;
    

    ${SelectAriaSelectedValue} {
      flex: 0 1 100%;
      padding: 10px;
      text-align: left;
      ${(props) => props.theme.mediaScalingFont(1.8)}
      color: ${(props) => props.inverted ? props.theme.black[90] : props.theme.black[0]};
    }

    ${SelectAriaDropDownIcon} {
      position: relative;
      flex: 0 0 36px;
      color: ${(props) => props.inverted ? props.theme.black[90] : props.theme.black[0]};
      font-size: 1em;

      svg {
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  ${SelectAriaRequiredSymbol} {
    padding-left: 0.25em;
    font-weight: bold;
  }
`;
