import styled from 'styled-components';
import { ITooltip, ITooltipDialog } from './Tooltip.i';

export const TooltipDialog = styled.div<ITooltipDialog>`
  position: absolute;
  ${(props) => (props.position === 'top'
    ? `bottom calc(100% + ${props.offset || '5px'})`
    : `top calc(100% + ${props.offset || '5px'})`)};
  left: 10px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 250px;
  padding: 8px;
  border-radius: 4px;
  transition: visibility 0.3s, color 0.2s, background-color 0.1s ease-in-out;
  font-size: 1.6rem;
  
  z-index: 2;
`;

export const TooltipContainer = styled.span<ITooltip>`
  position: relative;
  &:hover > ${TooltipDialog} {
    visibility: visible;
    color: ${(props) => props.theme.black[100]};
    background-color: ${(props) => props.theme.black[0]};

    ${(props) => props.inverted === true && `
      box-shadow: 5px 5px 10px ${props.theme.black[70]};
    `}
  }
`;
