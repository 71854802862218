import { ILabel } from 'src/components/Label/Label.i';
import styled from 'styled-components';

export const Label = styled.label<ILabel>`
  width: 100%;
  display: ${(props) => props.inline ? 'flex' : 'block'};
  align-items: center;
  margin-bottom: 15px;

  input {
    margin-bottom: 0;
    margin-left: ${(props) => props.inline ? 15 : 0}px;
  }
`;

export const InvertedLabel = styled(Label)`
  color: ${(props) => props.theme.black[80]};
`;
