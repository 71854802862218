import styled from 'styled-components';

import { TextButton } from '../../src/index';
import { ContentContainer } from '../../src/index';
import { IPageTabsHeaderItem } from './TabsHeader.i';
import { shouldForwardPropFilter } from '@riac/core';

export const PageTabsContainer = styled(ContentContainer)`
  margin-top: 30px;

  @media print {
    font-size: 1.4rem;
    line-height: 1rem;
    margin: 0;
  }
`;

export const PageTabsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const PageTabsHeaderItem = styled(TextButton).withConfig({
  shouldForwardProp: shouldForwardPropFilter(['active']),
})<IPageTabsHeaderItem>`
  flex: 0 1 auto;
  font-size: 3rem;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  transition: color 0.25s;
  color: white;
  font-variant: small-caps;
  font-family: Amiri,serif;

  @media print {
    font-size: 2rem;
    padding: 0 10px;
  }
  
  &:hover {
    color: ${({ theme }) => theme.gold[50]}
  }

  ${({ active, theme }) => active && `
    background-color: rgba(255, 255, 255, 0.075);
    color: ${(theme.gold[50])};
    a, h4 {
      color: ${theme.gold[50]};
      @media print {
        color: ${theme.black[95]};
        font-size: 2rem;
      }
    }
  `}

  ${({ theme }) => theme.desktop} {
    padding: 10px 20px;
  }
`;

export const PageTabsBody = styled.div`
  width: 100%;
  padding: 15px;
  padding-bottom: 100px;
  background-color: rgba(255, 255, 255, 0.075);
  border-top: 1px solid rgba(255, 255, 255, 0.125);
  
  margin-bottom: 3em;
  ${({ theme }) => theme.desktop} {
    margin-bottom: 160px;
  }
  
  min-height: 500px;

  @media print {
    margin: 0;
    padding-bottom: 15px;
  }

  h4 {
    @media print {
      display: none;
    }
  }
`;

export const PageDetailTabTable = styled.div`
  display: flex;
  gap: 1em;

  width: 100%;
  margin-top: 1em;

  &>div {
    flex: 1;
    flex-shrink: 0;
  }
`;

export const PageDetailTabLine = styled.div`
  margin-bottom: 10px;
  &>span {
    color: rgba(255, 255, 255, 0.9);
    
    @media print {
      font-weight: 700;
    }
  }
  &>p {
    max-width: 680px;
    @media print {
      font-size: 1.4rem;
      line-height: 1.15;
      margin-top: 5px;
    }
  }
  &>img {
    vertical-align: bottom;
    @media print {
      height: 1.4rem;
    }
  }
`;

export const PageDetailTabField = styled.span`
  font-weight: bold;
`;
