import styled from 'styled-components';
import { ITabbedNavigationHeaderItem } from './TabbedNavigation.i';
import { TextButton } from '../index';

export const TabbedNavigationContainer = styled.div`
  margin: 0 !important;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 0;

  height: 100%;

  @media print {
    font-size: 1.4rem;
    line-height: 1rem;
    margin: 0;
  }
`;

export const TabbedNavigationTitle = styled.div`
  flex 1 0 auto;
`;

export const TabbedNavigationHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  flex: 1 0 auto;

  & > div {
    margin: 0;
  }
`;

export const TabbedNavigationHeaderItem = styled(TextButton)<ITabbedNavigationHeaderItem>`
  flex: 0 1 auto;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition: color 0.25s;
  color: grey !important;
  background-color: transparent;
  border: none;

  @media print {
    font-size: 2rem;
    padding: 0 10px;
  }
  
  :hover{
    color: ${({ theme }) => theme.gold[50]};
  }

  ${({ active, theme }) => active && `
    border-bottom: 2px ${(theme.gold[50])} solid;
    color: ${(theme.gold[50])};
    
  `}

  ${({ theme }) => theme.desktop} {
    padding: 10px 20px;
  }
`;

export const TabbedNavigationBody = styled.div`
  width: 100%;

  flex: 0 1 90%;
    
  background-color: rgba(255, 255, 255, 0.075);
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  
  @media print {
    margin: 0;
    padding-bottom: 15px;
  }
`;
