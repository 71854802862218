import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { ITextButtonProps, TextButton } from './TextButton';
import { IPolymorphicBaseButtonProps } from '../BaseComponents/Buttons/PolymorphicBaseButton';

const ArrowLinkButton = (props: ITextButtonProps & IPolymorphicBaseButtonProps): JSX.Element => {
  const { children, leftArrow, ...restProps } = props;
  return (
    <ArrowLinkButtonContainer>
      <TextButton { ...restProps }>
        <ArrowLinkSpacerBefore/>
        <ArrowLinkUnderlineContainer>
          { leftArrow
            && <>
              <FaArrowLeft/>
              <ArrowLinkSpacer/>
            </>
          }
          <div>{ children }</div>
          { !leftArrow
            && <>
              <ArrowLinkSpacer/>
              <FaArrowRight/>
            </>
          }
        </ArrowLinkUnderlineContainer>
      </TextButton>
      <ArrowLinkSpacerAfter/>
    </ArrowLinkButtonContainer>
  );
};

ArrowLinkButton.defaultProps = {
  leftArrow: false,
};


const ArrowLinkSpacer = styled.div`
  min-width: 15px;
  transition: min-width 0.6s;
  flex: 0;
`;
const ArrowLinkSpacerAfter = styled.div`
  min-width: 5px;
  transition: min-width 0.6s;
  flex: 0;
`;
const ArrowLinkSpacerBefore = styled.div`
  min-width: 5px;
  transition: min-width 0.6s;
  flex: 0;
`;

const ArrowLinkButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ArrowLinkUnderlineContainer = styled.div`
  border-bottom: 2px solid ${ ({ theme }) => theme.gold[50] };
  display: flex;
  flex-direction: row;
  padding: 0 5px;
`;

export const ArrowLink = styled(ArrowLinkButton)`
  display: flex;
  padding: 0;
  background: transparent;
  color: ${ ({ theme }) => theme.black[0] };

  svg {
    display: inline;
    font-family: FontAwesome;
    font-size: 14px;
    line-height: 1.5;
    color: ${ ({ theme }) => theme.black[0] };
    transition: all 0.6s;
    align-self: center;
  }

  &:focus, &:active, :visited {
    color: ${ ({ theme }) => theme.black[0] };
    text-decoration: none;
  }

  &:hover {
    color: ${ ({ theme }) => theme.black[0] };
    text-decoration: none;

    ${ ArrowLinkSpacer } {
      min-width: 25px;
    }

    & ~ ${ ArrowLinkSpacerAfter } {
      min-width: 0;
    }

    ${ ArrowLinkSpacerBefore } {
      min-width: 0;
    }
  }

  &:focus {
    outline: 1px solid ${ ({ theme }) => theme.gold[50] };
  }

  &:disabled {
    background: inherit;
    color: rgba(255, 255, 255, 0.5);

    &:before {
      display: none;
    }
  }
`;
