import styled from 'styled-components';

import { Button } from '@riac/design-system';
import { OutlineButton } from '@riac/design-system';
import { TextButton } from '@riac/design-system';

import { IToggle } from 'src/components/Header/Header.i';
import { shouldForwardPropFilter } from '@riac/core';
import { theme } from '@riac/design-system';

export const Navbar = styled.header`
  background: ${theme.black[100]};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;

  @media print {
    display: none;
  }

  ${theme.desktop} {
    height: 87px;
  }
`;

export const HeaderContentWrapper = styled.div`
  flex: 1;
`;

export const Placeholder = styled.div`
  height: 87px;

  @media print {
    display: none;
  }

  ${theme.desktop} {
    height: 87px;
  }
`;

export const Logo = styled(Button)`
  flex: 1;
  img {
    max-height: 50px;
    flex: 1;
  }
`;

export const AccountButtons = styled.div`
  justify-content: end;
  display: none;
  flex: 1;
  gap: 18px;

  ${theme.desktop} {
    display: flex;
  }

  ${TextButton} {
    font-size: 2rem;
    align-self: center;
    text-align: right;
    margin-right: 10px;
  }
`;

export const Login = styled.nav`
  text-align: center;
  flex-basis: 50px;
  ${OutlineButton} {
    font-size: 24px;
    padding: 8px 16px;
  }
`;

export const Toggle = styled(Button).withConfig({
  shouldForwardProp: shouldForwardPropFilter(['active']),
})<IToggle>`
  display: block;
  height: 100%;
  width: 70px;
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${theme.black[90]};
  border: none;
  padding: 0 15px;
  z-index: 300;
  ${theme.desktop} {
    display: none;
  }

  &:before {
    display: none;
  }

  span {
    background: ${theme.black[0]};
    width: 100%;
    height: 2px;
    display: block;
    position: relative;
    transition: all 0.5s;
    &:nth-child(1) {
      transform: rotate(${({ active }) => active ? '45deg' : '0deg'});
    }
    &:nth-child(2) {
      margin: ${({ active }) => active ? '0' : '8px 0'};
      opacity: ${({ active }) => active ? '0' : '1'};
    }
    &:nth-child(3) {
      transform: rotate(${({ active }) => active ? '-45deg' : '0deg'});
      top: ${({ active }) => active ? '-4px' : '0'};
    }
  }
  @media only screen and (max-height: 480px){
    height: 80%;
    width: 60px;
  }
  @media only screen and (max-height: 320px){
    height: 60%;
    width: 50px;
  }
`;
