import { PageLoader } from 'src/lib/PageLoader';
import React from 'react';

export const AsyncAdCampaignsSinglePage = ({ ...props }): JSX.Element => <PageLoader
  id='src/pages/AdCampaigns/AdCampaignsSinglePage'
  componentImport={() => import('src/pages/AdCampaigns/AdCampaignsSinglePage')}
  head={({ fetch, params }) => async () => {
    const adReq = await fetch(`/api/adcampaigns/getAdCampaignsBySlug/${params.slug}`);
    const ad = (await adReq.json())[0];
    return {
      title: `${ad.Title} - ${ad.AdDate} - Ad Campaigns`,
      description: 'We routinely place over 210 ads in top publications '
      + 'from around the world focusing on firearms, hunting, and collecting.',
    };
  }}
  {...props}
/>;
