import styled from 'styled-components';
import { Container } from '../Container/Container';

export const CTASection = styled.section`
  background: ${(props) => props.theme.black[100]};
    padding: 50px 0;
`;
export const CTAContainer = styled(Container)`
  justify-content: space-around;
  align-items: center;
`;
export const CTAContent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;

  ${(props) => props.theme.tablet} {
    width: 50%;
    text-align: left;
    margin-bottom: 0;
  }
`;
export const CTATitle = styled.h2`
  font-size: 48px;
  font-family: ${(props) => props.theme.serif};
  color: ${(props) => props.theme.black[0]};
  line-height: 48px;
  margin-bottom: 15px;
`;

export const CTACopy = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.sansSerif};
  color: ${(props) => props.theme.black[50]};
  line-height: 1.3;
`;
