import styled from 'styled-components';

import { BoxButton } from '../index';
import { IImg } from './OpenSeaDragon.i';

import { ArrowButton } from './ArrowButton';

export const Img = styled.div<IImg>`
  flex: 0 1 100%;
  background: ${(props) => props.bg ? props.theme.black[0] : 'transparent'};
  position: relative;
  
  /* ${(props) => props.theme.phone} {
    margin: 0 15px 30px;
  } */

  ${(props) => props.theme.desktop} {
    max-width: 100%;
  }

  ${(props) => props.fullscreen && `
    position: fixed!important; /** Needed to overwrite position:relative on overriding components */
    padding: 0!important; /** Needed to overwrite aspect-ratio controls. */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
  `}

  #osd {
    height: 100vh;

    .openseadragon-canvas + div {
      @media print {
        display: none;
      }
    }
  }
`;

export const FullscreenButton = styled(BoxButton)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 11px;
  font-size: 2rem;

  @media print {
    display: none;
  }

  z-index: 1;
`;

export const ArrowButtonLeft = styled(ArrowButton)`
  left: 0;
  
  background: none;
`;

export const ArrowButtonRight = styled(ArrowButton)`
  right: 0;
  
  background: none;
  svg {
    transform: rotate(180deg);
  }
`;
