import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncNewsAndEventsPage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/NewsAndEventsPage/NewsAndEventsPage'
    componentImport={async () => (await import('src/pages/NewsAndEventsPage/NewsAndEventsPage'))}
    head={() => async () => {
      return {
        title: 'Antique Firearms News and Gun Show Events',
        description: 'Schedule of upcoming firearms events and the latest news'
        + 'from Rock Island Auction Company.',
      };
    }}
    {...props}
  />
);
