import { createGlobalStyle } from 'styled-components';
import { reset } from '@riac/design-system';
import { IGlobal } from 'src/styles/global.i';
import { theme } from '@riac/design-system';

export const GlobalStyles = createGlobalStyle<IGlobal>`
  ${reset}
  
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    /* overflow-x: hidden; */
  }

  body {
    background: ${(props) => props.theme.black[100]};
    @media print {
      background: white;
    }
  }

  body, p {
    color: ${(props) => props.theme.black[30]};
    font-family: ${(props) => props.theme.sansSerif};
    line-height: 1.5;
    ${theme.mediaScalingFont(1.6)}
  }

  h1, h2, h3, h4, h5 {
    font-family: ${(props) => props.theme.serif};
    color: ${(props) => props.theme.black[0]};
    font-variant: small-caps;
    line-height: 1;
  }

  h1 {
    ${theme.mediaScalingFont(3.6)}
  }
  h2 {
    ${theme.mediaScalingFont(3.2)}
  }
  h3 {
    ${theme.mediaScalingFont(2.8)}
  }
  h4 {
    ${theme.mediaScalingFont(2.4)}
  }
  h5 {
    ${theme.mediaScalingFont(2.2)}
  }

  strong {
    font-weight: 700;
  }

  input:not([type='submit']), textarea {
    background: ${(props) => props.theme.black[100]};
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.black[80]};
    box-shadow: none;
    line-height: 1.8;
    font-family: ${(props) => props.theme.serif};
    outline: ${(props) => props.theme.gold[50]};
    padding: 2px 8px;
    color: ${(props) => props.theme.black[0]};
    width: 100%;
    resize: none;
    margin-bottom: 10px;

    &[id^=react-select] {
      margin-bottom: 0;
    }

    ${theme.mediaScalingFont(1.8)}

    &:focus:not(:disabled), &:active:not(:disabled) {
      border: 1px solid ${(props) => props.theme.gold[50]};
    }
  }

  input[type="radio"] {
    width: auto;
  }

  ul {
    list-style: disc;
  }

  a {
    color: ${(props) => props.theme.black[20]};
    transition: color 0.3s;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.gold[50]};
    }
  }

  .grecaptcha-badge {
    visibility: collapse; 
  }

  button {
    border: none;
    background: none;
  }

  :target {
    scroll-margin-top: 90px;
  }
`;
